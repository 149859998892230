/* ======================================================================== */
/* 66. sectionIntro */
/* ======================================================================== */
.section-intro__heading
	@include fluid-type($min_width, $max_width, 58, 107)
	margin-bottom: 0.4em !important

@media screen and (max-width: $md)
	.section-intro__heading
		margin-bottom: 0.25em !important
