@charset "UTF-8";
/* * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 *
 * Cassio – Architect Portfolio AJAX HTML5 Template
 *
 * [Table of Contents]
 *
 * 1. asideLogos
 * 2. backgrounds
 * 3. bootstrapLayout
 * 4. button
 * 5. buttonBordered
 * 6. buttonSolid
 * 7. buttonCircles
 * 8. colors
 * 9. colorsAccentPrimary
 * 10. colorsAccentSecondary
 * 11. comments
 * 12. counter
 * 13. figureAward
 * 14. figureContact
 * 15. figureFeature
 * 16. figureImage
 * 17. figureInfo
 * 18. figureMember
 * 19. figurePortfolio
 * 20. figurePortfolioBig
 * 21. figurePost
 * 22. filter
 * 23. footer
 * 24. form
 * 25. formContact
 * 26. gmap
 * 27. grid
 * 28. gridFluid
 * 29. header
 * 30. headerColors
 * 31. headerOverlayThemes
 * 32. headerSticky
 * 33. headerThemes
 * 34. inputFloat
 * 35. inputSearch
 * 36. lazy
 * 37. link
 * 38. logo
 * 39. menu
 * 40. menuOverlay
 * 41. overlay
 * 42. pagination
 * 43. artParallax
 * 44. post
 * 45. postPreview
 * 46. preloader
 * 47. scroll
 * 48. scrollDown
 * 49. section
 * 50. sectionHeight
 * 51. sectionMarginsPaddings
 * 52. section404
 * 53. sectionAbout
 * 54. sectionAboutThemes
 * 55. sectionAwards
 * 56. sectionAwardsThemes
 * 57. sectionBlog
 * 58. sectionContactsThemes
 * 59. sectionContent
 * 60. sectionCTA
 * 61. sectionCTAThemes
 * 62. sectionFeatures
 * 63. sectionFeaturesThemes
 * 64. sectionFullscreenSlider
 * 65. sectionImage
 * 66. sectionIntro
 * 67. sectionLatestPostsThemes
 * 68. sectionMasthead
 * 69. sectionNavProjects
 * 70. sectionNavProjectsThemes
 * 71. sectionPortfolio
 * 72. sectionProperties
 * 73. sectionServices
 * 74. sectionServicesThemes
 * 75. sectionSliderThemes
 * 76. sectionTeam
 * 77. sectionTeamThemes
 * 78. sectionTestimonialsThemes
 * 79. sectionVideo
 * 80. select
 * 81. sidebar
 * 82. slider
 * 83. sliderFullscreen
 * 84. sliderHalfscreen
 * 85. sliderImages
 * 86. sliderLetters
 * 87. sliderProjects
 * 88. sliderTestimonials
 * 89. social
 * 90. splitText
 * 91. tags
 * 92. transitionCurtain
 * 93. typography
 * 94. typographyAccentSecondaryAdjustments
 * 95. typographyDarkAdjustments
 * 96. utilities
 * 97. widget
 * 98. widgetArchive
 * 99. widgetCalendar
 * 100. widgetCassioLogo
 * 101. widgetCassioMenuInline
 * 102. widgetCassioSocial
 * 103. widgetCategories
 * 104. widgetLogo
 * 105. widgetNavMenu
 * 106. widgetPolylang
 * 107. widgetRecentComments
 * 108. widgetRecentEntries
 * 109. widgetRSS
 * 110. widgetWPML

 * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 */
:root {
  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Montserrat', serif;
  --color-accent-primary-1: #c69f73;
  --color-accent-primary-2: #ebe6dd;
  --color-accent-primary-3: #c9bcae;
  --color-accent-primary-4: #837567;
  --color-accent-secondary-1: #628ab2;
  --color-accent-secondary-2: #3c556e;
  --color-accent-secondary-3: #283847;
  --color-accent-secondary-4: #162330;
  --distance-max-xsmall: 60;
  --distance-max-small: 100;
  --distance-max-normal: 200;
  --distance-max-large: 300;
  --distance-max-xlarge: 400;
  --distance-min-xsmall: 40;
  --distance-min-small: 40;
  --distance-min-normal: 60;
  --distance-min-large: 100;
  --distance-min-xlarge: 160;
  --xxl-max-font-size: 167;
  --xxl-min-font-size: 58;
  --xxl-line-height: 1;
  --xl-max-font-size: 107;
  --xl-min-font-size: 58;
  --xl-line-height: 1;
  --h1-max-font-size: 65;
  --h1-min-font-size: 34;
  --h1-line-height: 1.28;
  --h2-max-font-size: 52;
  --h2-min-font-size: 30;
  --h2-line-height: 1.38;
  --h3-max-font-size: 42;
  --h3-min-font-size: 26;
  --h3-line-height: 1.57;
  --h4-max-font-size: 33;
  --h4-min-font-size: 22;
  --h4-line-height: 1.61;
  --h5-max-font-size: 20;
  --h5-min-font-size: 18;
  --h5-line-height: 1.7;
  --h6-max-font-size: 18;
  --h6-min-font-size: 16;
  --h6-line-height: 1.81;
  --subheading-max-font-size: 13;
  --subheading-min-font-size: 11;
  --subheading-line-height: 1.75;
  --blockquote-max-font-size: 20;
  --blockquote-min-font-size: 16;
  --blockquote-line-height: 1.7;
  --paragraph-max-font-size: 16;
  --paragraph-min-font-size: 16;
  --paragraph-line-height: 1.75;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: none;
}

body {
  position: relative;
  width: 100%;
  min-width: 320px;
  color: #444444;
  background-color: #f8f8f8;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 100%;
  line-height: 1.75;
  word-wrap: break-word;
}

.body_lock-scroll {
  overflow: hidden;
  position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img, iframe {
  max-width: 100%;
  height: auto;
}

a {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: var(--color-accent-primary-1);
}

a:hover, a:focus {
  text-decoration: none;
  color: var(--color-accent-primary-4);
}

.page-wrapper__content {
  overflow-x: hidden;
}

[data-header-animation="intransition"] a {
  pointer-events: none !important;
}

#js-webgl {
  display: none;
}

/* ======================================================================== */
/* 1. asideLogos */
/* ======================================================================== */
.aside-logos__wrapper-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.aside-logos__wrapper-logo img {
  max-height: 100px;
  width: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px) {
  .aside-logos__wrapper-logo .lazy {
    padding: 0 !important;
  }
  .aside-logos__wrapper-logo img {
    top: 0 !important;
    left: 0 !important;
    transform: none;
    position: relative !important;
  }
}

/* ======================================================================== */
/* 2. backgrounds */
/* ======================================================================== */
.bg-white {
  background-color: #ffffff;
}

.bg-off-white {
  background-color: #f7f5f0;
}

.bg-light {
  background-color: #f8f8f8;
}

.bg-black {
  background-color: #111111;
}

.bg-dark {
  background-color: #181818;
}

.bg-dark-2 {
  background-color: #222222;
}

.bg-dark-3 {
  background-color: #333333;
}

.bg-accent-primary-1 {
  background-color: var(--color-accent-primary-1);
}

.bg-accent-primary-2 {
  background-color: var(--color-accent-primary-2);
}

.bg-accent-primary-3 {
  background-color: var(--color-accent-primary-3);
}

.bg-accent-primary-4 {
  background-color: var(--color-accent-primary-4);
}

.bg-accent-secondary-1 {
  background-color: var(--color-accent-secondary-1);
}

.bg-accent-secondary-2 {
  background-color: var(--color-accent-secondary-2);
}

.bg-accent-secondary-3 {
  background-color: var(--color-accent-secondary-3);
}

.bg-accent-secondary-4 {
  background-color: var(--color-accent-secondary-4);
}

/* ======================================================================== */
/* 3. button */
/* ======================================================================== */
.button {
  position: relative;
  display: inline-block;
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  padding: 16px 32px;
}

@media screen and (min-width: 320px) {
  .button {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .button {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.button:focus {
  outline: none;
}

.button_accent {
  background-color: var(--color-accent-primary-2);
  border-color: var(--color-accent-primary-2);
  color: var(--color-accent-primary-4);
}

.button_accent .button__icon {
  background-color: var(--color-accent-primary-3);
  color: #ffffff;
}

.button_accent:hover {
  background-color: var(--color-accent-primary-3);
  color: #ffffff;
}

.button_accent:hover .button__icon {
  background-color: var(--color-accent-primary-4);
}

.button_icon {
  display: inline-flex;
  padding: 0;
  border: none;
}

.button__label {
  padding: 18px 32px;
}

.button__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 60px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.button__icon i {
  font-size: 24px;
}

.button_fullwidth {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .button {
    padding: 12px 28px;
  }
  .button__label {
    padding: 12px 28px;
  }
  .button__icon {
    width: 43px;
  }
  .button__icon i {
    font-size: 20px;
  }
  .button_icon {
    padding: 0;
  }
}

/* ======================================================================== */
/* 4. buttonBordered */
/* ======================================================================== */
.button_bordered.button_accent-primary-1 {
  border-color: var(--color-accent-primary-1);
  color: var(--color-accent-primary-1);
}

.button_bordered.button_accent-primary-1:focus {
  color: var(--color-accent-primary-1);
}

.button_bordered.button_accent-primary-1:hover {
  background-color: var(--color-accent-primary-1);
  color: #ffffff;
}

.button_bordered.button_accent-primary-2 {
  border-color: var(--color-accent-primary-2);
  color: var(--color-accent-primary-2);
}

.button_bordered.button_accent-primary-2:focus {
  color: var(--color-accent-primary-2);
}

.button_bordered.button_accent-primary-2:hover {
  background-color: var(--color-accent-primary-2);
  color: #ffffff;
}

.button_bordered.button_accent-primary-3 {
  border-color: var(--color-accent-primary-3);
  color: var(--color-accent-primary-3);
}

.button_bordered.button_accent-primary-3:focus {
  color: var(--color-accent-primary-3);
}

.button_bordered.button_accent-primary-3:hover {
  background-color: var(--color-accent-primary-3);
  color: #ffffff;
}

.button_bordered.button_accent-primary-4 {
  border-color: var(--color-accent-primary-4);
  color: var(--color-accent-primary-4);
}

.button_bordered.button_accent-primary-4:focus {
  color: var(--color-accent-primary-4);
}

.button_bordered.button_accent-primary-4:hover {
  background-color: var(--color-accent-primary-4);
  color: #ffffff;
}

.button_bordered.button_accent-secondary-1 {
  border-color: var(--color-accent-secondary-1);
  color: var(--color-accent-secondary-1);
}

.button_bordered.button_accent-secondary-1:focus {
  color: var(--color-accent-secondary-1);
}

.button_bordered.button_accent-secondary-1:hover {
  background-color: var(--color-accent-secondary-1);
  color: #ffffff;
}

.button_bordered.button_accent-secondary-2 {
  border-color: var(--color-accent-secondary-2);
  color: var(--color-accent-secondary-2);
}

.button_bordered.button_accent-secondary-2:focus {
  color: var(--color-accent-secondary-2);
}

.button_bordered.button_accent-secondary-2:hover {
  background-color: var(--color-accent-secondary-2);
  color: #ffffff;
}

.button_bordered.button_accent-secondary-3 {
  border-color: var(--color-accent-secondary-3);
  color: var(--color-accent-secondary-3);
}

.button_bordered.button_accent-secondary-3:focus {
  color: var(--color-accent-secondary-3);
}

.button_bordered.button_accent-secondary-3:hover {
  background-color: var(--color-accent-secondary-3);
  color: #ffffff;
}

.button_bordered.button_accent-secondary-4 {
  border-color: var(--color-accent-secondary-4);
  color: var(--color-accent-secondary-4);
}

.button_bordered.button_accent-secondary-4:focus {
  color: var(--color-accent-secondary-4);
}

.button_bordered.button_accent-secondary-4:hover {
  background-color: var(--color-accent-secondary-4);
  color: #ffffff;
}

.button_bordered.button_dark {
  border-color: #444444;
  color: #444444;
}

.button_bordered.button_dark:focus {
  color: #444444;
}

.button_bordered.button_dark:hover {
  background-color: #444444;
  color: #ffffff;
}

.button_bordered.button_light {
  border-color: #f8f8f8;
  color: #f8f8f8;
}

.button_bordered.button_light:focus {
  color: #f8f8f8;
}

.button_bordered.button_light:hover {
  background-color: #f8f8f8;
  color: #181818;
}

.button_bordered.button_white {
  border-color: #ffffff;
  color: #ffffff;
}

.button_bordered.button_white:focus {
  color: #ffffff;
}

.button_bordered.button_white:hover {
  background-color: #ffffff;
  color: #181818;
}

/* ======================================================================== */
/* 5. buttonSolid */
/* ======================================================================== */
.button_solid.button_accent-primary-1 {
  background-color: var(--color-accent-primary-1);
  border-color: var(--color-accent-primary-1);
  color: #ffffff;
}

.button_solid.button_accent-primary-1:focus {
  color: #ffffff;
}

.button_solid.button_accent-primary-1:hover {
  border-color: var(--color-accent-primary-4);
  background-color: var(--color-accent-primary-4);
}

.button_solid.button_accent-primary-2 {
  background-color: var(--color-accent-primary-2);
  border-color: var(--color-accent-primary-2);
  color: #ffffff;
}

.button_solid.button_accent-primary-2:focus {
  color: #ffffff;
}

.button_solid.button_accent-primary-2:hover {
  border-color: var(--color-accent-primary-4);
  background-color: var(--color-accent-primary-4);
}

.button_solid.button_accent-primary-3 {
  background-color: var(--color-accent-primary-3);
  border-color: var(--color-accent-primary-3);
  color: #ffffff;
}

.button_solid.button_accent-primary-3:focus {
  color: #ffffff;
}

.button_solid.button_accent-primary-3:hover {
  border-color: var(--color-accent-primary-4);
  background-color: var(--color-accent-primary-4);
}

.button_solid.button_accent-primary-4 {
  background-color: var(--color-accent-primary-4);
  border-color: var(--color-accent-primary-4);
  color: #ffffff;
}

.button_solid.button_accent-primary-4:focus {
  color: #ffffff;
}

.button_solid.button_accent-primary-4:hover {
  border-color: var(--color-accent-primary-3);
  background-color: var(--color-accent-primary-3);
}

.button_solid.button_accent-secondary-1 {
  background-color: var(--color-accent-secondary-1);
  border-color: var(--color-accent-secondary-1);
  color: #ffffff;
}

.button_solid.button_accent-secondary-1:focus {
  color: #ffffff;
}

.button_solid.button_accent-secondary-1:hover {
  border-color: var(--color-accent-secondary-4);
  background-color: var(--color-accent-secondary-4);
}

.button_solid.button_accent-secondary-2 {
  background-color: var(--color-accent-secondary-2);
  border-color: var(--color-accent-secondary-2);
  color: #ffffff;
}

.button_solid.button_accent-secondary-2:focus {
  color: #ffffff;
}

.button_solid.button_accent-secondary-2:hover {
  border-color: var(--color-accent-secondary-4);
  background-color: var(--color-accent-secondary-4);
}

.button_solid.button_accent-secondary-3 {
  background-color: var(--color-accent-secondary-3);
  border-color: var(--color-accent-secondary-3);
  color: #ffffff;
}

.button_solid.button_accent-secondary-3:focus {
  color: #ffffff;
}

.button_solid.button_accent-secondary-3:hover {
  border-color: var(--color-accent-secondary-4);
  background-color: var(--color-accent-secondary-4);
}

.button_solid.button_accent-secondary-4 {
  background-color: var(--color-accent-secondary-4);
  border-color: var(--color-accent-secondary-4);
  color: #ffffff;
}

.button_solid.button_accent-secondary-4:focus {
  color: #ffffff;
}

.button_solid.button_accent-secondary-4:hover {
  border-color: var(--color-accent-secondary-3);
  background-color: var(--color-accent-secondary-3);
}

.button_solid.button_dark {
  background-color: #444444;
  border-color: #444444;
  color: #ffffff;
}

.button_solid.button_dark:focus {
  color: #444444;
}

.button_solid.button_dark:hover {
  background-color: #181818;
  color: #ffffff;
}

.button_solid.button_light {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  color: var(--color-accent-secondary-2);
}

.button_solid.button_light:focus {
  color: #f8f8f8;
}

.button_solid.button_light:hover {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #181818;
}

.button_solid.button_white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: var(--color-accent-secondary-2);
}

.button_solid.button_white:focus {
  color: #ffffff;
}

.button_solid.button_white:hover {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #181818;
}

/* ======================================================================== */
/* 6. bootstrapLayout */
/* ======================================================================== */
.container-fluid {
  padding-left: 120px;
  padding-right: 120px;
}

.container-fluid_paddings {
  padding-top: 120px;
  padding-bottom: 120px;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.row {
  margin-left: -20px;
  margin-right: -20px;
}

.col, [class*='col-'], [class*='col-'] {
  padding-left: 20px;
  padding-right: 20px;
}

.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media screen and (max-width: 1400px) {
  .container-fluid {
    padding-left: 80px;
    padding-right: 80px;
  }
  .container-fluid_paddings {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
  .container-fluid_paddings {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 991px) {
  .container-fluid, .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container-fluid_paddings {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .container_xs-no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

/* ======================================================================== */
/* 7. buttonCircles */
/* ======================================================================== */
.button-circles {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 36px;
  height: 36px;
}

.button-circles__circle {
  position: relative;
  width: 14px;
  height: 14px;
  outline: none;
  display: inline-block;
  border: 2px solid rgba(136, 136, 136, 0.3);
  vertical-align: middle;
  border-radius: 100%;
}

.button-circles__circle:nth-child(1) {
  align-self: flex-start;
}

.button-circles__circle:nth-child(2) {
  align-self: flex-start;
}

.button-circles__circle:nth-child(3) {
  align-self: flex-end;
}

.button-circles__circle:nth-child(4) {
  align-self: flex-end;
}

.button-circles__circle svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  stroke-width: 42px;
  z-index: 50;
}

.button-circles__circle svg .circle {
  stroke: var(--color-accent-primary-1);
}

@media screen and (max-width: 767px) {
  .button-circles {
    width: 30px;
    height: 30px;
  }
  .button-circles__circle {
    width: 12px;
    height: 12px;
  }
  .button-circles__circle svg {
    width: 12px;
    height: 12px;
  }
}

/* ======================================================================== */
/* 8. comments */
/* ======================================================================== */
.comments-title, .comment-reply-title {
  font-family: var(--font-primary);
  margin-top: 0;
  margin-bottom: 0;
}

.comment-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0 !important;
}

.comment-list > li {
  padding-top: 30px;
  padding-bottom: 30px;
}

.comment-list > li ol.children {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 4%;
}

.comment-list > li ol.children li {
  padding-top: 30px;
  padding-bottom: 30px;
}

.comment-list > li ol.children li:last-child {
  padding-bottom: 0;
}

.comment-list > li:not(:last-child) {
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
}

.comment-list > ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comment-author {
  max-width: 100px;
  margin-right: 2em;
  flex: 0 0 auto;
}

.comment-author .avatar {
  position: relative;
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 100%;
  display: block;
}

.comment-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comment-metadata {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  margin-left: 10px;
}

@media screen and (min-width: 320px) {
  .comment-metadata {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .comment-metadata {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.comment-metadata a:not(.comment-edit-link) {
  color: #888888;
}

.comment-metadata a:not(.comment-edit-link):hover {
  color: var(--color-accent-secondary);
}

.comment-metadata a {
  border-bottom: none !important;
}

.comment-body {
  display: flex;
}

.comment-body .fn {
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.7;
}

.comment-body .fn a {
  color: var(--color-accent-secondary-2);
}

.comment-body .fn a:hover {
  color: var(--color-accent-primary-1);
}

.comment-content {
  width: 100%;
}

.reply {
  display: inline-block;
}

.comment-reply-link {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  border-bottom: none !important;
  margin-right: 24px;
}

@media screen and (min-width: 320px) {
  .comment-reply-link {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .comment-reply-link {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.comment-reply-link:before {
  content: '\f112';
  font-family: 'FontAwesome';
  text-transform: none;
  margin-right: 8px;
}

.comment-respond {
  margin-top: 30px;
}

.comment-edit-link {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  color: var(--color-accent-primary);
  border-bottom: none !important;
}

@media screen and (min-width: 320px) {
  .comment-edit-link {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .comment-edit-link {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.comment-edit-link:before {
  content: '\f044';
  font-family: 'FontAwesome';
  text-transform: none;
  margin-right: 6px;
}

@media only screen and (max-width: 991px) {
  .comment-author {
    margin-right: 1em;
  }
  .comment-author .avatar {
    max-width: 50px;
    max-height: 50px;
  }
  .comment-meta {
    flex-wrap: wrap;
  }
  .comment-metadata {
    margin-top: 5px;
    margin-left: 0;
  }
}

/* ======================================================================== */
/* 9. colors */
/* ======================================================================== */
.color-white {
  color: #ffffff;
}

.color-white .header__burger-line {
  background-color: #ffffff;
}

.color-white .header__controls .social__item a {
  color: #ffffff;
}

.color-white .heading-light {
  color: #cccccc;
}

.color-white .subheading {
  color: #ffffff;
}

.color-black {
  color: #444444;
}

.color-black .subheading {
  color: #888888;
}

.color-black .heading-light {
  color: #888888;
}

/* ======================================================================== */
/* 10. colorsAccentPrimary */
/* ======================================================================== */
.color-accent-primary-1 {
  color: var(--color-accent-primary-1);
}

.color-accent-primary-2 {
  color: var(--color-accent-primary-2);
}

.color-accent-primary-3 {
  color: var(--color-accent-primary-3);
}

.color-accent-primary-4 {
  color: var(--color-accent-primary-4);
}

/* ======================================================================== */
/* 11. colorsAccentSecondary */
/* ======================================================================== */
.color-accent-secondary-1 {
  color: var(--color-accent-secondary-1);
}

.color-accent-secondary-2 {
  color: var(--color-accent-secondary-2);
}

.color-accent-secondary-3 {
  color: var(--color-accent-secondary-3);
}

.color-accent-secondary-4 {
  color: var(--color-accent-secondary-4);
}

/* ======================================================================== */
/* 12. counter */
/* ======================================================================== */
.counter {
  text-align: center;
  color: #888888;
}

.counter__number {
  font-family: var(--font-primary);
  font-weight: 100;
}

.counter__label {
  margin-top: 5px;
  font-weight: 400;
}

/* ======================================================================== */
/* 13. figureAward */
/* ======================================================================== */
.figure-award {
  position: relative;
  padding-top: calc(30 * 1px);
  padding-bottom: calc(30 * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .figure-award {
    padding-top: calc(30 * 1px + (50 - 30) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-award {
    padding-top: calc(50 * 1px);
  }
}

@media screen and (min-width: 320px) {
  .figure-award {
    padding-bottom: calc(30 * 1px + (50 - 30) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-award {
    padding-bottom: calc(50 * 1px);
  }
}

.figure-award__year {
  font-family: var(--font-primary);
  font-weight: 100;
  line-height: 1;
  font-size: calc(42 * 1px);
  color: var(--color-accent-primary-1);
}

@media screen and (min-width: 320px) {
  .figure-award__year {
    font-size: calc(42 * 1px + (65 - 42) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-award__year {
    font-size: calc(65 * 1px);
  }
}

.figure-award__items > *:first-child {
  margin-top: 0;
}

.figure-award__items > *:last-child {
  margin-bottom: 0;
}

.figure-award__border-line {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid rgba(204, 204, 204, 0.3);
}

@media screen and (max-width: 576px) {
  .figure-award__year {
    margin-bottom: 30px;
  }
}

/* ======================================================================== */
/* 14. figureContact */
/* ======================================================================== */
.figure-contact {
  text-align: center;
}

.figure-contact__icon {
  font-size: 36px !important;
  line-height: 1 !important;
  display: inline-block;
  margin-bottom: 30px;
  color: var(--color-accent-secondary-2);
}

@media only screen and (max-width: 767px) {
  .figure-contact__icon {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .figure-contact {
    padding-top: calc(var(--distance-min-small)/3 * 1px);
    padding-bottom: calc(var(--distance-min-small)/3 * 1px);
  }
}

@media screen and (max-width: 991px) and (min-width: 320px) and (max-width: 1920px) {
  .figure-contact {
    padding-top: calc(var(--distance-min-small)/3 * 1px + (var(--distance-max-small)/2 - var(--distance-min-small)/3) * ((100vw - 320px) / 1600));
  }
}

@media screen and (max-width: 991px) and (min-width: 1920px) {
  .figure-contact {
    padding-top: calc(var(--distance-max-small)/2 * 1px);
  }
}

@media screen and (max-width: 991px) and (min-width: 320px) {
  .figure-contact {
    padding-bottom: calc(var(--distance-min-small)/3 * 1px + (var(--distance-max-small)/2 - var(--distance-min-small)/3) * ((100vw - 320px) / 1600));
  }
}

@media screen and (max-width: 991px) and (min-width: 1920px) {
  .figure-contact {
    padding-bottom: calc(var(--distance-max-small)/2 * 1px);
  }
}

/* ======================================================================== */
/* 15. figureInfo */
/* ======================================================================== */
.figure-info__option {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  margin-bottom: 8px;
}

@media screen and (min-width: 320px) {
  .figure-info__option {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-info__option {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

/* ======================================================================== */
/* 16. figureFeature */
/* ======================================================================== */
.figure-feature {
  position: relative;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: calc(40 * 1px);
  padding-bottom: calc(40 * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .figure-feature {
    padding-top: calc(40 * 1px + (90 - 40) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-feature {
    padding-top: calc(90 * 1px);
  }
}

@media screen and (min-width: 320px) {
  .figure-feature {
    padding-bottom: calc(40 * 1px + (90 - 40) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-feature {
    padding-bottom: calc(90 * 1px);
  }
}

.figure-feature__corner {
  position: absolute;
  width: 30px;
  height: 30px;
  border-color: var(--color-accent-primary-1);
}

.figure-feature__corner_top {
  top: 0;
  left: 0;
  border-top-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-left-style: solid;
}

.figure-feature__corner_bottom {
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-right-style: solid;
  right: 0;
  bottom: 0;
}

.figure-feature__icon {
  font-size: 48px;
  color: var(--color-accent-primary-1);
  width: 48px;
  height: 48px;
}

.figure-feature__header {
  margin-top: calc(1 * (16 * 1px));
  width: 100%;
}

@media screen and (min-width: 320px) {
  .figure-feature__header {
    margin-top: calc(1 * (16 * 1px + (24 - 16) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .figure-feature__header {
    margin-top: calc(1 * (24 * 1px));
  }
}

.figure-feature__heading {
  margin-top: 0;
  margin-bottom: 0;
}

/* ======================================================================== */
/* 17. figureMember */
/* ======================================================================== */
.figure-member {
  text-align: center;
}

.figure-member_has-social:hover .figure-member__curtain {
  transform: skewY(-5deg) translateY(0%);
}

.figure-member_has-social:hover .figure-member__wrapper-img img {
  transform: translateY(-20px);
}

.figure-member_has-social:hover .figure-member__social li {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(1) {
  transition-delay: 80ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(2) {
  transition-delay: 110ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(3) {
  transition-delay: 140ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(4) {
  transition-delay: 170ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(5) {
  transition-delay: 200ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(6) {
  transition-delay: 230ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(7) {
  transition-delay: 260ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(8) {
  transition-delay: 290ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(9) {
  transition-delay: 320ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(10) {
  transition-delay: 350ms;
}

.figure-member_has-social:hover .figure-member__content {
  transform: translate(0, -25%);
}

.figure-member_has-social:hover .figure-member__line {
  transform: scaleY(0);
}

.figure-member__position {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  color: var(--color-accent-primary-3);
}

@media screen and (min-width: 320px) {
  .figure-member__position {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-member__position {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.figure-member__wrapper-img {
  position: relative;
}

.figure-member__wrapper-img img {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  width: 100%;
}

.figure-member__wrapper-img-inner {
  position: relative;
  overflow: hidden;
}

.figure-member__line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  margin: 0 auto;
  height: 60px;
  width: 1px;
  background-color: var(--color-accent-primary-1);
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: top center;
}

.figure-member__heading {
  margin-top: 50px;
  margin-bottom: 0;
}

.figure-member__position {
  margin-top: 12px;
}

.figure-member__curtain {
  position: absolute;
  left: -20px;
  right: -20px;
  bottom: -20px;
  transform: skewY(-5deg) translateY(100%);
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding: 60px 30px 60px;
}

.figure-member__social {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 30px;
}

.figure-member__social li {
  transform: translateY(30px);
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  opacity: 0;
  visibility: hidden;
}

.figure-member__content {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

/* ======================================================================== */
/* 18. figurePortfolio */
/* ======================================================================== */
.figure-portfolio {
  display: block;
}

.figure-portfolio__wrapper-img {
  position: relative;
  overflow: hidden;
}

.figure-portfolio__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
  background: none !important;
}

.figure-portfolio__header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 50;
}

.figure-portfolio__category {
  position: relative;
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  font-weight: normal;
  z-index: 50;
  margin-bottom: 1em;
}

@media screen and (min-width: 320px) {
  .figure-portfolio__category {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-portfolio__category {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.figure-portfolio__curtain {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -100px;
  z-index: 1;
  transform: skewY(0deg) translateY(100%);
}

.figure-portfolio__heading {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.33;
}

.figure-portfolio__icon {
  margin-top: 10px;
  z-index: 50;
}

@media screen and (max-width: 991px) {
  .figure-portfolio__content {
    padding: 30px;
  }
  .figure-portfolio__icon {
    margin-top: 5px;
  }
}

/* ======================================================================== */
/* 19. figurePortfolioBig */
/* ======================================================================== */
.figure-portfolio-big {
  display: block;
  position: relative;
}

.figure-portfolio-big:hover .figure-portfolio-big__wrapper-img-inner {
  transform: scale(0.95);
}

.figure-portfolio-big:hover .figure-portfolio-big__wrapper-img img {
  transform: scale(1.2);
}

.figure-portfolio-big:hover .figure-portfolio-big__content {
  transform: translate(0, -25%);
}

.figure-portfolio-big__category {
  margin-top: 0.5em;
  margin-bottom: 0;
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  color: var(--color-accent-primary-1);
  font-weight: normal;
}

@media screen and (min-width: 320px) {
  .figure-portfolio-big__category {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-portfolio-big__category {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.figure-portfolio-big__heading {
  margin-top: 0.75em;
  margin-bottom: 0.5em;
}

.figure-portfolio-big__letter {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-75%);
  z-index: -1;
  color: #888888;
  font-family: var(--font-secondary);
  font-weight: bold;
  line-height: 0.66;
  font-size: calc(67 * 1px);
  opacity: .15;
}

@media screen and (min-width: 320px) {
  .figure-portfolio-big__letter {
    font-size: calc(67 * 1px + (250 - 67) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-portfolio-big__letter {
    font-size: calc(250 * 1px);
  }
}

.figure-portfolio-big__wrapper-img img {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: center center;
  transform: scale(1);
}

.figure-portfolio-big__wrapper-img-inner {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: center center;
  transform: scale(1);
  overflow: hidden;
}

.figure-portfolio-big__content {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@media screen and (max-width: 1024px) {
  .figure-portfolio-big__letter {
    display: none;
  }
}

/* ======================================================================== */
/* 20. figureImage */
/* ======================================================================== */
.figure-image {
  position: relative;
  text-align: center;
}

.figure-image img {
  width: 100%;
}

.figure-image__wrapper-img {
  position: relative;
  overflow: hidden;
}

/* ======================================================================== */
/* 21. figurePost */
/* ======================================================================== */
.figure-post {
  display: block;
}

.figure-post:hover .figure-post__wrapper-img img {
  transform: scale(1.05);
}

.figure-post:hover .figure-post__wrapper-img-inner {
  transform: scale(0.95);
}

.figure-post:hover .figure-post__wrapper-img img {
  transform: scale(1.2);
}

.figure-post:hover .figure-post__content {
  transform: translate(0, -25%);
}

.figure-post:hover .figure-post__line {
  transform: scaleY(0);
}

.figure-post__date {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  color: var(--color-accent-primary-1);
}

@media screen and (min-width: 320px) {
  .figure-post__date {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .figure-post__date {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.figure-post__wrapper-img {
  position: relative;
}

.figure-post__wrapper-img img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.figure-post__line {
  display: inline-block;
  width: 1px;
  height: 60px;
  background-color: var(--color-accent-primary-1);
  position: absolute;
  bottom: -30px;
  z-index: 50;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: top center;
}

.figure-post__content {
  padding-top: 60px;
}

.figure-post__heading {
  margin-top: 0.5em;
  margin-bottom: 0;
}

.figure-post__wrapper-img-inner {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  overflow: hidden;
}

.figure-post__content {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

/* ======================================================================== */
/* 22. filter */
/* ======================================================================== */
.filter {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;
  text-align: center;
}

.filter__inner {
  position: relative;
}

.filter__item {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@media screen and (min-width: 320px) {
  .filter__item {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .filter__item {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.filter__item:not(.filter__item_active):hover {
  color: #444444;
}

.filter__item_active {
  color: var(--color-accent-primary-1);
}

.filter__underline {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-accent-primary-1);
  width: 0;
  height: 1px;
}

@media screen and (max-width: 1280px) {
  .filter__underline {
    display: none;
  }
}

/* ======================================================================== */
/* 23. footer */
/* ======================================================================== */
.footer {
  position: relative;
}

.footer__area-primary {
  padding-top: calc(30 * 1px);
  padding-bottom: calc(30 * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .footer__area-primary {
    padding-top: calc(30 * 1px + (100 - 30) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .footer__area-primary {
    padding-top: calc(100 * 1px);
  }
}

@media screen and (min-width: 320px) {
  .footer__area-primary {
    padding-bottom: calc(30 * 1px + (100 - 30) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .footer__area-primary {
    padding-bottom: calc(100 * 1px);
  }
}

.footer__area-secondary {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid rgba(136, 136, 136, 0.3);
}

@media screen and (max-width: 991px) {
  .footer__area-secondary {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .footer__column {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .footer__column.text-left, .footer__column.text-center, .footer__column.text-right {
    text-align: center !important;
  }
}

/* ======================================================================== */
/* 24. form */
/* ======================================================================== */
.form {
  width: 100%;
}

.form__submit {
  margin-top: 1.5em;
}

.form__col {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.form__col_submit {
  margin-top: 1.5em;
  margin-bottom: 0;
}

.form__error, span.wpcf7-not-valid-tip {
  display: block;
  font-size: 12px;
  color: red;
  text-align: left;
  margin-top: 4px;
}

.form__heading {
  margin-top: 0;
  margin-bottom: 1em;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  border-color: red;
  padding: 15px;
  margin: 1.5em 0 0;
  display: none !important;
}

@media only screen and (max-width: 992px) {
  .form__col_submit {
    text-align: left;
  }
}

/* ======================================================================== */
/* 25. gmap */
/* ======================================================================== */
.gmap {
  width: 100%;
  height: 100%;
}

.gmap__container {
  width: 100%;
  height: 100%;
  color: #181818;
}

@media only screen and (max-width: 991px) {
  .gmap__container {
    height: 600px;
    max-height: 120vh;
  }
}

/* ======================================================================== */
/* 26. formContact */
/* ======================================================================== */
.form-contact {
  padding-top: calc(var(--distance-min-small) * 1px);
  padding-bottom: calc(var(--distance-min-small) * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .form-contact {
    padding-top: calc(var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .form-contact {
    padding-top: calc(var(--distance-max-small) * 1px);
  }
}

@media screen and (min-width: 320px) {
  .form-contact {
    padding-bottom: calc(var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .form-contact {
    padding-bottom: calc(var(--distance-max-small) * 1px);
  }
}

/* ======================================================================== */
/* 27. inputFloat */
/* ======================================================================== */
.input-float {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.input-float__label {
  position: absolute;
  top: 1em;
  left: 0;
  margin: auto;
  display: block;
  font-size: 16px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: left center;
  cursor: text;
  color: #888888;
}

.input-float__input {
  display: block;
  width: 100%;
  width: 100%;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  padding: 12px 0 8px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: transparent;
  border-radius: 0;
  color: inherit;
}

.input-float__input_textarea {
  resize: none;
  min-height: 200px;
}

.input-float__input_focused + .input-float__label, .input-float__input_not-empty + .input-float__label {
  transform: scale(0.8571) translateY(-2rem);
}

.input-float__input_focused {
  border-color: var(--color-accent-primary-1);
}

.input-float__input_focused + .input-float__label {
  color: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 28. grid */
/* ======================================================================== */
.grid {
  overflow: hidden;
}

.grid__sizer {
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
}

.grid__item {
  display: block;
  width: 100%;
}

.grid__item-link {
  display: block;
  position: relative;
}

.grid__item-link img {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: center center;
}

.grid__item-link:hover, .grid__item-link:focus {
  opacity: 1;
}

.grid__item-link:hover img {
  transform: scale(1.1);
}

.grid__item-link:hover .grid__item-overlay {
  transform: scaleY(1);
}

.grid__item-link:hover .grid__item-icon {
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  transition-delay: 50ms;
}

.grid__item-link:hover .grid__item-icon:after {
  transform: scale(1);
  transition-delay: 100ms;
}

.grid__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  z-index: 50;
  transform: translate(-50%, 10px);
  transition: all 0.6s ease;
  opacity: 0;
  visibility: hidden;
  color: #ffffff;
}

.grid__item-icon:after {
  content: '';
  display: block;
  border-radius: 100%;
  border: 1px solid #888888;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.grid__item-icon-symbol {
  font-size: 28px !important;
}

.grid__item-overlay {
  opacity: 1;
  background-color: #181818;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: scaleY(0);
  transform-origin: bottom center;
}

.grid__item-icon {
  width: 60px;
  height: 60px;
}

.grid__item-icon-symbol {
  font-size: 24px !important;
}

@media only screen and (min-width: 992px) {
  .grid__item_desktop-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__item_desktop-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__item_desktop-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .grid__item_desktop-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid__item_tablet-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__item_tablet-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__item_tablet-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .grid__item_tablet-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .grid__item_mobile-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__item_mobile-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__item_mobile-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .grid__item_mobile-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

/* ======================================================================== */
/* 29. gridFluid */
/* ======================================================================== */
.grid_fluid-1 {
  margin: -1vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-1 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-1 {
  padding: 1vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-1 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-1-fancy:nth-of-type(3) {
    margin-top: 2vw;
  }
}

.grid_fluid-2 {
  margin: -2vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-2 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-2 {
  padding: 2vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-2 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-2-fancy:nth-of-type(3) {
    margin-top: 4vw;
  }
}

.grid_fluid-3 {
  margin: -3vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-3 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-3 {
  padding: 3vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-3 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-3-fancy:nth-of-type(3) {
    margin-top: 6vw;
  }
}

.grid_fluid-4 {
  margin: -4vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-4 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-4 {
  padding: 4vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-4 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-4-fancy:nth-of-type(3) {
    margin-top: 8vw;
  }
}

.grid_fluid-5 {
  margin: -5vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-5 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-5 {
  padding: 5vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-5 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-5-fancy:nth-of-type(3) {
    margin-top: 10vw;
  }
}

.grid_fluid-6 {
  margin: -6vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-6 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-6 {
  padding: 6vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-6 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-6-fancy:nth-of-type(3) {
    margin-top: 12vw;
  }
}

.grid_fluid-7 {
  margin: -7vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-7 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-7 {
  padding: 7vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-7 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-7-fancy:nth-of-type(3) {
    margin-top: 14vw;
  }
}

.grid_fluid-8 {
  margin: -8vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-8 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-8 {
  padding: 8vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-8 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-8-fancy:nth-of-type(3) {
    margin-top: 16vw;
  }
}

.grid_fluid-9 {
  margin: -9vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-9 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-9 {
  padding: 9vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-9 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-9-fancy:nth-of-type(3) {
    margin-top: 18vw;
  }
}

.grid_fluid-10 {
  margin: -10vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-10 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-10 {
  padding: 10vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-10 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-10-fancy:nth-of-type(3) {
    margin-top: 20vw;
  }
}

.grid_fluid-11 {
  margin: -11vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-11 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-11 {
  padding: 11vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-11 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-11-fancy:nth-of-type(3) {
    margin-top: 22vw;
  }
}

.grid_fluid-12 {
  margin: -12vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-12 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-12 {
  padding: 12vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-12 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-12-fancy:nth-of-type(3) {
    margin-top: 24vw;
  }
}

.grid_fluid-13 {
  margin: -13vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-13 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-13 {
  padding: 13vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-13 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-13-fancy:nth-of-type(3) {
    margin-top: 26vw;
  }
}

.grid_fluid-14 {
  margin: -14vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-14 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-14 {
  padding: 14vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-14 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-14-fancy:nth-of-type(3) {
    margin-top: 28vw;
  }
}

.grid_fluid-15 {
  margin: -15vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-15 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-15 {
  padding: 15vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-15 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-15-fancy:nth-of-type(3) {
    margin-top: 30vw;
  }
}

.grid_fluid-16 {
  margin: -16vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-16 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-16 {
  padding: 16vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-16 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-16-fancy:nth-of-type(3) {
    margin-top: 32vw;
  }
}

.grid_fluid-17 {
  margin: -17vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-17 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-17 {
  padding: 17vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-17 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-17-fancy:nth-of-type(3) {
    margin-top: 34vw;
  }
}

.grid_fluid-18 {
  margin: -18vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-18 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-18 {
  padding: 18vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-18 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-18-fancy:nth-of-type(3) {
    margin-top: 36vw;
  }
}

.grid_fluid-19 {
  margin: -19vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-19 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-19 {
  padding: 19vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-19 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-19-fancy:nth-of-type(3) {
    margin-top: 38vw;
  }
}

.grid_fluid-20 {
  margin: -20vw;
}

@media only screen and (max-width: 767px) {
  .grid_fluid-20 {
    margin: -10px -20px;
  }
}

.grid__item_fluid-20 {
  padding: 20vw;
}

@media only screen and (max-width: 767px) {
  .grid__item_fluid-20 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid__item_fluid-20-fancy:nth-of-type(3) {
    margin-top: 40vw;
  }
}

/* ======================================================================== */
/* 30. header */
/* ======================================================================== */
.header {
  padding: 75px 0 0;
}

.header_absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}

.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}

.header__burger-line {
  width: 100%;
  height: 2px;
  background-color: #888888;
  margin-top: 3px;
  margin-bottom: 3px;
  transform: rotate(0deg) translateY(0px);
  will-change: transform;
  transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.header__wrapper-overlay-menu {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background: none !important;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
}

.header__burger {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.header__burger_opened .header__burger-line {
  background-color: #888888 !important;
}

.header__burger_opened .header__burger-line:nth-child(1) {
  transform: rotate(-45deg) translateY(6px);
}

.header__burger_opened .header__burger-line:nth-child(2) {
  transform: rotate(45deg) translateY(-6px);
}

.header__wrapper-menu {
  position: relative;
  width: 100%;
  max-width: calc(50vw - 300px);
}

.header__curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.header__wrapper-overlay-widgets {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 64px;
}

.header__wrapper-overlay-widgets .social {
  margin-top: 1em;
  margin-bottom: 1em;
}

.header__overlay-menu-back {
  position: absolute;
  top: 0;
  cursor: pointer;
  font-size: 32px !important;
  color: #888888 !important;
  opacity: 0;
  visibility: hidden;
}

.header__controls {
  position: relative;
  z-index: 600;
}

.header__circle-letters {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 300px;
  border: 2px solid rgba(136, 136, 136, 0.3);
  border-radius: 100%;
}

.header__circle-letters svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 100%;
  stroke-width: 1px;
  z-index: 50;
}

.header__circle-letters svg .circle {
  stroke: var(--color-accent-primary-1);
}

.header__circle-letters-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1400px) {
  .header {
    padding-top: 50px;
  }
  .header__wrapper-overlay-widgets {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1280px) {
  .header {
    padding-top: 40px;
  }
  .header__wrapper-overlay-widgets {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .header {
    padding-top: 20px;
  }
  .header__circle-letters {
    display: none;
  }
  .header__wrapper-menu {
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
  .header__wrapper-overlay-menu {
    text-align: center;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .header__wrapper-overlay-widgets {
    text-align: center;
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: auto;
  }
  .header__wrapper-overlay-widgets .text-left, .header__wrapper-overlay-widgets .text-right {
    text-align: center !important;
  }
}

@media screen and (max-height: 800px) and (min-width: 991px) {
  .header {
    padding-top: 40px;
  }
  .header__wrapper-overlay-widgets {
    padding-bottom: 30px;
  }
}

/* ======================================================================== */
/* 31. headerColors */
/* ======================================================================== */
.header_color-white .header__burger-line {
  background-color: #ffffff;
}

.header_color-white .header__controls .social__item a {
  color: #ffffff;
}

.header_color-white:not(.header_sticky) .menu > li > a {
  color: #ffffff;
}

.header_color-white:not(.header_sticky) .menu > li > a:hover {
  color: var(--color-accent-primary-1);
}

.header_color-black .header__burger-line {
  background-color: #444444;
}

.header_color-black .header__controls .social__item a {
  color: #888888;
}

/* ======================================================================== */
/* 32. headerOverlayThemes */
/* ======================================================================== */
.header__wrapper-overlay-menu.bg-white .header__curtain {
  background-color: #ffffff;
}

.header__wrapper-overlay-menu.bg-white .menu-overlay li a {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-white .menu-overlay li a:hover {
  color: var(--color-accent-secondary-4);
}

.header__wrapper-overlay-menu.bg-white .vector-letter {
  fill: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-white .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-off-white .header__curtain {
  background-color: #f7f5f0;
}

.header__wrapper-overlay-menu.bg-off-white .menu-overlay li a {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-off-white .menu-overlay li a:hover {
  color: var(--color-accent-secondary-4);
}

.header__wrapper-overlay-menu.bg-off-white .vector-letter {
  fill: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-off-white .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-light .header__curtain {
  background-color: #f8f8f8;
}

.header__wrapper-overlay-menu.bg-light .menu-overlay li a {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-light .menu-overlay li a:hover {
  color: var(--color-accent-secondary-4);
}

.header__wrapper-overlay-menu.bg-light .vector-letter {
  fill: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-light .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-black .header__curtain {
  background-color: #111111;
}

.header__wrapper-overlay-menu.bg-black .menu-overlay li a {
  color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-black .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-black .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-black .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-dark .header__curtain {
  background-color: #181818;
}

.header__wrapper-overlay-menu.bg-dark .menu-overlay li a {
  color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-dark .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-dark .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-dark .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-dark-2 .header__curtain {
  background-color: #222222;
}

.header__wrapper-overlay-menu.bg-dark-2 .menu-overlay li a {
  color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-dark-2 .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-dark-2 .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-dark-2 .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-dark-3 .header__curtain {
  background-color: #333333;
}

.header__wrapper-overlay-menu.bg-dark-3 .menu-overlay li a {
  color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-dark-3 .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-dark-3 .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-dark-3 .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-accent-primary-1 .header__curtain {
  background-color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-accent-primary-1 .menu-overlay li a {
  color: #f8f8f8;
}

.header__wrapper-overlay-menu.bg-accent-primary-1 .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-accent-primary-1 .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-accent-primary-1 .header__overlay-menu-back {
  color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-accent-primary-2 .header__curtain {
  background-color: var(--color-accent-primary-2);
}

.header__wrapper-overlay-menu.bg-accent-primary-3 .header__curtain {
  background-color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-accent-primary-4 .header__curtain {
  background-color: var(--color-accent-primary-4);
}

.header__wrapper-overlay-menu.bg-accent-secondary-1 .header__curtain {
  background-color: var(--color-accent-secondary-1);
}

.header__wrapper-overlay-menu.bg-accent-secondary-1 .menu-overlay li a {
  color: #ffffff;
}

.header__wrapper-overlay-menu.bg-accent-secondary-1 .menu-overlay li a:hover {
  color: var(--color-accent-primary-2);
}

.header__wrapper-overlay-menu.bg-accent-secondary-1 .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-accent-secondary-2 .header__curtain {
  background-color: var(--color-accent-secondary-2);
}

.header__wrapper-overlay-menu.bg-accent-secondary-2 .menu-overlay li a {
  color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-accent-secondary-2 .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-accent-secondary-2 .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-accent-secondary-3 .header__curtain {
  background-color: var(--color-accent-secondary-3);
}

.header__wrapper-overlay-menu.bg-accent-secondary-3 .menu-overlay li a {
  color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-accent-secondary-3 .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-accent-secondary-3 .vector-letter {
  fill: #ffffff;
}

.header__wrapper-overlay-menu.bg-accent-secondary-4 .header__curtain {
  background-color: var(--color-accent-secondary-4);
}

.header__wrapper-overlay-menu.bg-accent-secondary-4 .menu-overlay li a {
  color: var(--color-accent-primary-3);
}

.header__wrapper-overlay-menu.bg-accent-secondary-4 .menu-overlay li a:hover {
  color: var(--color-accent-primary-1);
}

.header__wrapper-overlay-menu.bg-accent-secondary-4 .vector-letter {
  fill: #ffffff;
}

/* ======================================================================== */
/* 33. headerSticky */
/* ======================================================================== */
.js-sticky-header {
  transition: all 0.6s ease;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
  will-change: padding, box-shadow;
}

.header_sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.1);
}

/* ======================================================================== */
/* 34. headerThemes */
/* ======================================================================== */
.header.bg-white .header__burger-line {
  background-color: #181818;
}

.header.bg-white .social__item a {
  color: #888888;
}

.header.bg-off-white .header__burger-line {
  background-color: #181818;
}

.header.bg-off-white .social__item a {
  color: #888888;
}

.header.bg-light .header__burger-line {
  background-color: #181818;
}

.header.bg-light .social__item a {
  color: #888888;
}

.header.bg-black .menu > li > a {
  color: #ffffff;
}

.header.bg-black .menu > li > a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-black .menu .sub-menu {
  background-color: #222222;
}

.header.bg-black .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-black .menu .sub-menu > li a:hover {
  background-color: #333333;
}

.header.bg-black .social__item a {
  color: #ffffff;
}

.header.bg-black .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-black .header__burger-line {
  background-color: #ffffff;
}

.header.bg-dark .menu > li > a {
  color: #ffffff;
}

.header.bg-dark .menu > li > a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-dark .menu .sub-menu {
  background-color: #222222;
}

.header.bg-dark .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-dark .menu .sub-menu > li a:hover {
  background-color: #333333;
}

.header.bg-dark .social__item a {
  color: #ffffff;
}

.header.bg-dark .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-dark .header__burger-line {
  background-color: #ffffff;
}

.header.bg-dark-2 .menu > li > a {
  color: #ffffff;
}

.header.bg-dark-2 .menu > li > a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-dark-2 .menu .sub-menu {
  background-color: #222222;
}

.header.bg-dark-2 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-dark-2 .menu .sub-menu > li a:hover {
  background-color: #333333;
}

.header.bg-dark-2 .social__item a {
  color: #ffffff;
}

.header.bg-dark-2 .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-dark-2 .header__burger-line {
  background-color: #ffffff;
}

.header.bg-dark-3 .menu > li > a {
  color: #ffffff;
}

.header.bg-dark-3 .menu > li > a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-dark-3 .menu .sub-menu {
  background-color: #222222;
}

.header.bg-dark-3 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-dark-3 .menu .sub-menu > li a:hover {
  background-color: #333333;
}

.header.bg-dark-3 .social__item a {
  color: #ffffff;
}

.header.bg-dark-3 .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-dark-3 .header__burger-line {
  background-color: #ffffff;
}

.header.bg-accent-primary-1 .menu > li > a {
  color: #ffffff;
}

.header.bg-accent-primary-1 .menu > li > a:hover {
  color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-1 .menu > li > a:before {
  background-color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-1 .menu .sub-menu {
  background-color: var(--color-accent-primary-3);
}

.header.bg-accent-primary-1 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-accent-primary-1 .menu .sub-menu > li a:hover {
  border-color: #181818;
  background-color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-1 .logo {
  color: #ffffff;
}

.header.bg-accent-primary-1 .logo:hover {
  color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-1 .social__item a {
  color: #ffffff;
}

.header.bg-accent-primary-1 .social__item a:hover {
  color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-3 .menu > li > a {
  color: #ffffff;
}

.header.bg-accent-primary-3 .menu > li > a:hover {
  color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-3 .menu > li > a:before {
  background-color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-3 .menu .sub-menu {
  background-color: var(--color-accent-primary-3);
}

.header.bg-accent-primary-3 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-accent-primary-3 .menu .sub-menu > li a:hover {
  border-color: #181818;
  background-color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-3 .logo {
  color: #ffffff;
}

.header.bg-accent-primary-3 .logo:hover {
  color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-3 .social__item a {
  color: #ffffff;
}

.header.bg-accent-primary-3 .social__item a:hover {
  color: var(--color-accent-primary-4);
}

.header.bg-accent-primary-4 .menu > li > a {
  color: #ffffff;
}

.header.bg-accent-primary-4 .menu > li > a:hover {
  color: #cccccc;
}

.header.bg-accent-primary-4 .menu > li > a:before {
  background-color: #cccccc;
}

.header.bg-accent-primary-4 .menu .sub-menu {
  background-color: var(--color-accent-primary-3);
}

.header.bg-accent-primary-4 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-accent-primary-4 .menu .sub-menu > li a:hover {
  border-color: #181818;
  background-color: var(--color-accent-primary-2);
  color: #181818;
}

.header.bg-accent-primary-4 .social__item a {
  color: #ffffff;
}

.header.bg-accent-primary-4 .social__item a:hover {
  color: var(--color-accent-primary-4);
}

.header.bg-accent-secondary-1 .menu > li > a {
  color: #ffffff;
}

.header.bg-accent-secondary-1 .menu > li > a:hover {
  color: #cccccc;
}

.header.bg-accent-secondary-1 .menu > li > a:before {
  background-color: #cccccc;
}

.header.bg-accent-secondary-1 .menu .sub-menu {
  background-color: var(--color-accent-secondary-2);
}

.header.bg-accent-secondary-1 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-accent-secondary-1 .menu .sub-menu > li a:hover {
  border-color: var(--color-accent-primary-1);
  background-color: var(--color-accent-secondary-1);
}

.header.bg-accent-secondary-1 .social__item a {
  color: #ffffff;
}

.header.bg-accent-secondary-1 .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-accent-secondary-1 .header__burger-line {
  background-color: #ffffff;
}

.header.bg-accent-secondary-2 .menu > li > a {
  color: #ffffff;
}

.header.bg-accent-secondary-2 .menu > li > a:hover {
  color: #cccccc;
}

.header.bg-accent-secondary-2 .menu > li > a:before {
  background-color: #cccccc;
}

.header.bg-accent-secondary-2 .menu .sub-menu {
  background-color: var(--color-accent-secondary-2);
}

.header.bg-accent-secondary-2 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-accent-secondary-2 .menu .sub-menu > li a:hover {
  border-color: var(--color-accent-primary-1);
  background-color: var(--color-accent-secondary-1);
}

.header.bg-accent-secondary-2 .social__item a {
  color: #ffffff;
}

.header.bg-accent-secondary-2 .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-accent-secondary-2 .header__burger-line {
  background-color: #ffffff;
}

.header.bg-accent-secondary-3 .menu > li > a {
  color: #ffffff;
}

.header.bg-accent-secondary-3 .menu > li > a:hover {
  color: #cccccc;
}

.header.bg-accent-secondary-3 .menu > li > a:before {
  background-color: #cccccc;
}

.header.bg-accent-secondary-3 .menu .sub-menu {
  background-color: var(--color-accent-secondary-2);
}

.header.bg-accent-secondary-3 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-accent-secondary-3 .menu .sub-menu > li a:hover {
  border-color: var(--color-accent-primary-1);
  background-color: var(--color-accent-secondary-1);
}

.header.bg-accent-secondary-3 .social__item a {
  color: #ffffff;
}

.header.bg-accent-secondary-3 .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-accent-secondary-3 .header__burger-line {
  background-color: #ffffff;
}

.header.bg-accent-secondary-4 .menu > li > a {
  color: #ffffff;
}

.header.bg-accent-secondary-4 .menu > li > a:hover {
  color: #cccccc;
}

.header.bg-accent-secondary-4 .menu > li > a:before {
  background-color: #cccccc;
}

.header.bg-accent-secondary-4 .menu .sub-menu {
  background-color: var(--color-accent-secondary-2);
}

.header.bg-accent-secondary-4 .menu .sub-menu > li a {
  color: #ffffff;
}

.header.bg-accent-secondary-4 .menu .sub-menu > li a:hover {
  border-color: var(--color-accent-primary-1);
  background-color: var(--color-accent-secondary-1);
}

.header.bg-accent-secondary-4 .social__item a {
  color: #ffffff;
}

.header.bg-accent-secondary-4 .social__item a:hover {
  color: var(--color-accent-primary-1);
}

.header.bg-accent-secondary-4 .header__burger-line {
  background-color: #ffffff;
}

/* ======================================================================== */
/* 35. inputSearch */
/* ======================================================================== */
.input-search__input {
  padding-right: 30px;
}

.input-search__submit {
  display: inline-block;
  font-size: 18px;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: transparent;
  border: none;
  color: #888888;
  outline: none;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
}

.input-search__submit:hover {
  color: #181818;
}

.input-search__submit:focus {
  outline: none;
}

/* ======================================================================== */
/* 36. logo */
/* ======================================================================== */
.logo {
  position: relative;
  color: var(--color-accent-primary-1);
  font-weight: bold;
  display: inline-flex;
}

.logo__wrapper-img {
  align-items: center;
  flex: 1 0 auto;
  height: 100%;
}

.logo__wrapper-img img {
  height: 50px;
}

/* ======================================================================== */
/* 37. menu */
/* ======================================================================== */
.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  word-wrap: normal;
  text-align: right;
  display: inline-block;
  font-size: 13px;
}

.menu > li {
  display: inline-block;
}

.menu > li:not(:last-child) {
  margin-right: 17px;
}

.menu > li a {
  display: block;
  color: #888888;
  padding: 15px;
}

.menu > li a:hover {
  color: var(--color-accent-primary-1);
}

.menu > li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: 'FontAwesome';
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.menu > li:not(.menu-item-has-children) a {
  position: relative;
}

.menu > li:not(.menu-item-has-children) a:hover:before {
  left: 15px;
  width: calc(100% - 30px);
}

.menu > li:not(.menu-item-has-children) a:before {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  background-color: var(--color-accent-primary-1);
  bottom: 12px;
  right: 15px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.menu .menu-item-has-children {
  position: relative;
}

.menu .menu-item-has-children > a:hover ~ ul {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.menu .sub-menu {
  position: absolute;
  top: 100%;
  left: 15px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translate(0, 10px);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  z-index: 50;
}

.menu .sub-menu > li {
  display: block;
  white-space: nowrap;
}

.menu .sub-menu > li a {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-left: 2px solid transparent;
}

.menu .sub-menu > li a:hover {
  border-color: var(--color-accent-primary-1);
  background-color: #f8f8f8;
}

.menu .sub-menu > li.menu-item-has-children > a:after {
  content: '\f105';
  font-family: 'FontAwesome';
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  padding-left: 15px;
}

.menu .sub-menu ul {
  top: 0;
  right: calc(100% + 1px);
  transform: translate(10px, 0);
}

.menu .sub-menu:hover {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

/* ======================================================================== */
/* 38. link */
/* ======================================================================== */
.link-arrow {
  display: inline-flex;
  align-items: center;
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  color: var(--color-accent-primary-1);
  font-weight: bold;
}

@media screen and (min-width: 320px) {
  .link-arrow {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .link-arrow {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.link-arrow__label {
  display: inline-block;
  vertical-align: middle;
}

.link-arrow__icon {
  display: inline-block;
  font-size: 20px !important;
  margin-bottom: 1px;
  margin-left: 10px;
  vertical-align: middle;
}

/* ======================================================================== */
/* 39. lazy */
/* ======================================================================== */
.lazy {
  background-color: rgba(136, 136, 136, 0.1);
}

.lazy_loaded {
  background-color: unset;
}

.lazy__img:not(img) {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.lazy-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(136, 136, 136, 0.1);
}

.lazy-bg_loaded {
  background-color: unset;
}

/* ======================================================================== */
/* 40. menuOverlay */
/* ======================================================================== */
.menu-overlay {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-overlay > li {
  display: block;
}

.menu-overlay > li > a {
  display: block;
  font-family: var(--font-secondary);
  font-size: calc(28 * 1px);
  font-weight: bold;
}

@media screen and (min-width: 320px) {
  .menu-overlay > li > a {
    font-size: calc(28 * 1px + (42 - 28) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .menu-overlay > li > a {
    font-size: calc(42 * 1px);
  }
}

.menu-overlay__item-wrapper {
  font-family: var(--font-secondary);
}

.menu-overlay .sub-menu {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.menu-overlay .sub-menu > li {
  display: block;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.menu-overlay .sub-menu > li > a {
  display: block;
  font-family: var(--font-secondary);
  font-size: 20px;
  font-size: calc(20 * 1px);
  font-weight: bold;
  line-height: 1.2;
  padding: 8px 0;
}

@media screen and (min-width: 320px) {
  .menu-overlay .sub-menu > li > a {
    font-size: calc(20 * 1px + (26 - 20) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .menu-overlay .sub-menu > li > a {
    font-size: calc(26 * 1px);
  }
}

@media screen and (max-width: 991px) {
  .menu-overlay > li > a {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
}

/* ======================================================================== */
/* 41. overlay */
/* ======================================================================== */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}

.overlay_dark {
  background-color: #181818;
  opacity: .8;
  mix-blend-mode: multiply;
}

.overlay_black {
  background-color: #111111;
  opacity: .8;
}

.overlay_white {
  background-color: #ffffff;
  opacity: .95;
}

.overlay_white-solid {
  background-color: #ffffff;
  opacity: 1;
}

/* ======================================================================== */
/* 42. pagination */
/* ======================================================================== */
.pagination {
  margin-top: 60px;
  border-top: 2px solid #181818;
  padding: 30px 0 0;
}

.pagination .nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination .nav-links__container {
  margin-left: auto;
  margin-right: auto;
}

.pagination .page-numbers {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  margin: 0 15px;
  background-image: none;
  vertical-align: middle;
}

.pagination .page-numbers.prev {
  font-size: 20px;
  font-weight: normal;
  margin-left: 0;
}

.pagination .page-numbers.next {
  font-size: 20px;
  font-weight: normal;
  margin-right: 0;
}

.pagination .page-numbers:not(a) {
  color: #888888;
}

.page-links {
  margin-top: 50px;
  border-top: 2px solid var(--color-accent-secondary-2);
  padding: 30px 0 0;
}

.page-links .page-number {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  margin: 0 15px;
  border: none;
}

.page-links a {
  background-image: none;
}

.page-links .post-page-numbers:not(a) {
  color: #888888;
}

.comments-pagination {
  text-align: center;
}

.comments-pagination .page-numbers {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  margin: 0 20px;
}

/* ======================================================================== */
/* 43. post */
/* ======================================================================== */
.post__content > *:first-child, .post__comments > *:first-child {
  margin-top: 0;
}

.post__content ul, .post__comments ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5em;
}

.post__content ul li, .post__comments ul li {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

.post__content ul ul, .post__content ul ol, .post__comments ul ul, .post__comments ul ol {
  padding-left: 1em;
}

.post__content ul, .post__comments ul {
  padding-left: 0.25em;
}

.post__content ul > li:before, .post__comments ul > li:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 1px;
  vertical-align: middle;
  margin-right: 0.5em;
  margin-bottom: 3px;
  background-color: #181818;
}

.post__content ol, .post__comments ol {
  margin-bottom: 24px;
  padding-left: 1.5em;
}

.post__content ol li, .post__comments ol li {
  display: list-item;
  margin-top: 12px;
  margin-bottom: 12px;
}

.post__content ol ul, .post__content ol ol, .post__comments ol ul, .post__comments ol ol {
  padding-left: 16px;
}

.post__media {
  margin-bottom: 2em;
}

.post__tags {
  border-top: 2px solid var(--color-accent-secondary-2);
  margin-top: 50px;
  padding-top: 20px;
}

.post__comments {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .post__comments {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .post__comments {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.post__read-more {
  padding: 16px 30px;
}

.sticky {
  background-color: #cccccc;
  padding: 50px;
}

/* ======================================================================== */
/* 44. postPreview */
/* ======================================================================== */
.post-preview__wrapper-content > *:first-child {
  margin-top: 0;
}

.post-preview__media {
  display: block;
  margin-bottom: 1.5em;
  overflow: hidden;
}

.post-preview__media:hover, .post-preview__media:focus {
  opacity: 1;
}

.post-preview__media:hover > * {
  transform: scale(1.05);
}

.post-preview__media > * {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.post-preview__header h4 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.post-preview__header a {
  color: var(--color-accent-secondary-2);
}

.post-preview__header a:hover {
  color: var(--color-accent-primary-1);
}

.post-preview__categories {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.post-preview__categories li {
  display: inline-block;
}

.post-preview__meta {
  margin-bottom: 1em;
  font-size: 13px;
}

.post-preview__date {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
}

@media screen and (min-width: 320px) {
  .post-preview__date {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .post-preview__date {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.post-preview__wrapper-meta a {
  color: #888888;
}

.post-preview__wrapper-meta .post-preview__date a {
  color: var(--color-accent-primary-1);
}

.post-preview__wrapper-readmore {
  text-align: right;
  margin-top: 2em;
}

@media screen and (max-width: 991px) {
  .post-preview__meta {
    margin: 0.25em 0;
  }
  .post-preview__wrapper-meta {
    margin: 0 0 1em;
  }
  .post-preview__date {
    width: 100%;
  }
}

/* ======================================================================== */
/* 45. artParallax */
/* ======================================================================== */
[data-art-parallax='image'], [data-art-parallax='background'] {
  position: relative;
  overflow: hidden;
}

[data-art-parallax='image'] img, [data-art-parallax='background'] img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.art-parallax__bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

/* ======================================================================== */
/* 46. preloader */
/* ======================================================================== */
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.preloader__curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.preloader__content {
  position: relative;
  width: 160px;
  height: 160px;
  z-index: 50;
  overflow: hidden;
}

.preloader__wrapper-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-bottom: 2px solid rgba(136, 136, 136, 0.3);
}

.preloader__curtain_inner {
  top: 99%;
  animation-name: preloader-load;
  animation-iteration-count: 1;
  animation-duration: 25s;
  transform-origin: left center;
}

@keyframes preloader-load {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

/* ======================================================================== */
/* 47. scroll */
/* ======================================================================== */
.smooth-scroll {
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

[data-os-animation] {
  opacity: 0;
  visibility: hidden;
}

[data-os-animation="animated"] {
  opacity: 1;
  visibility: visible;
}

/* ======================================================================== */
/* 48. scrollDown */
/* ======================================================================== */
.scroll-down {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100px;
}

.scroll-down:hover .scroll-down__line {
  transform: scaleY(0);
  transition-delay: 0s;
}

.scroll-down:hover .scroll-down__circle-1 {
  transform: translateY(110px);
  transition-delay: 0.2s;
}

.scroll-down__circle-1 {
  display: block;
  width: 12px;
  height: 12px;
  background-color: var(--color-accent-primary-3);
  border-radius: 100%;
  transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.scroll-down__line {
  width: 1px;
  height: 60px;
  background-color: var(--color-accent-primary-3);
  margin-top: 16px;
  margin-bottom: 16px;
  transform-origin: bottom center;
  transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0.2s;
}

.scroll-down__circle-2 {
  width: 24px;
  height: 24px;
  border: 1px solid #888888;
  border-radius: 100%;
  transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@media screen and (max-width: 767px) {
  .scroll-down__circle-1 {
    width: 8px;
    height: 8px;
  }
  .scroll-down__line {
    height: 40px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .scroll-down__circle-2 {
    width: 18px;
    height: 18px;
  }
  .scroll-down:hover .scroll-down__circle-1 {
    transform: translateY(69px);
  }
}

/* ======================================================================== */
/* 49. section */
/* ======================================================================== */
.section {
  position: relative;
  z-index: 50;
}

.section-fullheight {
  display: flex;
}

.section-fullheight__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.section__headline {
  display: block;
  width: 100%;
  max-width: 120px;
  height: 1px;
  background-color: var(--color-accent-primary-1);
  margin-top: calc(1 * (40 * 1px));
}

@media screen and (min-width: 320px) {
  .section__headline {
    margin-top: calc(1 * (40 * 1px + (80 - 40) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section__headline {
    margin-top: calc(1 * (80 * 1px));
  }
}

.section_z-100 {
  z-index: 100;
}

.section__content {
  position: relative;
  z-index: 60;
}

@media screen and (max-width: 991px) {
  .section__headline {
    max-width: 80px;
  }
}

@media screen and (max-width: 576px) {
  .section__headline {
    max-width: 50px;
  }
}

/* ======================================================================== */
/* 50. sectionHeight */
/* ======================================================================== */
.section_h-100 {
  height: 100px;
  max-height: 100vh;
}

.section_h-200 {
  height: 200px;
  max-height: 100vh;
}

.section_h-300 {
  height: 300px;
  max-height: 100vh;
}

.section_h-400 {
  height: 400px;
  max-height: 100vh;
}

.section_h-500 {
  height: 500px;
  max-height: 100vh;
}

.section_h-600 {
  height: 600px;
  max-height: 100vh;
}

.section_h-700 {
  height: 700px;
  max-height: 100vh;
}

.section_h-800 {
  height: 800px;
  max-height: 100vh;
}

.section_h-900 {
  height: 900px;
  max-height: 100vh;
}

.section_h-100vh {
  height: 100vh;
}

.section_w-container-right {
  max-width: 100%;
  width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px);
  margin-left: auto;
  text-align: left;
}

.section_w-container-left {
  max-width: 100%;
  width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px);
  margin-right: auto;
  text-align: right;
}

@media screen and (max-width: 1400px) {
  .section_w-container-right, .section_w-container-left {
    width: 100%;
    text-align: center;
  }
  .section_w-container-right .section-image__caption-vertical-left, .section_w-container-right .section-image__caption-vertical-right, .section_w-container-left .section-image__caption-vertical-left, .section_w-container-left .section-image__caption-vertical-right {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
    padding: 0 20px;
    margin-top: 1em;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .section_h-100 {
    max-height: 70vh;
  }
  .section_h-200 {
    max-height: 70vh;
  }
  .section_h-300 {
    max-height: 70vh;
  }
  .section_h-400 {
    max-height: 70vh;
  }
  .section_h-500 {
    max-height: 70vh;
  }
  .section_h-600 {
    max-height: 70vh;
  }
  .section_h-700 {
    max-height: 70vh;
  }
  .section_h-800 {
    max-height: 70vh;
  }
  .section_h-900 {
    max-height: 70vh;
  }
}

/* ======================================================================== */
/* 51. sectionMarginsPaddings */
/* ======================================================================== */
.section-offset_bottom {
  z-index: 60;
}

.section_pt-small.section-offset_bottom {
  padding-top: 0;
}

.section_pt-small.section-offset_bottom .section-offset__content {
  transform: translateY(calc(1 * var(--distance-min-small) * 1px));
}

@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt-small.section-offset_bottom .section-offset__content {
    transform: translateY(calc(1 * ((var(--distance-min-small) * 0.67 * 1px) + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600))));
  }
}

@media only screen and (min-width: 1920px) {
  .section_pt-small.section-offset_bottom .section-offset__content {
    transform: translateY(calc(1 * var(--distance-max-small) * 1px));
  }
}

.section_pt-small.section-offset_bottom.section_mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt-small.section-offset_bottom.section_mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-small.section-offset_bottom.section_mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_pt-small.section-offset_bottom.section_mb {
  margin-bottom: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt-small.section-offset_bottom.section_mb {
    margin-bottom: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-small.section-offset_bottom.section_mb {
    margin-bottom: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_pt-small.section-offset_bottom.section_mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt-small.section-offset_bottom.section_mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-small.section-offset_bottom.section_mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_pt.section-offset_bottom {
  padding-top: 0;
}

.section_pt.section-offset_bottom .section-offset__content {
  transform: translateY(calc(1 * var(--distance-min-normal) * 1px));
}

@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt.section-offset_bottom .section-offset__content {
    transform: translateY(calc(1 * ((var(--distance-min-normal) * 0.67 * 1px) + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600))));
  }
}

@media only screen and (min-width: 1920px) {
  .section_pt.section-offset_bottom .section-offset__content {
    transform: translateY(calc(1 * var(--distance-max-normal) * 1px));
  }
}

.section_pt.section-offset_bottom.section_mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt.section-offset_bottom.section_mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt.section-offset_bottom.section_mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_pt.section-offset_bottom.section_mb {
  margin-bottom: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt.section-offset_bottom.section_mb {
    margin-bottom: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt.section-offset_bottom.section_mb {
    margin-bottom: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_pt.section-offset_bottom.section_mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt.section-offset_bottom.section_mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt.section-offset_bottom.section_mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_pt-large.section-offset_bottom {
  padding-top: 0;
}

.section_pt-large.section-offset_bottom .section-offset__content {
  transform: translateY(calc(1 * var(--distance-min-normal) * 1px));
}

@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt-large.section-offset_bottom .section-offset__content {
    transform: translateY(calc(1 * ((var(--distance-min-normal) * 0.67 * 1px) + (var(--distance-max-large) - var(--distance-min-normal)) * ((100vw - 320px) / 1600))));
  }
}

@media only screen and (min-width: 1920px) {
  .section_pt-large.section-offset_bottom .section-offset__content {
    transform: translateY(calc(1 * var(--distance-max-large) * 1px));
  }
}

.section_pt-large.section-offset_bottom.section_mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt-large.section-offset_bottom.section_mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-large.section-offset_bottom.section_mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_pt-large.section-offset_bottom.section_mb {
  margin-bottom: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt-large.section-offset_bottom.section_mb {
    margin-bottom: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-large.section-offset_bottom.section_mb {
    margin-bottom: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_pt-large.section-offset_bottom.section_mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pt-large.section-offset_bottom.section_mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-large.section-offset_bottom.section_mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_pb-small.section-offset_top {
  padding-bottom: 0;
}

.section_pb-small.section-offset_top .section-offset__content {
  transform: translateY(calc(-1 * var(--distance-min-small) * 1px));
}

@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pb-small.section-offset_top .section-offset__content {
    transform: translateY(calc(-1 * ((var(--distance-min-small) * 0.67 * 1px) + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600))));
  }
}

@media only screen and (min-width: 1920px) {
  .section_pb-small.section-offset_top .section-offset__content {
    transform: translateY(calc(-1 * var(--distance-max-small) * 1px));
  }
}

.section_pb-small.section-offset_top.section_mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb-small.section-offset_top.section_mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-small.section-offset_top.section_mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_pb-small.section-offset_top.section_mt {
  margin-top: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb-small.section-offset_top.section_mt {
    margin-top: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-small.section-offset_top.section_mt {
    margin-top: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_pb-small.section-offset_top.section_mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb-small.section-offset_top.section_mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-small.section-offset_top.section_mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_pb.section-offset_top {
  padding-bottom: 0;
}

.section_pb.section-offset_top .section-offset__content {
  transform: translateY(calc(-1 * var(--distance-min-normal) * 1px));
}

@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pb.section-offset_top .section-offset__content {
    transform: translateY(calc(-1 * ((var(--distance-min-normal) * 0.67 * 1px) + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600))));
  }
}

@media only screen and (min-width: 1920px) {
  .section_pb.section-offset_top .section-offset__content {
    transform: translateY(calc(-1 * var(--distance-max-normal) * 1px));
  }
}

.section_pb.section-offset_top.section_mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb.section-offset_top.section_mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb.section-offset_top.section_mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_pb.section-offset_top.section_mt {
  margin-top: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb.section-offset_top.section_mt {
    margin-top: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb.section-offset_top.section_mt {
    margin-top: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_pb.section-offset_top.section_mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb.section-offset_top.section_mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb.section-offset_top.section_mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_pb-large.section-offset_top {
  padding-bottom: 0;
}

.section_pb-large.section-offset_top .section-offset__content {
  transform: translateY(calc(-1 * var(--distance-min-large) * 1px));
}

@media only screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pb-large.section-offset_top .section-offset__content {
    transform: translateY(calc(-1 * ((var(--distance-min-large) * 0.67 * 1px) + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600))));
  }
}

@media only screen and (min-width: 1920px) {
  .section_pb-large.section-offset_top .section-offset__content {
    transform: translateY(calc(-1 * var(--distance-max-large) * 1px));
  }
}

.section_pb-large.section-offset_top.section_mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb-large.section-offset_top.section_mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-large.section-offset_top.section_mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_pb-large.section-offset_top.section_mt {
  margin-top: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb-large.section-offset_top.section_mt {
    margin-top: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-large.section-offset_top.section_mt {
    margin-top: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_pb-large.section-offset_top.section_mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_pb-large.section-offset_top.section_mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-large.section-offset_top.section_mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_pt-xsmall {
  padding-top: calc(var(--distance-min-xsmall) * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt-xsmall {
    padding-top: calc(var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-xsmall {
    padding-top: calc(var(--distance-max-xsmall) * 1px);
  }
}

.section_pt-small {
  padding-top: calc(var(--distance-min-small) * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt-small {
    padding-top: calc(var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-small {
    padding-top: calc(var(--distance-max-small) * 1px);
  }
}

.section_pt {
  padding-top: calc(var(--distance-min-normal) * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt {
    padding-top: calc(var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt {
    padding-top: calc(var(--distance-max-normal) * 1px);
  }
}

.section_pt-large {
  padding-top: calc(var(--distance-min-large) * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt-large {
    padding-top: calc(var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-large {
    padding-top: calc(var(--distance-max-large) * 1px);
  }
}

.section_pt-xlarge {
  padding-top: calc(var(--distance-min-xlarge) * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section_pt-xlarge {
    padding-top: calc(var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pt-xlarge {
    padding-top: calc(var(--distance-max-xlarge) * 1px);
  }
}

.section_pb-xsmall {
  padding-bottom: calc(var(--distance-min-xsmall) * 1px);
}

@media screen and (min-width: 320px) {
  .section_pb-xsmall {
    padding-bottom: calc(var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-xsmall {
    padding-bottom: calc(var(--distance-max-xsmall) * 1px);
  }
}

.section_pb-small {
  padding-bottom: calc(var(--distance-min-small) * 1px);
}

@media screen and (min-width: 320px) {
  .section_pb-small {
    padding-bottom: calc(var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-small {
    padding-bottom: calc(var(--distance-max-small) * 1px);
  }
}

.section_pb {
  padding-bottom: calc(var(--distance-min-normal) * 1px);
}

@media screen and (min-width: 320px) {
  .section_pb {
    padding-bottom: calc(var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb {
    padding-bottom: calc(var(--distance-max-normal) * 1px);
  }
}

.section_pb-large {
  padding-bottom: calc(var(--distance-min-large) * 1px);
}

@media screen and (min-width: 320px) {
  .section_pb-large {
    padding-bottom: calc(var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-large {
    padding-bottom: calc(var(--distance-max-large) * 1px);
  }
}

.section_pb-xlarge {
  padding-bottom: calc(var(--distance-min-xlarge) * 1px);
}

@media screen and (min-width: 320px) {
  .section_pb-xlarge {
    padding-bottom: calc(var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section_pb-xlarge {
    padding-bottom: calc(var(--distance-max-xlarge) * 1px);
  }
}

.section_mt-xsmall {
  margin-top: calc(1 * (var(--distance-min-xsmall) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mt-xsmall {
    margin-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mt-xsmall {
    margin-top: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.section_mt-small {
  margin-top: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mt-small {
    margin-top: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_mt {
  margin-top: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mt {
    margin-top: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mt {
    margin-top: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_mt-large {
  margin-top: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mt-large {
    margin-top: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_mt-xlarge {
  margin-top: calc(1 * (var(--distance-min-xlarge) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mt-xlarge {
    margin-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mt-xlarge {
    margin-top: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.section_mb-xsmall {
  margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mb-xsmall {
    margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mb-xsmall {
    margin-bottom: calc(1 * (var(--distance-max-xsmall) * 1px));
  }
}

.section_mb-small {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mb-small {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

.section_mb {
  margin-bottom: calc(1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mb {
    margin-bottom: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mb {
    margin-bottom: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section_mb-large {
  margin-bottom: calc(1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mb-large {
    margin-bottom: calc(1 * (var(--distance-max-large) * 1px));
  }
}

.section_mb-xlarge {
  margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px));
}

@media screen and (min-width: 320px) {
  .section_mb-xlarge {
    margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_mb-xlarge {
    margin-bottom: calc(1 * (var(--distance-max-xlarge) * 1px));
  }
}

.section_minus-mt-xsmall {
  margin-top: calc(-1 * (var(--distance-min-xsmall) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mt-xsmall {
    margin-top: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mt-xsmall {
    margin-top: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.section_minus-mt-small {
  margin-top: calc(-1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mt-small {
    margin-top: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mt-small {
    margin-top: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.section_minus-mt {
  margin-top: calc(-1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mt {
    margin-top: calc(-1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mt {
    margin-top: calc(-1 * (var(--distance-max-normal) * 1px));
  }
}

.section_minus-mt-large {
  margin-top: calc(-1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mt-large {
    margin-top: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mt-large {
    margin-top: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.section_minus-mt-xlarge {
  margin-top: calc(-1 * (var(--distance-min-xlarge) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mt-xlarge {
    margin-top: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mt-xlarge {
    margin-top: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

.section_minus-mb-xsmall {
  margin-bottom: calc(-1 * (var(--distance-min-xsmall) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mb-xsmall {
    margin-bottom: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mb-xsmall {
    margin-bottom: calc(-1 * (var(--distance-max-xsmall) * 1px));
  }
}

.section_minus-mb-small {
  margin-bottom: calc(-1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mb-small {
    margin-bottom: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mb-small {
    margin-bottom: calc(-1 * (var(--distance-max-small) * 1px));
  }
}

.section_minus-mb {
  margin-bottom: calc(-1 * (var(--distance-min-normal) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mb {
    margin-bottom: calc(-1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mb {
    margin-bottom: calc(-1 * (var(--distance-max-normal) * 1px));
  }
}

.section_minus-mb-large {
  margin-bottom: calc(-1 * (var(--distance-min-large) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mb-large {
    margin-bottom: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mb-large {
    margin-bottom: calc(-1 * (var(--distance-max-large) * 1px));
  }
}

.section_minus-mb-xlarge {
  margin-bottom: calc(-1 * (var(--distance-min-xlarge) * 1px));
}

@media screen and (min-width: 320px) {
  .section_minus-mb-xlarge {
    margin-bottom: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section_minus-mb-xlarge {
    margin-bottom: calc(-1 * (var(--distance-max-xlarge) * 1px));
  }
}

/* ======================================================================== */
/* 52. sectionAbout */
/* ======================================================================== */
.section-about__header {
  position: relative;
  margin-left: -100px;
  margin-bottom: calc(1 * (30 * 1px));
}

@media screen and (min-width: 320px) {
  .section-about__header {
    margin-bottom: calc(1 * (30 * 1px + (50 - 30) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-about__header {
    margin-bottom: calc(1 * (50 * 1px));
  }
}

.section-about__content {
  margin-top: calc(1 * (var(--distance-min-normal) * 1px));
  max-width: 550px;
  z-index: 50;
}

@media screen and (min-width: 320px) {
  .section-about__content {
    margin-top: calc(1 * (var(--distance-min-normal) * 1px + (var(--distance-max-normal) - var(--distance-min-normal)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-about__content {
    margin-top: calc(1 * (var(--distance-max-normal) * 1px));
  }
}

.section-about__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.section-about__headline {
  margin-top: 0;
  margin-bottom: calc(1 * (30 * 1px));
}

@media screen and (min-width: 320px) {
  .section-about__headline {
    margin-bottom: calc(1 * (30 * 1px + (60 - 30) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-about__headline {
    margin-bottom: calc(1 * (60 * 1px));
  }
}

.section-about__decor {
  position: absolute;
  top: -200px;
  right: -100px;
  z-index: -1;
}

.section-about__wrapper-counter {
  padding-top: calc(25 * 1px);
  padding-bottom: calc(25 * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section-about__wrapper-counter {
    padding-top: calc(25 * 1px + (50 - 25) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-about__wrapper-counter {
    padding-top: calc(50 * 1px);
  }
}

@media screen and (min-width: 320px) {
  .section-about__wrapper-counter {
    padding-bottom: calc(25 * 1px + (50 - 25) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-about__wrapper-counter {
    padding-bottom: calc(50 * 1px);
  }
}

.section-about__wrapper-content {
  padding-left: 80px;
  padding-right: 80px;
}

.section-about__wrapper-content > *:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .section-about__content {
    margin-top: 0;
    max-width: 100%;
  }
  .section-about__header {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .section-about__wrapper-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section-about__wrapper-img {
    margin-top: calc(1 * (40 * 1px));
  }
}

@media screen and (max-width: 991px) and (min-width: 320px) {
  .section-about__wrapper-img {
    margin-top: calc(1 * (40 * 1px + (80 - 40) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (max-width: 991px) and (min-width: 1920px) {
  .section-about__wrapper-img {
    margin-top: calc(1 * (80 * 1px));
  }
}

/* ======================================================================== */
/* 53. sectionAboutThemes */
/* ======================================================================== */
.section-about.bg-dark .section-about__heading, .section-about.bg-dark-2 .section-about__heading, .section-about.bg-dark-3 .section-about__heading {
  color: #ffffff;
}

.section-about.bg-dark .counter__number, .section-about.bg-dark-2 .counter__number, .section-about.bg-dark-3 .counter__number {
  color: var(--color-accent-primary-1);
}

.section-about.bg-dark .counter__label, .section-about.bg-dark-2 .counter__label, .section-about.bg-dark-3 .counter__label {
  color: #ffffff;
}

/* ======================================================================== */
/* 54. sectionAwards */
/* ======================================================================== */
.section-awards__wrapper-item:first-child .figure-award {
  padding-top: 0;
}

.section-awards__wrapper-item:last-child .figure-award {
  padding-bottom: 0;
}

.section-awards__wrapper-item:last-child .figure-award__border-line {
  display: none;
}

/* ======================================================================== */
/* 55. sectionAwardsThemes */
/* ======================================================================== */
.section-awards.bg-dark .figure-award__border-line, .section-awards.bg-dark-2 .figure-award__border-line, .section-awards.bg-dark-3 .figure-award__border-line {
  border-color: rgba(136, 136, 136, 0.1);
}

/* ======================================================================== */
/* 56. section404 */
/* ======================================================================== */
.section-404__wrapper-button {
  margin-top: 60px;
}

/* ======================================================================== */
/* 57. sectionBlog */
/* ======================================================================== */
.section-blog .search-form {
  margin-top: 2em;
}

.section-blog__wrapper-post {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
}

.section-blog__wrapper-post:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

@media only screen and (max-width: 767px) {
  .section-blog__wrapper-post {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

/* ======================================================================== */
/* 58. sectionCTA */
/* ======================================================================== */
.section-cta__line {
  width: 1px;
  height: 120px;
  background-color: var(--color-accent-primary-1);
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
}

.section-cta__heading {
  margin-top: 0;
}

.section-cta__wrapper-button {
  margin-top: calc(1 * (30 * 1px));
}

@media screen and (min-width: 320px) {
  .section-cta__wrapper-button {
    margin-top: calc(1 * (30 * 1px + (50 - 30) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-cta__wrapper-button {
    margin-top: calc(1 * (50 * 1px));
  }
}

@media screen and (max-width: 991px) {
  .section-cta__line {
    height: 60px;
    top: -30px;
  }
}

/* ======================================================================== */
/* 59. sectionCTAThemes */
/* ======================================================================== */
.section-cta.bg-dark .section-cta__heading, .section-cta.bg-dark-2 .section-cta__heading, .section-cta.bg-dark-3 .section-cta__heading {
  color: #ffffff;
}

/* ======================================================================== */
/* 60. sectionContactsThemes */
/* ======================================================================== */
.section-contacts.bg-dark .figure-contact__icon, .section-contacts.bg-dark-2 .figure-contact__icon, .section-contacts.bg-dark-3 .figure-contact__icon {
  color: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 61. sectionContent */
/* ======================================================================== */
.section-content__content {
  margin-bottom: -1em;
}

.section-content__content *:first-child:not(.section-content__headline):not(.section-content__subheading) {
  margin-top: 0 !important;
}

.section-content__header *:first-child:not(.section-content__headline):not(.section-content__subheading) {
  margin-top: 0 !important;
}

.section-content__headline {
  margin-top: 14px !important;
}

.section-content__header_mb {
  margin-bottom: calc(1 * (0 * 1px));
}

@media screen and (min-width: 320px) {
  .section-content__header_mb {
    margin-bottom: calc(1 * (0 * 1px + (40 - 0) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-content__header_mb {
    margin-bottom: calc(1 * (40 * 1px));
  }
}

.section-content__headline_right {
  margin-left: auto;
}

.section-content__headline_left {
  margin-right: auto;
}

.section-content__headline_center {
  margin-left: auto;
  margin-right: auto;
}

.section-content__subheading {
  margin-top: 5px;
}

@media screen and (max-width: 991px) {
  .section-content__content {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .section-content__content *:last-child {
    margin-bottom: 0;
  }
  .section-content__header *:last-child {
    margin-bottom: 0;
  }
  .section-content__headline {
    margin-top: 0em !important;
    margin-bottom: 0em !important;
  }
  .section-content__headline_right {
    margin-left: 0;
    margin-right: auto;
  }
  .section-content__subheading {
    margin-top: 1em !important;
  }
}

/* ======================================================================== */
/* 62. sectionFeatures */
/* ======================================================================== */
.section-features__wrapper-item {
  padding-top: calc(25 * 1px);
  padding-bottom: calc(25 * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section-features__wrapper-item {
    padding-top: calc(25 * 1px + (50 - 25) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-features__wrapper-item {
    padding-top: calc(50 * 1px);
  }
}

@media screen and (min-width: 320px) {
  .section-features__wrapper-item {
    padding-bottom: calc(25 * 1px + (50 - 25) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-features__wrapper-item {
    padding-bottom: calc(50 * 1px);
  }
}

/* ======================================================================== */
/* 63. sectionFeaturesThemes */
/* ======================================================================== */
.section-features.bg-dark .figure-feature__heading, .section-features.bg-dark-2 .figure-feature__heading, .section-features.bg-dark-3 .figure-feature__heading {
  color: #ffffff;
}

/* ======================================================================== */
/* 64. sectionFullscreenSlider */
/* ======================================================================== */
.section-fullscreen-slider.color-white .slider__dot:before {
  background-color: #888888;
}

.section-fullscreen-slider.color-white .slider__dot:after {
  border-color: #ffffff;
}

.section-fullscreen-slider.color-white .slider__dot_active:before {
  background-color: #ffffff;
  border-color: #ffffff;
}

.section-fullscreen-slider__inner {
  padding-top: 0;
  padding-bottom: 0;
  min-height: calc(var(--fix-bar-vh, 1vh) * 100);
}

/* ======================================================================== */
/* 65. sectionImage */
/* ======================================================================== */
.section-image {
  display: flex;
  flex-direction: column;
}

.section-image__content {
  margin-top: 40px;
}

.section-image__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.section-image__caption {
  display: inline-block;
  font-weight: bold;
  margin-top: 1em;
}

.section-image__caption-vertical-left {
  position: absolute;
  bottom: -1em;
  left: -2em;
  transform: rotate(-90deg);
  transform-origin: left center;
  text-align: left;
}

.section-image__caption-vertical-right {
  position: absolute;
  top: -2em;
  right: -2em;
  transform: rotate(-90deg);
  transform-origin: right center;
  text-align: left;
}

.section-image__wrapper_absolute {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-image__overlay {
  z-index: 0;
}

@media screen and (max-width: 1280px) {
  .section-image__caption-vertical-left {
    left: -1.5em;
  }
  .section-image__caption-vertical-right {
    right: -1.5em;
  }
}

@media screen and (max-width: 991px) {
  .section-image__caption-vertical-left, .section-image__caption-vertical-right {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
    padding: 0 20px;
    margin-top: 1em;
    text-align: center;
    width: 100%;
  }
}

/* ======================================================================== */
/* 66. sectionIntro */
/* ======================================================================== */
.section-intro__heading {
  font-size: calc(58 * 1px);
  margin-bottom: 0.4em !important;
}

@media screen and (min-width: 320px) {
  .section-intro__heading {
    font-size: calc(58 * 1px + (107 - 58) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-intro__heading {
    font-size: calc(107 * 1px);
  }
}

@media screen and (max-width: 991px) {
  .section-intro__heading {
    margin-bottom: 0.25em !important;
  }
}

/* ======================================================================== */
/* 67. sectionLatestPostsThemes */
/* ======================================================================== */
.section-latest-posts.bg-dark .figure-post__heading, .section-latest-posts.bg-dark-2 .figure-post__heading, .section-latest-posts.bg-dark-3 .figure-post__heading {
  color: #ffffff;
}

/* ======================================================================== */
/* 68. sectionNavProjects */
/* ======================================================================== */
.section-nav-projects__inner {
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  padding-top: calc(var(--distance-min-small) * 1px);
  padding-bottom: calc(var(--distance-min-small) * 1px);
  overflow: hidden;
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section-nav-projects__inner {
    padding-top: calc(var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-nav-projects__inner {
    padding-top: calc(var(--distance-max-small) * 1px);
  }
}

@media screen and (min-width: 320px) {
  .section-nav-projects__inner {
    padding-bottom: calc(var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-nav-projects__inner {
    padding-bottom: calc(var(--distance-max-small) * 1px);
  }
}

.section-nav-projects__inner_all {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-nav-projects__inner_prev {
  text-align: left;
  justify-content: flex-start;
}

.section-nav-projects__inner_next {
  text-align: right;
  justify-content: flex-end;
}

.section-nav-projects__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 32px !important;
}

.section-nav-projects__arrow_prev {
  left: 120px;
}

.section-nav-projects__arrow_next {
  right: 120px;
}

.section-nav-projects__heading {
  font-family: var(--font-secondary);
  font-size: calc(34 * 1px);
  font-weight: bold;
  line-height: 1.28;
  margin-bottom: 0.25em;
}

@media screen and (min-width: 320px) {
  .section-nav-projects__heading {
    font-size: calc(34 * 1px + (52 - 34) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-nav-projects__heading {
    font-size: calc(52 * 1px);
  }
}

.section-nav-projects__wrapper-heading_prev {
  padding-right: 20px;
  padding-left: 120px;
}

.section-nav-projects__wrapper-heading_next {
  padding-left: 20px;
  padding-right: 120px;
}

@media screen and (max-width: 1400px) {
  .section-nav-projects__arrow_prev {
    left: 70px;
  }
  .section-nav-projects__arrow_next {
    right: 70px;
  }
  .section-nav-projects__wrapper-heading_prev {
    padding-left: 80px;
  }
  .section-nav-projects__wrapper-heading_next {
    padding-right: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .section-nav-projects__arrow_prev {
    left: 40px;
  }
  .section-nav-projects__arrow_next {
    right: 40px;
  }
  .section-nav-projects__wrapper-heading_prev {
    padding-left: 50px;
  }
  .section-nav-projects__wrapper-heading_next {
    padding-right: 50px;
  }
}

@media screen and (max-width: 991px) {
  .section-nav-projects__arrow_prev {
    left: 10px;
  }
  .section-nav-projects__arrow_next {
    right: 10px;
  }
  .section-nav-projects__wrapper-heading_prev {
    padding-left: 20px;
  }
  .section-nav-projects__wrapper-heading_next {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .section-nav-projects__inner_prev {
    text-align: center;
    justify-content: center;
  }
  .section-nav-projects__inner_next {
    text-align: center;
    justify-content: center;
  }
  .section-nav-projects__inner:not(:last-child) {
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  }
  .section-nav-projects__arrow {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    transform: none;
    margin-top: 0.25em;
    font-size: 24px !important;
  }
  .section-nav-projects__inner {
    flex-direction: column;
  }
}

/* ======================================================================== */
/* 69. sectionNavProjectsThemes */
/* ======================================================================== */
.section-nav-projects.bg-white .section-nav-projects__inner {
  color: var(--color-accent-secondary-2);
}

.section-nav-projects.bg-white .section-nav-projects__inner:hover {
  background-color: #f8f8f8;
}

.section-nav-projects.bg-white .button-circles__circle svg .circle {
  stroke: var(--color-accent-secondary-2);
}

.section-nav-projects.bg-off-white .section-nav-projects__inner {
  color: var(--color-accent-primary-3);
}

.section-nav-projects.bg-off-white .section-nav-projects__inner:hover {
  background-color: #ffffff;
}

.section-nav-projects.bg-light .section-nav-projects__inner {
  color: var(--color-accent-primary-3);
}

.section-nav-projects.bg-light .section-nav-projects__inner:hover {
  background-color: #f7f5f0;
}

.section-nav-projects.bg-black .section-nav-projects__inner:hover {
  background-color: #181818;
}

.section-nav-projects.bg-dark .section-nav-projects__inner:hover {
  background-color: #222222;
}

.section-nav-projects.bg-dark-2 .section-nav-projects__inner:hover {
  background-color: #333333;
}

.section-nav-projects.bg-dark-3 .section-nav-projects__inner:hover {
  background-color: #222222;
}

.section-nav-projects.bg-accent-primary-1 .section-nav-projects__inner {
  color: #ffffff;
}

.section-nav-projects.bg-accent-primary-1 .section-nav-projects__inner:hover {
  background-color: #333333;
}

.section-nav-projects.bg-accent-primary-1 .button-circles__circle svg .circle {
  stroke: #ffffff;
}

.section-nav-projects.bg-accent-primary-1 .button-circles__circle {
  border-color: #ffffff;
}

.section-nav-projects.bg-accent-primary-2 .section-nav-projects__inner {
  color: var(--color-accent-primary-3);
}

.section-nav-projects.bg-accent-primary-2 .section-nav-projects__inner:hover {
  background-color: #f7f5f0;
}

.section-nav-projects.bg-accent-primary-3 .section-nav-projects__inner {
  color: var(--color-accent-primary-1);
}

.section-nav-projects.bg-accent-primary-3 .section-nav-projects__inner:hover {
  background-color: #f8f8f8;
}

.section-nav-projects.bg-accent-primary-4 .section-nav-projects__inner {
  color: #ffffff;
}

.section-nav-projects.bg-accent-primary-4 .section-nav-projects__inner:hover {
  background-color: #333333;
}

.section-nav-projects.bg-accent-primary-4 .button-circles__circle svg .circle {
  stroke: #ffffff;
}

.section-nav-projects.bg-accent-primary-4 .button-circles__circle {
  border-color: #ffffff;
}

.section-nav-projects.bg-accent-secondary-1 .section-nav-projects__inner {
  color: #ffffff;
}

.section-nav-projects.bg-accent-secondary-1 .section-nav-projects__inner:hover {
  background-color: var(--color-accent-secondary-2);
}

.section-nav-projects.bg-accent-secondary-1 .button-circles__circle svg .circle {
  stroke: #ffffff;
}

.section-nav-projects.bg-accent-secondary-1 .button-circles__circle {
  border-color: #ffffff;
}

.section-nav-projects.bg-accent-secondary-2 .section-nav-projects__inner {
  color: #ffffff;
}

.section-nav-projects.bg-accent-secondary-2 .section-nav-projects__inner:hover {
  background-color: var(--color-accent-secondary-1);
}

.section-nav-projects.bg-accent-secondary-2 .button-circles__circle svg .circle {
  stroke: #ffffff;
}

.section-nav-projects.bg-accent-secondary-2 .button-circles__circle {
  border-color: #ffffff;
}

.section-nav-projects.bg-accent-secondary-3 .section-nav-projects__inner {
  color: #f8f8f8;
}

.section-nav-projects.bg-accent-secondary-3 .section-nav-projects__inner:hover {
  background-color: var(--color-accent-secondary-2);
}

.section-nav-projects.bg-accent-secondary-3 .button-circles__circle svg .circle {
  stroke: #f8f8f8;
}

.section-nav-projects.bg-accent-secondary-4 .section-nav-projects__inner {
  color: var(--color-accent-primary-1);
}

.section-nav-projects.bg-accent-secondary-4 .section-nav-projects__inner:hover {
  background-color: var(--color-accent-secondary-3);
}

.section-nav-projects.bg-accent-secondary-4 .button-circles__circle svg .circle {
  stroke: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 70. sectionPortfolio */
/* ======================================================================== */
.section-portfolio__wrapper-tabs {
  margin-bottom: calc(1 * (var(--distance-min-small) * 1px));
}

@media screen and (min-width: 320px) {
  .section-portfolio__wrapper-tabs {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-portfolio__wrapper-tabs {
    margin-bottom: calc(1 * (var(--distance-max-small) * 1px));
  }
}

/* ======================================================================== */
/* 71. sectionMasthead */
/* ======================================================================== */
.section-masthead {
  overflow: hidden;
}

.section-masthead__header h1 {
  margin-bottom: 0.75em;
}

.section-masthead__background_fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.section-masthead__overlay {
  z-index: 0;
}

.section-masthead__inner {
  position: relative;
  z-index: 50;
}

.section-masthead__wrapper-scroll-down {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  text-align: center;
  z-index: 50;
}

.section-masthead__inner_background {
  width: 760px;
  max-width: 50vw;
  padding-left: 50px;
  padding-right: 50px;
  background: none;
}

.section-masthead__inner_background-left {
  margin-right: auto;
  margin-left: 0;
  padding-left: 120px;
  padding-right: 30px;
}

.section-masthead__inner_background-left .section-masthead__wrapper-scroll-down {
  left: 80px;
  right: auto;
}

.section-masthead__inner_background-right {
  margin-left: auto;
  margin-right: 0;
  padding-left: 120px;
  padding-right: 30px;
}

.section-masthead__inner_background-right .section-masthead__wrapper-scroll-down {
  left: 80px;
  right: auto;
}

.section-masthead__background-right {
  left: 760px;
}

.section-masthead__background-left {
  right: 760px;
}

.section-masthead__meta {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -1em 1em;
  max-width: 100%;
}

.section-masthead__meta .post-preview__meta {
  margin: 1em;
  max-width: 100%;
  font-size: calc(13 * 1px);
}

@media screen and (min-width: 320px) {
  .section-masthead__meta .post-preview__meta {
    font-size: calc(13 * 1px + (16 - 13) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-masthead__meta .post-preview__meta {
    font-size: calc(16 * 1px);
  }
}

.section-masthead__meta .post-preview__meta a {
  color: #888888;
}

.section-masthead__meta .post-preview__date a {
  color: var(--color-accent-primary-1);
}

.section-masthead__curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-masthead_big-heading {
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.section-masthead__heading-big {
  margin-top: 0;
  margin-bottom: 0;
  color: #888888;
  opacity: .3;
}

.section-masthead__wrapper-button {
  margin-top: 2em;
}

@media screen and (max-width: 1400px) {
  .section-masthead__inner_background-left {
    padding-left: 80px;
  }
  .section-masthead__inner_background-left .section-masthead__wrapper-scroll-down {
    left: 40px;
  }
  .section-masthead__inner_background-right {
    padding-left: 80px;
  }
  .section-masthead__inner_background-right .section-masthead__wrapper-scroll-down {
    left: 40px;
  }
}

@media screen and (max-width: 1280px) {
  .section-masthead__inner_background-left {
    padding-left: 60px;
  }
  .section-masthead__inner_background-left .section-masthead__wrapper-scroll-down {
    left: 20px;
  }
  .section-masthead__inner_background-right {
    padding-left: 60px;
  }
  .section-masthead__inner_background-right .section-masthead__wrapper-scroll-down {
    left: 20px;
  }
}

@media screen and (max-width: 991px) {
  .section-masthead__meta .post-preview__meta {
    margin: 0.5em;
  }
  .section-masthead__meta .post-preview__wrapper-meta {
    margin: -0.5em -0.5em 0.5em;
  }
  .section-masthead__meta .post-preview__date {
    display: block;
    width: 100%;
  }
  .section-masthead__curtain {
    opacity: .8;
    mix-blend-mode: multiply;
  }
  .section-masthead__inner_background {
    max-width: 100vw;
    width: 100%;
  }
  .section-masthead__inner_background-left {
    padding-left: 20px;
  }
  .section-masthead__inner_background-left .section-masthead__wrapper-scroll-down {
    left: 20px;
    right: 20px;
    margin: 0 auto;
  }
  .section-masthead__inner_background-right {
    padding-left: 20px;
  }
  .section-masthead__inner_background-right .section-masthead__wrapper-scroll-down {
    left: 20px;
    right: 20px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .section-masthead__inner_background {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section-masthead__wrapper-scroll-down {
    bottom: 20px;
  }
}

/* ======================================================================== */
/* 72. sectionProperties */
/* ======================================================================== */
@media screen and (max-width: 991px) {
  .section-properties__item {
    padding-top: calc(var(--distance-min-small)/3 * 1px);
    padding-bottom: calc(var(--distance-min-small)/3 * 1px);
  }
}

@media screen and (max-width: 991px) and (min-width: 320px) and (max-width: 991px) {
  .section-properties__item {
    padding-top: calc(var(--distance-min-small)/3 * 1px + (var(--distance-max-small)/2 - var(--distance-min-small)/3) * ((100vw - 320px) / 671));
  }
}

@media screen and (max-width: 991px) and (min-width: 991px) {
  .section-properties__item {
    padding-top: calc(var(--distance-max-small)/2 * 1px);
  }
}

@media screen and (max-width: 991px) and (min-width: 320px) {
  .section-properties__item {
    padding-bottom: calc(var(--distance-min-small)/3 * 1px + (var(--distance-max-small)/2 - var(--distance-min-small)/3) * ((100vw - 320px) / 671));
  }
}

@media screen and (max-width: 991px) and (min-width: 991px) {
  .section-properties__item {
    padding-bottom: calc(var(--distance-max-small)/2 * 1px);
  }
}

@media screen and (max-width: 576px) {
  .section-properties__item:first-of-type {
    padding-top: 0;
  }
  .section-properties__item:last-of-type {
    padding-bottom: 0;
  }
}

/* ======================================================================== */
/* 73. sectionServices */
/* ======================================================================== */
.section-services {
  background: none;
}

.section-services__inner {
  position: relative;
}

.section-services__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - ( (100vw - 1140px) / 2) - 20px);
  height: 100%;
}

.section-services__counter {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
}

@media screen and (min-width: 320px) {
  .section-services__counter {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-services__counter {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.section-services__wrapper-item {
  position: relative;
  padding-top: calc(40 * 1px);
  padding-bottom: calc(40 * 1px);
  z-index: 50;
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section-services__wrapper-item {
    padding-top: calc(40 * 1px + (120 - 40) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-services__wrapper-item {
    padding-top: calc(120 * 1px);
  }
}

@media screen and (min-width: 320px) {
  .section-services__wrapper-item {
    padding-bottom: calc(40 * 1px + (120 - 40) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-services__wrapper-item {
    padding-bottom: calc(120 * 1px);
  }
}

.section-services__wrapper-item:not(:last-child) .section-services__border-line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(136, 136, 136, 0.3);
  width: 100%;
  height: 1px;
  display: none;
}

@media screen and (max-width: 991px) {
  .section-services__wrapper-item:not(:last-child) .section-services__border-line {
    display: block;
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    background-color: rgba(136, 136, 136, 0.3);
    width: auto;
    height: 1px;
  }
  .section-services__heading {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
}

@media screen and (max-width: 1140px) {
  .section-services__bg {
    width: 100%;
  }
}

/* ======================================================================== */
/* 74. sectionServicesThemes */
/* ======================================================================== */
.section-services.bg-dark .section-services__heading, .section-services.bg-dark-2 .section-services__heading, .section-services.bg-dark-3 .section-services__heading {
  color: #ffffff;
}

.section-services.bg-dark .section-services__link, .section-services.bg-dark-2 .section-services__link, .section-services.bg-dark-3 .section-services__link {
  color: var(--color-accent-primary-3);
}

.section-services.bg-dark .section-services__link:hover, .section-services.bg-dark-2 .section-services__link:hover, .section-services.bg-dark-3 .section-services__link:hover {
  color: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 75. sectionSliderThemes */
/* ======================================================================== */
.section-slider.bg-dark-3 .slider__counter_current {
  color: var(--color-accent-primary-1);
}

.section-slider.bg-dark-3 .slider__dot .circle {
  stroke: var(--color-accent-primary-1);
}

.section-slider.bg-accent-primary-1 .slider__dot {
  border-color: rgba(204, 204, 204, 0.3);
}

.section-slider.bg-accent-primary-1 .slider__dot .circle {
  stroke: #ffffff;
}

.section-slider.bg-accent-primary-1 .slider__arrow {
  color: #ffffff;
}

.section-slider.bg-accent-primary-1 .slider__arrows-divider, .section-slider.bg-accent-primary-1 .slider__counter-divider {
  background-color: rgba(204, 204, 204, 0.3);
}

.section-slider.bg-accent-primary-1 .slider__counter_current {
  color: #ffffff;
}

.section-slider.bg-accent-primary-1 .slider__counter_total {
  color: #181818;
}

.section-slider.bg-accent-primary-2 .slider__counter_current {
  color: var(--color-accent-primary-1);
}

.section-slider.bg-accent-primary-2 .slider__dot .circle {
  stroke: var(--color-accent-primary-1);
}

.section-slider.bg-accent-primary-3 .slider__dot .circle {
  stroke: #ffffff;
}

.section-slider.bg-accent-primary-3 .slider__arrow {
  color: #ffffff;
}

.section-slider.bg-accent-primary-3 .slider__counter_current {
  color: #ffffff;
}

.section-slider.bg-accent-primary-3 .slider__counter_total {
  color: #181818;
}

.section-slider.bg-accent-primary-4 .slider__dot {
  border-color: rgba(204, 204, 204, 0.3);
}

.section-slider.bg-accent-primary-4 .slider__dot .circle {
  stroke: #ffffff;
}

.section-slider.bg-accent-primary-4 .slider__arrow {
  color: #ffffff;
}

.section-slider.bg-accent-primary-4 .slider__arrows-divider, .section-slider.bg-accent-primary-4 .slider__counter-divider {
  background-color: rgba(204, 204, 204, 0.3);
}

.section-slider.bg-accent-primary-4 .slider__counter_current {
  color: #ffffff;
}

.section-slider.bg-accent-primary-4 .slider__counter_total {
  color: #181818;
}

.section-slider.bg-accent-secondary-1 .slider__dot {
  border-color: rgba(204, 204, 204, 0.3);
}

.section-slider.bg-accent-secondary-1 .slider__dot .circle {
  stroke: #ffffff;
}

.section-slider.bg-accent-secondary-1 .slider__arrow {
  color: #ffffff;
}

.section-slider.bg-accent-secondary-1 .slider__arrows-divider, .section-slider.bg-accent-secondary-1 .slider__counter-divider {
  background-color: rgba(204, 204, 204, 0.3);
}

.section-slider.bg-accent-secondary-1 .slider__counter_current {
  color: #ffffff;
}

.section-slider.bg-accent-secondary-1 .slider__counter_total {
  color: #181818;
}

.section-slider.bg-accent-secondary-3 .slider__counter_current {
  color: var(--color-accent-primary-1);
}

.section-slider.bg-accent-secondary-3 .slider__dot .circle {
  stroke: var(--color-accent-primary-1);
}

.section-slider.bg-accent-secondary-4 .slider__counter_current {
  color: var(--color-accent-primary-1);
}

.section-slider.bg-accent-secondary-4 .slider__dot .circle {
  stroke: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 76. sectionVideo */
/* ======================================================================== */
.section-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-video__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  z-index: 100;
}

.section-video__link:hover {
  border-color: var(--color-accent-primary-1);
  color: var(--color-accent-primary-1);
}

.section-video__link:focus {
  opacity: 1;
}

.section-video__icon {
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  border: 2px solid rgba(136, 136, 136, 0.3);
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@media screen and (min-width: 320px) {
  .section-video__icon {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-video__icon {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.section-video__icon:hover {
  color: var(--color-accent-primary-1);
}

.section-video__icon svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  stroke-width: 1px;
  z-index: 50;
}

.section-video__icon svg .circle {
  stroke: var(--color-accent-primary-1);
}

.section-video__overlay {
  z-index: 0;
}

@media screen and (max-width: 767px) {
  .section-video__icon {
    width: 120px;
    height: 120px;
  }
  .section-video__icon svg {
    width: 120px;
    height: 120px;
  }
}

/* ======================================================================== */
/* 77. select */
/* ======================================================================== */
select {
  display: block;
  border: 1px solid rgba(136, 136, 136, 0.3);
  padding: 10px 35px 10px 15px;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../img/general/select.png");
  background-position: right 15px bottom 50%;
  background-repeat: no-repeat;
  background-size: 6px 4px;
}

/* ======================================================================== */
/* 78. sectionTeam */
/* ======================================================================== */
.section-team {
  margin-top: calc(-1 * (25 * 1px));
  margin-bottom: calc(-1 * (25 * 1px));
}

@media screen and (min-width: 320px) {
  .section-team {
    margin-top: calc(-1 * (25 * 1px + (30 - 25) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-team {
    margin-top: calc(-1 * (30 * 1px));
  }
}

@media screen and (min-width: 320px) {
  .section-team {
    margin-bottom: calc(-1 * (25 * 1px + (30 - 25) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .section-team {
    margin-bottom: calc(-1 * (30 * 1px));
  }
}

.section-team__wrapper-item {
  padding-top: calc(25 * 1px);
  padding-bottom: calc(25 * 1px);
}

@media screen and (min-width: 320px) and (max-width: 1920px) {
  .section-team__wrapper-item {
    padding-top: calc(25 * 1px + (50 - 25) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-team__wrapper-item {
    padding-top: calc(50 * 1px);
  }
}

@media screen and (min-width: 320px) {
  .section-team__wrapper-item {
    padding-bottom: calc(25 * 1px + (50 - 25) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .section-team__wrapper-item {
    padding-bottom: calc(50 * 1px);
  }
}

/* ======================================================================== */
/* 79. sectionTeamThemes */
/* ======================================================================== */
.section-team.bg-dark .figure-member__heading, .section-team.bg-dark-2 .figure-member__heading, .section-team.bg-dark-3 .figure-member__heading {
  color: #ffffff;
}

.section-team.bg-dark .social__item a, .section-team.bg-dark-2 .social__item a, .section-team.bg-dark-3 .social__item a {
  color: #ffffff;
}

.section-team.bg-dark .social__item a:hover, .section-team.bg-dark-2 .social__item a:hover, .section-team.bg-dark-3 .social__item a:hover {
  color: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 80. sectionTestimonialsThemes */
/* ======================================================================== */
.section-testimonials.bg-dark .slider-testimonials__text, .section-testimonials.bg-dark-2 .slider-testimonials__text, .section-testimonials.bg-dark-3 .slider-testimonials__text {
  color: #ffffff;
}

/* ======================================================================== */
/* 81. sidebar */
/* ======================================================================== */
.sidebar_no-margin-last-widget .widget:last-child {
  margin-bottom: 0;
}

.sidebar .widget {
  margin-bottom: 50px;
}

.sidebar .search-form {
  margin-top: 0;
}

@media only screen and (max-width: 991px) {
  .sidebar {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar {
    margin-top: 60px;
  }
}

/* ======================================================================== */
/* 82. slider */
/* ======================================================================== */
.slider:not(.container) {
  max-width: 100%;
}

.slider_offset-top {
  margin-top: -200px;
  padding-bottom: 200px;
}

.slider__bg {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider__images-slide-inner {
  transition: transform 2.4s ease;
  transform: scale(1.05);
  width: 100%;
  height: 100%;
}

.slider__images-slide {
  overflow: hidden;
}

.slider__images-slide.swiper-slide-active .slider__images-slide-inner {
  transform: scale(1);
}

.slider__images-slide .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-prev):not(.swiper-slide-next) .slider__images-slide-inner {
  display: none !important;
}

.slider__counter {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  height: 24px;
}

@media screen and (min-width: 320px) {
  .slider__counter {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .slider__counter {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.slider__counter_current {
  color: var(--color-accent-primary-3);
}

.slider__counter_total {
  color: #888888;
}

.slider__link {
  display: block;
}

.slider__progress {
  display: inline-flex;
  align-items: center;
}

.slider__progressbar {
  position: relative;
  width: 180px;
  height: 1px;
  background: #888888;
}

.slider__progressbar-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #181818;
  transform-origin: left center;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider__dot {
  position: relative;
  width: 14px;
  height: 14px;
  margin: 0 8px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  border: 2px solid rgba(136, 136, 136, 0.3);
  border-radius: 100%;
  vertical-align: middle;
  z-index: 50;
}

.slider__dot svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  stroke-width: 42px;
  z-index: 50;
}

.slider__dot svg .circle {
  stroke: var(--color-accent-primary-1);
}

.slider__dots {
  display: inline-flex;
  height: 24px;
}

.slider__arrows {
  display: inline-flex;
  align-items: center;
}

.slider__arrows-divider, .slider__counter-divider {
  width: 1px;
  height: 18px;
  background-color: rgba(136, 136, 136, 0.3);
  transform: rotate(45deg);
  margin-left: 40px;
  margin-right: 40px;
}

.slider__arrow {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  outline: none;
  width: 24px;
  height: 24px;
  color: #888888;
}

.slider__arrow i {
  font-size: 24px;
}

.slider__arrow.swiper-button-disabled {
  opacity: 0.3;
  color: #888888;
}

.slider__arrow:hover {
  color: var(--color-accent-primary-3);
}

.slider__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 80px;
  padding-left: 0;
  padding-right: 0;
  z-index: 50;
}

.slide__arrow_prev_centered {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 120px;
  transform: translateY(-50%);
  z-index: 50;
}

.slide__arrow_next_centered {
  position: absolute;
  top: 50%;
  right: 0;
  padding-right: 120px;
  transform: translateY(-50%);
  z-index: 50;
}

.slider__footer_half {
  width: 50%;
}

.slider__footer_left {
  margin-right: auto;
}

.slider__footer_right {
  margin-left: auto;
}

.swiper-lazy {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease;
}

.swiper-lazy-loaded {
  opacity: 1;
  visibility: visible;
}

.slider__footer-relative {
  padding-top: 30px;
}

.slider__overlay {
  z-index: 1;
}

.slider__overlay_solid {
  opacity: 1;
  mix-blend-mode: normal;
}

.slider__progress {
  vertical-align: top;
}

@media screen and (max-width: 1400px) {
  .slider__footer {
    padding-bottom: 60px;
  }
  .slide__arrow_prev_centered {
    padding-left: 80px;
  }
  .slide__arrow_next_centered {
    padding-right: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .slider__footer {
    padding-bottom: 40px;
  }
  .slide__arrow_prev_centered {
    padding-left: 50px;
  }
  .slide__arrow_next_centered {
    padding-right: 50px;
  }
}

@media screen and (max-height: 800px) and (min-width: 991px) {
  .slider__footer {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 991px) {
  .slider__footer {
    padding-bottom: 20px;
  }
  .slider__footer_halfscreen {
    padding-top: 20px;
    padding-bottom: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
  .slide__arrow_prev_centered {
    padding-left: 30px;
  }
  .slide__arrow_next_centered {
    padding-right: 30px;
  }
  .slider-halfscreen__container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .slider__footer-relative {
    padding-top: 20px;
  }
  .slider__footer-col {
    padding-top: 10px;
  }
  .slider__footer-col.text-left, .slider__footer-col.text-center, .slider__footer-col.text-right {
    text-align: center !important;
  }
  .slider__arrows-divider, .slider__counter-divider {
    margin-left: 20px;
    margin-right: 20px;
  }
  .slider__counter {
    height: 18px;
  }
  .slider__arrow {
    width: 18px;
    height: 18px;
  }
  .slider__arrow i {
    font-size: 18px;
  }
  .slider__progress, .slider__arrows, .slider__dots {
    vertical-align: top;
  }
  .slider__dots {
    height: 18px;
  }
}

/* ======================================================================== */
/* 83. sliderHalfscreen */
/* ======================================================================== */
.slider-halfscreen {
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 160px;
}

.slider-halfscreen_fullheight {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}

.slider-halfscreen__header p {
  max-width: 600px;
}

.slider-halfscreen__wrapper-button {
  margin-top: calc(1 * (30 * 1px));
}

@media screen and (min-width: 320px) {
  .slider-halfscreen__wrapper-button {
    margin-top: calc(1 * (30 * 1px + (50 - 30) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .slider-halfscreen__wrapper-button {
    margin-top: calc(1 * (50 * 1px));
  }
}

.slider-halfscreen__arrows_centered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 50;
}

.slider-halfscreen__content_padding {
  padding: 120px;
}

@media screen and (max-width: 1400px) {
  .slider-halfscreen:not(.slider-halfscreen_fullheight) {
    padding-top: 120px;
    padding-bottom: 130px;
  }
  .slider-halfscreen__content_padding {
    padding: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .slider-halfscreen:not(.slider-halfscreen_fullheight) {
    padding-top: 100px;
    padding-bottom: 110px;
  }
  .slider-halfscreen__content_padding {
    padding: 60px;
  }
}

@media screen and (max-height: 800px) and (min-width: 991px) {
  .slider-halfscreen:not(.slider-halfscreen_fullheight) {
    padding-top: 100px;
    padding-bottom: 110px;
  }
}

@media screen and (max-width: 991px) {
  .slider-halfscreen, .slider-halfscreen:not(.slider-halfscreen_fullheight) {
    padding-top: calc(var(--distance-min-large) * 1px);
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    max-height: unset;
    height: auto !important;
  }
}

@media screen and (max-width: 991px) and (min-width: 320px) and (max-width: 1920px) {
  .slider-halfscreen, .slider-halfscreen:not(.slider-halfscreen_fullheight) {
    padding-top: calc(var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (max-width: 991px) and (min-width: 1920px) {
  .slider-halfscreen, .slider-halfscreen:not(.slider-halfscreen_fullheight) {
    padding-top: calc(var(--distance-max-large) * 1px);
  }
}

@media screen and (max-width: 991px) {
  .slider-halfscreen__images {
    height: 33vh !important;
  }
  .slider-halfscreen__content {
    margin-bottom: 40px;
    margin-bottom: calc(1 * (40 * 1px));
    padding: 0 20px;
  }
}

@media screen and (max-width: 991px) and (min-width: 320px) {
  .slider-halfscreen__content {
    margin-bottom: calc(1 * (40 * 1px + (50 - 40) * ((100vw - 320px) / 671)));
  }
}

@media screen and (max-width: 991px) and (min-width: 991px) {
  .slider-halfscreen__content {
    margin-bottom: calc(1 * (50 * 1px));
  }
}

@media screen and (max-width: 991px) {
  .slider__footer_half {
    width: 100%;
  }
}

/* ======================================================================== */
/* 84. sliderFullscreen */
/* ======================================================================== */
.slider-fullscreen {
  position: relative;
  height: calc(var(--fix-bar-vh, 1vh) * 100);
}

.slider-fullscreen__header h1 {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.slider-fullscreen__images {
  position: relative;
  width: 100%;
  height: 100%;
}

.slider-fullscreen__images-slide {
  will-change: transform;
}

.slider-fullscreen__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 80px;
  z-index: 60;
}

.slider-fullscreen__content_centered {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  padding-bottom: 0;
}

.slider-fullscreen__footer {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 50;
}

.slider-fullscreen__footer-inner {
  position: relative;
  height: 100px;
}

.slider-fullscreen__footer-divider {
  position: absolute;
  top: 50%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 1px;
  transform: translateY(-50%);
}

.slider-fullscreen__arrows {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.slider-fullscreen__progress {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 50px;
}

.slider-fullscreen__counter-divider {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
  transform: none !important;
  width: 50px !important;
  height: 1px !important;
  transform: none !important;
}

.slider-fullscreen__wrapper-button {
  margin-top: calc(1 * (20 * 1px));
}

@media screen and (min-width: 320px) {
  .slider-fullscreen__wrapper-button {
    margin-top: calc(1 * (20 * 1px + (40 - 20) * ((100vw - 320px) / 1600)));
  }
}

@media screen and (min-width: 1920px) {
  .slider-fullscreen__wrapper-button {
    margin-top: calc(1 * (40 * 1px));
  }
}

@media screen and (max-width: 1400px) {
  .slider-fullscreen__content {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 1280px) {
  .slider-fullscreen__content {
    padding-bottom: 25px;
  }
}

/* ======================================================================== */
/* 85. sliderImages */
/* ======================================================================== */
.slider-images {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.slider-images_footer-bottom .slider-images__footer {
  padding-top: 30px;
  order: 1;
}

.slider-images_footer-top .slider-images__footer {
  padding-bottom: 30px;
  order: -1;
}

.slider-images__caption {
  font-weight: bold;
  width: 100%;
}

.slider-images__slider-captions {
  height: 28px;
}

@media screen and (max-width: 991px) {
  .slider-images_footer-bottom .slider-images__footer {
    padding-top: 10px;
  }
  .slider-images_footer-top .slider-images__footer {
    padding-bottom: 10px;
  }
}

/* ======================================================================== */
/* 86. sliderLetters */
/* ======================================================================== */
.slider-letters {
  width: 100%;
  height: 100%;
}

.vector-letter:not(:first-child) {
  visibility: hidden;
}

svg.vector-letters {
  height: 130px;
}

/* ======================================================================== */
/* 87. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials__text {
  font-weight: 300;
  font-style: italic;
  margin-top: 0;
}

.slider-testimonials__author {
  display: flex;
  align-items: center;
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  color: var(--color-accent-primary-3);
  margin-top: 3em;
}

@media screen and (min-width: 320px) {
  .slider-testimonials__author {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .slider-testimonials__author {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.slider-testimonials__author-line {
  width: 60px;
  height: 1px;
  background-color: var(--color-accent-primary-1);
  margin-right: 2em;
}

.slider-testimonials__footer {
  margin-top: 30px;
}

/* ======================================================================== */
/* 88. social */
/* ======================================================================== */
.social {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: -16px;
  margin-right: -16px;
}

.social__item {
  display: inline-block;
  margin: 0 8px;
}

.social__item a {
  color: #888888;
  font-size: 13px;
  padding: 8px;
}

.social__item a:hover {
  color: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 89. sliderProjects */
/* ======================================================================== */
.slider-projects__footer {
  padding-top: 30px;
}

/* ======================================================================== */
/* 90. splitText */
/* ======================================================================== */
.elementor-editor-active [data-os-animation] .split-text {
  opacity: 1;
  visibility: visible;
}

.clone .split-text__line {
  position: static !important;
}

.clone .split-text__word {
  position: static !important;
}

/* ======================================================================== */
/* 91. transitionCurtain */
/* ======================================================================== */
.transition-curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 400;
  display: none;
  transition: background-color 0.6s ease-in-out;
  will-change: background-color;
}

/* ======================================================================== */
/* 92. tags */
/* ======================================================================== */
.tagcloud ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tagcloud ul li {
  display: inline-block !important;
  margin-bottom: 0 !important;
}

.tagcloud ul li a {
  display: inline-block;
  font-size: 13px;
  color: var(--color-accent-secondary-2) !important;
  background-color: #f7f5f0;
  padding: 7px 15px;
  margin-bottom: 6px;
  margin-right: 4px;
  border-radius: 100px;
}

.tagcloud ul li a:hover {
  background-color: var(--color-accent-secondary-2);
  color: #ffffff !important;
}

/* ======================================================================== */
/* 93. utilities */
/* ======================================================================== */
.text-left {
  text-align: left !important;
}

.text-left .section__headline {
  margin-right: auto;
}

.text-center {
  text-align: center !important;
}

.text-center .section__headline {
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right !important;
}

.text-right .section__headline {
  margin-left: auto;
}

.row-80 {
  margin-left: -40px;
  margin-right: -40px;
}

.overflow {
  position: relative;
  overflow: hidden;
}

.overflow__curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.overflow__content {
  width: 100%;
  height: 100%;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.of-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.of-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.split-line {
  overflow: hidden;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

.grayscale {
  filter: grayscale(70%);
}

.h-100 {
  height: 100%;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.backgroundblendmode .blend-difference {
  mix-blend-mode: difference;
}

.backgroundblendmode .blend-difference .header__burger-line {
  background-color: #ffffff;
}

.backgroundblendmode .blend-difference .logo {
  color: #ffffff;
}

.backgroundblendmode .blend-difference .menu > li > a {
  color: #ffffff;
}

.border-radius, .button, .gmap__container, .menu .sub-menu {
  border-radius: 4px;
}

.bg-ornament {
  background-image: url("../img/general/bg-ornament.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-dots {
  width: 256px;
  height: 400px;
  background-image: url("../img/general/bg-dots.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.mw-960 {
  max-width: 960px;
}

/* ======================================================================== */
/* 94. typography */
/* ======================================================================== */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-secondary);
  color: var(--color-accent-secondary-2);
}

h1, h2, h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

h4, h5, h6 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

h1 {
  font-size: calc(var(--h1-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h1-line-height);
  margin-top: 0;
}

@media screen and (min-width: 320px) {
  h1 {
    font-size: calc(var(--h1-min-font-size) * 1px + (var(--h1-max-font-size) - var(--h1-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  h1 {
    font-size: calc(var(--h1-max-font-size) * 1px);
  }
}

h2 {
  font-size: calc(var(--h2-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h2-line-height);
}

@media screen and (min-width: 320px) {
  h2 {
    font-size: calc(var(--h2-min-font-size) * 1px + (var(--h2-max-font-size) - var(--h2-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  h2 {
    font-size: calc(var(--h2-max-font-size) * 1px);
  }
}

h3 {
  font-size: calc(var(--h3-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h3-line-height);
}

@media screen and (min-width: 320px) {
  h3 {
    font-size: calc(var(--h3-min-font-size) * 1px + (var(--h3-max-font-size) - var(--h3-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  h3 {
    font-size: calc(var(--h3-max-font-size) * 1px);
  }
}

h4 {
  font-size: calc(var(--h4-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h4-line-height);
}

@media screen and (min-width: 320px) {
  h4 {
    font-size: calc(var(--h4-min-font-size) * 1px + (var(--h4-max-font-size) - var(--h4-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  h4 {
    font-size: calc(var(--h4-max-font-size) * 1px);
  }
}

h5 {
  font-size: calc(var(--h5-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h5-line-height);
}

@media screen and (min-width: 320px) {
  h5 {
    font-size: calc(var(--h5-min-font-size) * 1px + (var(--h5-max-font-size) - var(--h5-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  h5 {
    font-size: calc(var(--h5-max-font-size) * 1px);
  }
}

h6 {
  font-size: calc(var(--h6-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h6-line-height);
}

@media screen and (min-width: 320px) {
  h6 {
    font-size: calc(var(--h6-min-font-size) * 1px + (var(--h6-max-font-size) - var(--h6-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  h6 {
    font-size: calc(var(--h6-max-font-size) * 1px);
  }
}

.h1 {
  font-size: calc(var(--h1-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h1-line-height);
  margin-top: 0;
}

@media screen and (min-width: 320px) {
  .h1 {
    font-size: calc(var(--h1-min-font-size) * 1px + (var(--h1-max-font-size) - var(--h1-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .h1 {
    font-size: calc(var(--h1-max-font-size) * 1px);
  }
}

.h2 {
  font-size: calc(var(--h2-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h2-line-height);
}

@media screen and (min-width: 320px) {
  .h2 {
    font-size: calc(var(--h2-min-font-size) * 1px + (var(--h2-max-font-size) - var(--h2-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .h2 {
    font-size: calc(var(--h2-max-font-size) * 1px);
  }
}

.h3 {
  font-size: calc(var(--h3-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h3-line-height);
}

@media screen and (min-width: 320px) {
  .h3 {
    font-size: calc(var(--h3-min-font-size) * 1px + (var(--h3-max-font-size) - var(--h3-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .h3 {
    font-size: calc(var(--h3-max-font-size) * 1px);
  }
}

.h4 {
  font-size: calc(var(--h4-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h4-line-height);
}

@media screen and (min-width: 320px) {
  .h4 {
    font-size: calc(var(--h4-min-font-size) * 1px + (var(--h4-max-font-size) - var(--h4-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .h4 {
    font-size: calc(var(--h4-max-font-size) * 1px);
  }
}

.h5 {
  font-size: calc(var(--h5-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h5-line-height);
}

@media screen and (min-width: 320px) {
  .h5 {
    font-size: calc(var(--h5-min-font-size) * 1px + (var(--h5-max-font-size) - var(--h5-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .h5 {
    font-size: calc(var(--h5-max-font-size) * 1px);
  }
}

.h6 {
  font-size: calc(var(--h6-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--h6-line-height);
}

@media screen and (min-width: 320px) {
  .h6 {
    font-size: calc(var(--h6-min-font-size) * 1px + (var(--h6-max-font-size) - var(--h6-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .h6 {
    font-size: calc(var(--h6-max-font-size) * 1px);
  }
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  line-height: var(--paragraph-line-height);
}

@media screen and (min-width: 320px) {
  p {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  p {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

strong, b {
  font-weight: bold;
}

small {
  display: block;
  font-size: 13px;
  line-height: 1.5;
  color: #888888;
}

.subheading {
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  font-size: calc(var(--subheading-min-font-size) * 1px);
  line-height: var(--subheading-line-height);
  margin-bottom: 1em;
}

@media screen and (min-width: 320px) {
  .subheading {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .subheading {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

@media screen and (min-width: 320px) {
  .subheading {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .subheading {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.heading-light {
  font-weight: normal;
}

blockquote {
  display: block;
  background-color: #f7f5f0;
  color: var(--color-accent-secondary-2);
  font-family: var(--font-secondary);
  font-size: calc(var(--blockquote-min-font-size) * 1px);
  line-height: var(--blockquote-line-height);
  font-style: italic;
  padding: 3em;
  margin-top: 2em;
  margin-bottom: 2em;
}

@media screen and (min-width: 320px) {
  blockquote {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  blockquote {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}

blockquote p {
  margin-top: 0;
  font-size: calc(var(--blockquote-min-font-size) * 1px);
}

@media screen and (min-width: 320px) {
  blockquote p {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  blockquote p {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}

cite {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-primary);
  font-style: normal;
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  margin-top: 1em;
  color: var(--color-accent-primary-1);
}

@media screen and (min-width: 320px) {
  cite {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  cite {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

cite:before {
  content: '';
  display: block;
  width: 50px;
  height: 1px;
  background-color: var(--color-accent-primary-1);
  margin-right: 1em;
}

.text-xl {
  font-size: calc(var(--xl-min-font-size) * 1px);
  line-height: var(--xl-line-height);
}

@media screen and (min-width: 320px) {
  .text-xl {
    font-size: calc(var(--xl-min-font-size) * 1px + (var(--xl-max-font-size) - var(--xl-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .text-xl {
    font-size: calc(var(--xl-max-font-size) * 1px);
  }
}

.text-xxl {
  font-size: calc(var(--xxl-min-font-size) * 1px);
  line-height: var(--xxl-line-height);
}

@media screen and (min-width: 320px) {
  .text-xxl {
    font-size: calc(var(--xxl-min-font-size) * 1px + (var(--xxl-max-font-size) - var(--xxl-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .text-xxl {
    font-size: calc(var(--xxl-max-font-size) * 1px);
  }
}

@media only screen and (max-width: 991px) {
  h1, .h1 {
    margin-bottom: 1em;
  }
  h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  blockquote {
    padding: 2em;
  }
}

/* ======================================================================== */
/* 95. typographyAccentSecondaryAdjustments */
/* ======================================================================== */
.bg-accent-secondary-1 h1, .bg-accent-secondary-1 h2, .bg-accent-secondary-1 h3, .bg-accent-secondary-1 h4, .bg-accent-secondary-1 h5, .bg-accent-secondary-1 h6, .bg-accent-secondary-1 .section-nav-projects__heading {
  color: var(--color-accent-primary-2);
}

.bg-accent-secondary-1 .section__headline {
  background-color: var(--color-accent-primary-2);
}

.bg-accent-secondary-2 h1, .bg-accent-secondary-2 h2, .bg-accent-secondary-2 h3, .bg-accent-secondary-2 h4, .bg-accent-secondary-2 h5, .bg-accent-secondary-2 h6, .bg-accent-secondary-2 .section-nav-projects__heading {
  color: var(--color-accent-primary-3);
}

.bg-accent-secondary-3 h1, .bg-accent-secondary-3 h2, .bg-accent-secondary-3 h3, .bg-accent-secondary-3 h4, .bg-accent-secondary-3 h5, .bg-accent-secondary-3 h6, .bg-accent-secondary-3 .section-nav-projects__heading {
  color: var(--color-accent-primary-3);
}

.bg-accent-secondary-4 h1, .bg-accent-secondary-4 h2, .bg-accent-secondary-4 h3, .bg-accent-secondary-4 h4, .bg-accent-secondary-4 h5, .bg-accent-secondary-4 h6, .bg-accent-secondary-4 .section-nav-projects__heading {
  color: var(--color-accent-primary-3);
}

/* ======================================================================== */
/* 96. typographyDarkAdjustments */
/* ======================================================================== */
.bg-black h1, .bg-black h2, .bg-black h3, .bg-black h4, .bg-black h5, .bg-black h6, .bg-black .section-nav-projects__heading {
  color: var(--color-accent-primary-3);
}

.bg-dark h1, .bg-dark h2, .bg-dark h3, .bg-dark h4, .bg-dark h5, .bg-dark h6, .bg-dark .section-nav-projects__heading {
  color: var(--color-accent-primary-3);
}

.bg-dark-2 h1, .bg-dark-2 h2, .bg-dark-2 h3, .bg-dark-2 h4, .bg-dark-2 h5, .bg-dark-2 h6, .bg-dark-2 .section-nav-projects__heading {
  color: var(--color-accent-primary-3);
}

.bg-dark-3 h1, .bg-dark-3 h2, .bg-dark-3 h3, .bg-dark-3 h4, .bg-dark-3 h5, .bg-dark-3 h6, .bg-dark-3 .section-nav-projects__heading {
  color: var(--color-accent-primary-3);
}

/* ======================================================================== */
/* 97. widget */
/* ======================================================================== */
.widget p:last-of-type {
  margin-bottom: 0;
}

.widget select {
  width: 100%;
  max-width: 100%;
}

.widget ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.widget ul li {
  display: block;
  margin-bottom: 20px;
}

.widget ul li:last-child {
  margin-bottom: 0;
}

.widget ul ul {
  width: 100%;
  padding-left: 1em;
  margin-top: 1em;
}

.widget ul .sub-menu {
  padding-left: 1em;
  margin-top: 1em;
}

.widget ul li a {
  color: #888888;
}

.widget ul li a:hover {
  color: var(--color-accent-primary-1);
}

.widgettitle {
  display: block;
  font-family: var(--font-primary);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  font-weight: bold;
  line-height: var(--subheading-line-height);
  letter-spacing: 2px;
  color: #888888;
  text-transform: uppercase;
  color: var(--color-accent-secondary-2);
  margin-bottom: 1.5em;
}

@media screen and (min-width: 320px) {
  .widgettitle {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * ((100vw - 320px) / 1600));
  }
}

@media screen and (min-width: 1920px) {
  .widgettitle {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.widgettitle:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--color-accent-secondary-2);
  margin-bottom: 0.5em;
}

/* ======================================================================== */
/* 98. widgetArchive */
/* ======================================================================== */
.widget_archive ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.widget_archive ul li span {
  color: #888888;
  font-size: 13px;
  line-height: 1;
}

/* ======================================================================== */
/* 99. widgetCalendar */
/* ======================================================================== */
#wp-calendar {
  width: 100%;
  text-align: center;
}

#wp-calendar caption {
  caption-side: top;
  width: 100%;
  text-align: center;
  padding-top: 0;
  padding-bottom: 10px;
}

#wp-calendar th {
  font-weight: 600;
  padding: 5px;
  text-align: center;
}

#wp-calendar td {
  padding: 5px;
  color: #888888;
}

#wp-calendar td#next {
  text-align: right;
}

#wp-calendar td#prev {
  text-align: left;
}

#wp-calendar tbody a:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background-color: #f7f5f0;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

#wp-calendar tbody a:hover {
  color: #ffffff;
}

#wp-calendar tbody a:hover:before {
  background: var(--color-accent-secondary-2);
}

#wp-calendar tr {
  border-bottom: none;
}

#wp-calendar a {
  display: inline-block;
  position: relative;
  color: var(--color-accent-secondary-2);
  z-index: 50;
}

#wp-calendar a:hover {
  color: var(--color-accent-primary-1);
}

/* ======================================================================== */
/* 100. widgetCassioLogo */
/* ======================================================================== */
/* ======================================================================== */
/* 101. widgetCassioMenuInline */
/* ======================================================================== */
.widget_cassio_menu_inline ul li {
  display: inline-block;
  margin-bottom: 0;
}

.widget_cassio_menu_inline ul li a {
  padding: 4px;
}

.widget_cassio_menu_inline ul li a:before {
  display: none;
}

@media screen and (max-width: 991px) {
  .widget_cassio_menu_inline .menu {
    text-align: center;
  }
}

/* ======================================================================== */
/* 102. widgetCassioSocial */
/* ======================================================================== */
.widget_cassio_social ul {
  text-align: inherit;
}

.widget_cassio_social ul li {
  display: inline-block;
  margin-bottom: 0;
}

/* ======================================================================== */
/* 103. widgetCategories */
/* ======================================================================== */
.widget_categories ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.widget_categories ul li span {
  color: #888888;
  font-size: 13px;
  line-height: 1;
}

/* ======================================================================== */
/* 104. widgetLogo */
/* ======================================================================== */
.widgetcassio_logo, .widget_cassio_logo {
  max-width: 360px;
}

.widgetcassio_logo .logo, .widget_cassio_logo .logo {
  margin-bottom: 2em;
}

@media screen and (max-width: 991px) {
  .widgetcassio_logo, .widget_cassio_logo {
    max-width: 100%;
  }
  .widgetcassio_logo .logo, .widget_cassio_logo .logo {
    margin-bottom: 1em;
  }
}

/* ======================================================================== */
/* 105. widgetNavMenu */
/* ======================================================================== */
.widget_nav_menu ul.menu > li {
  margin-bottom: 1em !important;
  white-space: normal;
  word-break: break-word;
}

.widget_nav_menu ul.menu > li a {
  display: inline-block;
  padding: 0;
  font-size: 16px;
}

.widget_nav_menu ul.menu > li a:after, .widget_nav_menu ul.menu > li a:before {
  display: none;
}

.widget_nav_menu ul.menu > li.menu-item-has-children {
  margin-bottom: 1.5em !important;
}

.widget_nav_menu ul.menu > li.menu-item-has-children a:after {
  display: none;
}

.widget_nav_menu ul.sub-menu {
  background-color: transparent;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
  border-bottom: none;
  transform: none;
  margin-top: 1em;
}

.widget_nav_menu ul.sub-menu > li {
  border-left: none;
}

.widget_nav_menu ul.sub-menu > li > a {
  padding: 3px 0;
  border-left: none;
}

.widget_nav_menu ul.sub-menu > li > a:hover {
  background-color: unset;
}

/* ======================================================================== */
/* 106. widgetPolylang */
/* ======================================================================== */
.widget_polylang {
  display: inline-block;
  font-size: 14px;
}

.widget_polylang select {
  width: auto;
  display: inline-block;
  padding: 5px 25px 5px 15px;
}

.widget_polylang ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.widget_polylang ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
}

/* ======================================================================== */
/* 107. widgetRSS */
/* ======================================================================== */
.widget_rss .rsswidget {
  font-family: var(--font-secondary);
  font-weight: bold;
  color: var(--color-accent-secondary-2);
}

.widget_rss .rss-date {
  display: block;
  width: 100%;
  font-size: 13px;
  margin-top: 0.5em;
  margin-bottom: 1em;
  color: #888888;
}

.widget_rss .rssSummary {
  margin-top: 1em;
  margin-bottom: 1em;
}

.widget_rss ul > li {
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
  padding-bottom: 1em;
}

.widget_rss ul > li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

/* ======================================================================== */
/* 108. widgetRecentComments */
/* ======================================================================== */
.widget_recent_comments ul li {
  padding: 20px 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
}

.widget_recent_comments ul li:first-child {
  padding-top: 0;
}

.widget_recent_comments ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.widget_recent_comments ul li a {
  color: var(--color-accent-secondary-2);
  font-family: var(--font-secondary);
  font-weight: bold;
}

.widget_recent_comments ul li a:hover {
  color: var(--color-accent-primary-1);
}

.widget_recent_comments .comment-author-link a {
  color: #888888;
  font-family: var(--font-primary);
  font-weight: normal;
}

/* ======================================================================== */
/* 109. widgetRecentEntries */
/* ======================================================================== */
.widget_recent_entries .post-date {
  display: block;
  width: 100%;
  font-size: 13px;
  margin-top: 0.25em;
  color: #888888;
}

.widget_recent_entries ul li {
  padding: 20px 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
}

.widget_recent_entries ul li a {
  font-family: var(--font-secondary);
  font-weight: bold;
  color: var(--color-accent-secondary-2) !important;
}

.widget_recent_entries ul li a:hover {
  color: var(--color-accent-primary-1) !important;
}

.widget_recent_entries ul li:first-child {
  padding-top: 0;
}

.widget_recent_entries ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

/* ======================================================================== */
/* 110. widgetWPML */
/* ======================================================================== */
.widget_icl_lang_sel_widget {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-size: 14px;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown {
  width: auto;
  border: 1px solid rgba(136, 136, 136, 0.3);
  border-radius: 2px;
  padding: 2px 5px;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a {
  border: none;
  background: transparent;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:hover, .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:focus {
  opacity: 1;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:hover, .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown a:focus, .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
  background: transparent;
}

.widget_icl_lang_sel_widget .wpml-ls-sub-menu {
  border-top: none;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal {
  padding: 2px 5px;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal .wpml-ls-item {
  display: inline-block;
  margin-bottom: 0;
}

.lang-switch-no-padding-right .widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal {
  padding-right: 0;
}
