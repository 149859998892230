/* ======================================================================== */
/* 16. figureFeature */
/* ======================================================================== */
.figure-feature
	position: relative
	text-align: center
	display: inline-flex
	flex-direction: column
	align-items: center
	justify-content: center
	width: 100%
	padding-left: 20px
	padding-right: 20px
	@include fluid-padding-top($min_width, $max_width, 40, 90)
	@include fluid-padding-bottom($min_width, $max_width, 40, 90)
.figure-feature__corner
	position: absolute
	width: 30px
	height: 30px
	border-color: $sandstone
.figure-feature__corner_top
	top: 0
	left: 0
	border-top-width: 1px
	border-left-width: 1px
	border-top-style: solid
	border-left-style: solid
.figure-feature__corner_bottom
	border-bottom-width: 1px
	border-right-width: 1px
	border-bottom-style: solid
	border-right-style: solid
	right: 0
	bottom: 0
.figure-feature__icon
	font-size: 48px
	color: $sandstone
	width: 48px
	height: 48px
.figure-feature__header
	@include fluid-margin-top($min_width, $max_width, 16, 24)
	width: 100%
.figure-feature__heading
	margin-top: 0
	margin-bottom: 0
