/* ======================================================================== */
/* 38. link */
/* ======================================================================== */
.link-arrow
	display: inline-flex
	align-items: center
	+small-caps
	color: $sandstone
	font-weight: bold
.link-arrow__label
	display: inline-block
	vertical-align: middle
.link-arrow__icon
	display: inline-block
	font-size: 20px !important
	margin-bottom: 1px
	margin-left: 10px
	vertical-align: middle
