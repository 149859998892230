/* ======================================================================== */
/* 96. typographyDarkAdjustments */
/* ======================================================================== */
.bg-black
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pinkish-grey
////////////////////////////////////////////////
.bg-dark
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pinkish-grey
////////////////////////////////////////////////
.bg-dark-2
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pinkish-grey
////////////////////////////////////////////////
.bg-dark-3
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading
		color: $pinkish-grey
