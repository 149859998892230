/* ======================================================================== */
/* 92. tags */
/* ======================================================================== */
.tagcloud
	ul
		+reset-ul
		li
			display: inline-block !important
			margin-bottom: 0 !important
			a
				display: inline-block
				font-size: 13px
				color: $dusk !important
				background-color: $off-white
				padding: 7px 15px
				margin-bottom: 6px
				margin-right: 4px
				border-radius: 100px
				&:hover
					background-color: $dusk
					color: $white !important
