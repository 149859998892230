/* ======================================================================== */
/* 69. sectionNavProjectsThemes */
/* ======================================================================== */
.section-nav-projects
	&.bg-white
		.section-nav-projects__inner
			color: $dusk
			&:hover
				background-color: $light
		.button-circles__circle svg .circle
			stroke: $dusk
	/////////////////////////////////////////////
	&.bg-off-white
		.section-nav-projects__inner
			color: $pinkish-grey
			&:hover
				background-color: $white
	/////////////////////////////////////////////
	&.bg-light
		.section-nav-projects__inner
			color: $pinkish-grey
			&:hover
				background-color: $off-white
	/////////////////////////////////////////////
	&.bg-black
		.section-nav-projects__inner
			&:hover
				background-color: $dark
	/////////////////////////////////////////////
	&.bg-dark
		.section-nav-projects__inner
			&:hover
				background-color: $dark2
	/////////////////////////////////////////////
	&.bg-dark-2
		.section-nav-projects__inner
			&:hover
				background-color: $dark3
	/////////////////////////////////////////////
	&.bg-dark-3
		.section-nav-projects__inner
			&:hover
				background-color: $dark2
	/////////////////////////////////////////////
	&.bg-accent-primary-1
		.section-nav-projects__inner
			color: $white
			&:hover
				background-color: $dark3
		.button-circles__circle svg .circle
			stroke: $white
		.button-circles__circle
			border-color: $white
	/////////////////////////////////////////////
	&.bg-accent-primary-2
		.section-nav-projects__inner
			color: $pinkish-grey
			&:hover
				background-color: $off-white
	/////////////////////////////////////////////
	&.bg-accent-primary-3
		.section-nav-projects__inner
			color: $sandstone
			&:hover
				background-color: $light
	/////////////////////////////////////////////
	&.bg-accent-primary-4
		.section-nav-projects__inner
			color: $white
			&:hover
				background-color: $dark3
		.button-circles__circle svg .circle
			stroke: $white
		.button-circles__circle
			border-color: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-1
		.section-nav-projects__inner
			color: $white
			&:hover
				background-color: $dusk
		.button-circles__circle svg .circle
			stroke: $white
		.button-circles__circle
			border-color: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-2
		.section-nav-projects__inner
			color: $white
			&:hover
				background-color: $grey-blue
		.button-circles__circle svg .circle
			stroke: $white
		.button-circles__circle
			border-color: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-3
		.section-nav-projects__inner
			color: $light
			&:hover
				background-color: $dusk
		.button-circles__circle svg .circle
			stroke: $light
	/////////////////////////////////////////////
	&.bg-accent-secondary-4
		.section-nav-projects__inner
			color: $sandstone
			&:hover
				background-color: $dark-blue
		.button-circles__circle svg .circle
			stroke: $sandstone
