/* * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 *
 * Cassio – Architect Portfolio AJAX HTML5 Template
 *
 * [Table of Contents]
 *
 * 1. asideLogos
 * 2. backgrounds
 * 3. bootstrapLayout
 * 4. button
 * 5. buttonBordered
 * 6. buttonSolid
 * 7. buttonCircles
 * 8. colors
 * 9. colorsAccentPrimary
 * 10. colorsAccentSecondary
 * 11. comments
 * 12. counter
 * 13. figureAward
 * 14. figureContact
 * 15. figureFeature
 * 16. figureImage
 * 17. figureInfo
 * 18. figureMember
 * 19. figurePortfolio
 * 20. figurePortfolioBig
 * 21. figurePost
 * 22. filter
 * 23. footer
 * 24. form
 * 25. formContact
 * 26. gmap
 * 27. grid
 * 28. gridFluid
 * 29. header
 * 30. headerColors
 * 31. headerOverlayThemes
 * 32. headerSticky
 * 33. headerThemes
 * 34. inputFloat
 * 35. inputSearch
 * 36. lazy
 * 37. link
 * 38. logo
 * 39. menu
 * 40. menuOverlay
 * 41. overlay
 * 42. pagination
 * 43. artParallax
 * 44. post
 * 45. postPreview
 * 46. preloader
 * 47. scroll
 * 48. scrollDown
 * 49. section
 * 50. sectionHeight
 * 51. sectionMarginsPaddings
 * 52. section404
 * 53. sectionAbout
 * 54. sectionAboutThemes
 * 55. sectionAwards
 * 56. sectionAwardsThemes
 * 57. sectionBlog
 * 58. sectionContactsThemes
 * 59. sectionContent
 * 60. sectionCTA
 * 61. sectionCTAThemes
 * 62. sectionFeatures
 * 63. sectionFeaturesThemes
 * 64. sectionFullscreenSlider
 * 65. sectionImage
 * 66. sectionIntro
 * 67. sectionLatestPostsThemes
 * 68. sectionMasthead
 * 69. sectionNavProjects
 * 70. sectionNavProjectsThemes
 * 71. sectionPortfolio
 * 72. sectionProperties
 * 73. sectionServices
 * 74. sectionServicesThemes
 * 75. sectionSliderThemes
 * 76. sectionTeam
 * 77. sectionTeamThemes
 * 78. sectionTestimonialsThemes
 * 79. sectionVideo
 * 80. select
 * 81. sidebar
 * 82. slider
 * 83. sliderFullscreen
 * 84. sliderHalfscreen
 * 85. sliderImages
 * 86. sliderLetters
 * 87. sliderProjects
 * 88. sliderTestimonials
 * 89. social
 * 90. splitText
 * 91. tags
 * 92. transitionCurtain
 * 93. typography
 * 94. typographyAccentSecondaryAdjustments
 * 95. typographyDarkAdjustments
 * 96. utilities
 * 97. widget
 * 98. widgetArchive
 * 99. widgetCalendar
 * 100. widgetCassioLogo
 * 101. widgetCassioMenuInline
 * 102. widgetCassioSocial
 * 103. widgetCategories
 * 104. widgetLogo
 * 105. widgetNavMenu
 * 106. widgetPolylang
 * 107. widgetRecentComments
 * 108. widgetRecentEntries
 * 109. widgetRSS
 * 110. widgetWPML

 * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 */

@mixin fluid-margin-bottom($min-vw, $max-vw, $min-value, $max-value, $multiplier: 1) {
	margin-bottom: calc(#{$multiplier} * (#{$min-value} * 1px));
	@media screen and (min-width: $min-vw) {
		margin-bottom: calc(#{$multiplier} * (#{$min-value} * 1px + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))); }
	@media screen and (min-width: $max-vw) {
		margin-bottom: calc(#{$multiplier} * (#{$max-value} * 1px)); } }

@mixin fluid-margin-top($min-vw, $max-vw, $min-value, $max-value, $multiplier: 1) {
	margin-top: calc(#{$multiplier} * (#{$min-value} * 1px));
	@media screen and (min-width: $min-vw) {
		margin-top: calc(#{$multiplier} * (#{$min-value} * 1px + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))); }
	@media screen and (min-width: $max-vw) {
		margin-top: calc(#{$multiplier} * (#{$max-value} * 1px)); } }

@mixin fluid-margin($min-vw, $max-vw, $min-value, $max-value, $multiplier: 1) {
	margin: calc(#{$multiplier} * (#{$min-value} * 1px));
	@media screen and (min-width: $min-vw) {
		margin: calc(#{$multiplier} * (#{$min-value} * 1px + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))); }
	@media screen and (min-width: $max-vw) {
		margin: calc(#{$multiplier} * (#{$max-value} * 1px)); } }

@mixin fluid-padding-bottom($min-vw, $max-vw, $min-value, $max-value) {
	padding-bottom: calc(#{$min-value} * 1px);
	@media screen and (min-width: $min-vw) {
		padding-bottom: calc(#{$min-value} * 1px + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})); }
	@media screen and (min-width: $max-vw) {
		padding-bottom: calc(#{$max-value} * 1px); } }

@mixin fluid-padding-top($min-vw, $max-vw, $min-value, $max-value) {
	padding-top: calc(#{$min-value} * 1px);
	@media screen and (min-width: $min-vw) and (max-width: $max-vw) {
		padding-top: calc(#{$min-value} * 1px + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})); }
	@media screen and (min-width: $max-vw) {
		padding-top: calc(#{$max-value} * 1px); } }

@mixin fluid-padding($min-vw, $max-vw, $min-value, $max-value) {
	padding: calc(#{$min-value} * 1px);
	@media screen and (min-width: $min-vw) and (max-width: $max-vw) {
		padding: calc(#{$min-value} * 1px + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})); }
	@media screen and (min-width: $max-vw) {
		padding: calc(#{$max-value} * 1px); } }

@mixin fluid-translate-y($min-vw, $max-vw, $min-value, $max-value, $multiplier: 1) {
	transform: translateY(calc(#{$multiplier} * #{$min-value} * 1px ));
	@media only screen and (min-width: $min-vw) and (max-width: $max-vw) {
		transform: translateY(calc(#{$multiplier} * ((#{$min-value} * 0.67 * 1px) + (#{$max-value} - #{$min-value}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})))); }
	@media only screen and (min-width: $max-vw) {
		transform: translateY(calc(#{$multiplier} * #{$max-value} * 1px )); } }

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	font-size: calc(#{$min-font-size} * 1px);
	@media screen and (min-width: $min-vw) {
		font-size: calc(#{$min-font-size} * 1px + (#{$max-font-size} - #{$min-font-size}) * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})); }
	@media screen and (min-width: $max-vw) {
		font-size: calc(#{$max-font-size} * 1px); } }

@function rem($size) {
	$remSize: $size / $em-base;
	@return #{$remSize}rem; }
@mixin trans1 {
	transition: all 0.3s cubic-bezier(0.785, 0.135, 0.150, 0.860); }
@mixin trans2 {
	transition: all 0.6s cubic-bezier(0.785, 0.135, 0.150, 0.860); }
@mixin reset-ul {
	list-style-type: none;
	padding: 0;
	margin: 0; }
@mixin shadow {
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.1); }
@mixin small-caps {
	font-family: $font-primary;
	@include fluid-type($min_width, $max_width, $subheading-min-font-size, $subheading-max-font-size);
	font-weight: bold;
	line-height: $subheading-line-height;
	letter-spacing: 2px;
	color: $brown-grey;
	text-transform: uppercase; }


@function strip-unit($value) {
	@return $value / ($value * 0 + 1); }

@function em($pixels, $context: $em-base) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px; }

	@if (unitless($context)) {
		$context: $context * 1px; }

	@return $pixels / $context * 1em; }

@function rem($pixels, $context: $em-base) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px; }

	@if (unitless($context)) {
		$context: $context * 1px; }

	@return $pixels / $context * 1rem; }

$em-base: 16px;
$font-primary: var(--font-primary);
$font-secondary: var(--font-secondary);
$line-height: 1.75;

//Color palette
$sandstone: var(--color-accent-primary-1);
$pale: var(--color-accent-primary-2);
$pinkish-grey: var(--color-accent-primary-3);
$brownish-grey: var(--color-accent-primary-4);

$grey-blue: var(--color-accent-secondary-1);
$dusk: var(--color-accent-secondary-2);
$dark-blue: var(--color-accent-secondary-3);
$dark-blue-2: var(--color-accent-secondary-4);

$white: #ffffff;
$light: #f8f8f8;
$off-white: #f7f5f0;

$very-light-pink: #cccccc;
$brown-grey: #888888;
$greyish-brown: #444444;
$dark2: #222222;
$dark3: #333333;
$dark: #181818;
$black: #111111;

$borders-dark: rgba(136, 136, 136, .3);
$borders-dark-low: rgba(136, 136, 136, .1);
$borders-light: rgba(204, 204, 204, .3);

$brdr: 4px;

// Responsive Breakpoints
$xxl: 1400px;
$xl: 1280px;
$lg: 1024px;
$md: 991px;
$sm: 767px;
$xs: 576px;

$min_width: 320px;
$max_width: 1920px;

$distance-max-xsmall: var(--distance-max-xsmall);
$distance-max-small: var(--distance-max-small);
$distance-max-normal: var(--distance-max-normal);
$distance-max-large: var(--distance-max-large);
$distance-max-xlarge: var(--distance-max-xlarge);
$distance-min-xsmall: var(--distance-min-xsmall);
$distance-min-small: var(--distance-min-small);
$distance-min-normal: var(--distance-min-normal);
$distance-min-large: var(--distance-min-large);
$distance-min-xlarge: var(--distance-min-xlarge);

$h1-max-font-size: var(--h1-max-font-size);
$h1-min-font-size: var(--h1-min-font-size);
$h1-line-height: var(--h1-line-height);

$h2-max-font-size: var(--h2-max-font-size);
$h2-min-font-size: var(--h2-min-font-size);
$h2-line-height: var(--h2-line-height);

$h3-max-font-size: var(--h3-max-font-size);
$h3-min-font-size: var(--h3-min-font-size);
$h3-line-height: var(--h3-line-height);

$h4-max-font-size: var(--h4-max-font-size);
$h4-min-font-size: var(--h4-min-font-size);
$h4-line-height: var(--h4-line-height);

$h5-max-font-size: var(--h5-max-font-size);
$h5-min-font-size: var(--h5-min-font-size);
$h5-line-height: var(--h5-line-height);

$h6-max-font-size: var(--h6-max-font-size);
$h6-min-font-size: var(--h6-min-font-size);
$h6-line-height: var(--h6-line-height);

$subheading-max-font-size: var(--subheading-max-font-size);
$subheading-min-font-size: var(--subheading-min-font-size);
$subheading-line-height: var(--subheading-line-height);

$blockquote-max-font-size: var(--blockquote-max-font-size);
$blockquote-min-font-size: var(--blockquote-min-font-size);
$blockquote-line-height: var(--blockquote-line-height);

$paragraph-max-font-size: var(--paragraph-max-font-size);
$paragraph-min-font-size: var(--paragraph-min-font-size);
$paragraph-line-height: var(--paragraph-line-height);

$text-xl-max-font-size: var(--xl-max-font-size);
$text-xl-min-font-size: var(--xl-min-font-size);
$text-xl-line-height: var(--xl-line-height);

$text-xxl-max-font-size: var(--xxl-max-font-size);
$text-xxl-min-font-size: var(--xxl-min-font-size);
$text-xxl-line-height: var(--xxl-line-height);

 :root {
	--font-primary: 'Montserrat', sans-serif;
	--font-secondary: 'Montserrat', serif;
	//
	--color-accent-primary-1: #c69f73;
	--color-accent-primary-2: #ebe6dd;
	--color-accent-primary-3: #c9bcae;
	--color-accent-primary-4: #837567;
	//
	--color-accent-secondary-1: #628ab2;
	--color-accent-secondary-2: #3c556e;
	--color-accent-secondary-3: #283847;
	--color-accent-secondary-4: #162330;
	//
	--distance-max-xsmall: 60;
	--distance-max-small: 100;
	--distance-max-normal: 200;
	--distance-max-large: 300;
	--distance-max-xlarge: 400;
	//
	--distance-min-xsmall: 40;
	--distance-min-small: 40;
	--distance-min-normal: 60;
	--distance-min-large: 100;
	--distance-min-xlarge: 160;
	//
	--xxl-max-font-size: 167;
	--xxl-min-font-size: 58;
	--xxl-line-height: 1;
	//
	--xl-max-font-size: 107;
	--xl-min-font-size: 58;
	--xl-line-height: 1;
	//
	--h1-max-font-size: 65;
	--h1-min-font-size: 34;
	--h1-line-height: 1.28;
	//
	--h2-max-font-size: 52;
	--h2-min-font-size: 30;
	--h2-line-height: 1.38;
	//
	--h3-max-font-size: 42;
	--h3-min-font-size: 26;
	--h3-line-height: 1.57;
	//
	--h4-max-font-size: 33;
	--h4-min-font-size: 22;
	--h4-line-height: 1.61;
	//
	--h5-max-font-size: 20;
	--h5-min-font-size: 18;
	--h5-line-height: 1.7;
	//
	--h6-max-font-size: 18;
	--h6-min-font-size: 16;
	--h6-line-height: 1.81;
	//
	--subheading-max-font-size: 13;
	--subheading-min-font-size: 11;
	--subheading-line-height: 1.75;
	//
	--blockquote-max-font-size: 20;
	--blockquote-min-font-size: 16;
	--blockquote-line-height: 1.7;
	//
	--paragraph-max-font-size: 16;
	--paragraph-min-font-size: 16;
	--paragraph-line-height: 1.75; }

html {
	font-size: $em-base;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-kerning: none; }
body {
	position: relative;
	width: 100%;
	min-width: 320px;
	color: $greyish-brown;
	background-color: $light;
	font-family: $font-primary;
	font-weight: 400;
	font-size: 100%;
	line-height: $line-height;
	word-wrap: break-word; }
.body_lock-scroll {
	overflow: hidden;
	position: fixed;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
img, iframe {
	max-width: 100%;
	height: auto; }
a {
	@include trans1;
	color: $sandstone;
	&:hover, &:focus {
		text-decoration: none;
		color: $brownish-grey; } }
.page-wrapper__content {
	overflow-x: hidden; }
[data-header-animation="intransition"] {
	a {
		pointer-events: none !important; } }
#js-webgl {
	display: none; }

/* ======================================================================== */
/* 1. asideLogos */
/* ======================================================================== */
.aside-logos__wrapper-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 25px;
	padding-bottom: 25px;
	img {
		max-height: 100px;
		width: auto !important;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%); } }

@media only screen and (max-width: $sm) {
	.aside-logos__wrapper-logo {
		.lazy {
			padding: 0 !important; }
		img {
			top: 0 !important;
			left: 0 !important;
			transform: none;
			position: relative !important; } } }

/* ======================================================================== */
/* 2. backgrounds */
/* ======================================================================== */
.bg-white {
	background-color: $white; }
.bg-off-white {
	background-color: $off-white; }
.bg-light {
	background-color: $light; }
.bg-black {
	background-color: $black; }
.bg-dark {
	background-color: $dark; }
.bg-dark-2 {
	background-color: $dark2; }
.bg-dark-3 {
	background-color: $dark3; }
.bg-accent-primary-1 {
	background-color: $sandstone; }
.bg-accent-primary-2 {
	background-color: $pale; }
.bg-accent-primary-3 {
	background-color: $pinkish-grey; }
.bg-accent-primary-4 {
	background-color: $brownish-grey; }
.bg-accent-secondary-1 {
	background-color: $grey-blue; }
.bg-accent-secondary-2 {
	background-color: $dusk; }
.bg-accent-secondary-3 {
	background-color: $dark-blue; }
.bg-accent-secondary-4 {
	background-color: $dark-blue-2; }

/* ======================================================================== */
/* 3. button */
/* ======================================================================== */
.button {
	position: relative;
	display: inline-block;
	@include small-caps;
	font-weight: bold;
	text-align: center;
	@include trans1;
	cursor: pointer;
	outline: none;
	box-shadow: none;
	border-width: 2px;
	border-style: solid;
	@extend .border-radius;
	padding: 16px 32px;
	&:focus {
		outline: none; } }
.button_accent {
	background-color: $pale;
	border-color: $pale;
	color: $brownish-grey;
	.button__icon {
		background-color: $pinkish-grey;
		color: $white; }
	&:hover {
		background-color: $pinkish-grey;
		color: $white;
		.button__icon {
			background-color: $brownish-grey; } } }
.button_icon {
	display: inline-flex;
	padding: 0;
	border: none; }
.button__label {
	padding: 18px 32px; }
.button__icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-top-right-radius: $brdr;
	border-bottom-right-radius: $brdr;
	width: 60px;
	@include trans1;
	i {
		font-size: 24px; } }
.button_fullwidth {
	width: 100%; }


@media screen and (max-width: $md) {
	.button {
		padding: 12px 28px; }
	.button__label {
		padding: 12px 28px; }
	.button__icon {
		width: 43px;
		i {
			font-size: 20px; } }
	.button_icon {
		padding: 0; } }

/* ======================================================================== */
/* 4. buttonBordered */
/* ======================================================================== */
.button_bordered {
	&.button_accent-primary-1 {
		border-color: var(--color-accent-primary-1);
		color: var(--color-accent-primary-1);
		&:focus {
			color: var(--color-accent-primary-1); }
		&:hover {
			background-color: var(--color-accent-primary-1);
			color: $white; } }
	&.button_accent-primary-2 {
		border-color: var(--color-accent-primary-2);
		color: var(--color-accent-primary-2);
		&:focus {
			color: var(--color-accent-primary-2); }
		&:hover {
			background-color: var(--color-accent-primary-2);
			color: $white; } }
	&.button_accent-primary-3 {
		border-color: var(--color-accent-primary-3);
		color: var(--color-accent-primary-3);
		&:focus {
			color: var(--color-accent-primary-3); }
		&:hover {
			background-color: var(--color-accent-primary-3);
			color: $white; } }
	&.button_accent-primary-4 {
		border-color: var(--color-accent-primary-4);
		color: var(--color-accent-primary-4);
		&:focus {
			color: var(--color-accent-primary-4); }
		&:hover {
			background-color: var(--color-accent-primary-4);
			color: $white; } }
	&.button_accent-secondary-1 {
		border-color: var(--color-accent-secondary-1);
		color: var(--color-accent-secondary-1);
		&:focus {
			color: var(--color-accent-secondary-1); }
		&:hover {
			background-color: var(--color-accent-secondary-1);
			color: $white; } }
	&.button_accent-secondary-2 {
		border-color: var(--color-accent-secondary-2);
		color: var(--color-accent-secondary-2);
		&:focus {
			color: var(--color-accent-secondary-2); }
		&:hover {
			background-color: var(--color-accent-secondary-2);
			color: $white; } }
	&.button_accent-secondary-3 {
		border-color: var(--color-accent-secondary-3);
		color: var(--color-accent-secondary-3);
		&:focus {
			color: var(--color-accent-secondary-3); }
		&:hover {
			background-color: var(--color-accent-secondary-3);
			color: $white; } }
	&.button_accent-secondary-4 {
		border-color: var(--color-accent-secondary-4);
		color: var(--color-accent-secondary-4);
		&:focus {
			color: var(--color-accent-secondary-4); }
		&:hover {
			background-color: var(--color-accent-secondary-4);
			color: $white; } }
	&.button_dark {
		border-color: $greyish-brown;
		color: $greyish-brown;
		&:focus {
			color: $greyish-brown; }
		&:hover {
			background-color: $greyish-brown;
			color: $white; } }
	&.button_light {
		border-color: $light;
		color: $light;
		&:focus {
			color: $light; }
		&:hover {
			background-color: $light;
			color: $dark; } }
	&.button_white {
		border-color: $white;
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			background-color: $white;
			color: $dark; } } }

/* ======================================================================== */
/* 5. buttonSolid */
/* ======================================================================== */
.button_solid {
	&.button_accent-primary-1 {
		background-color: var(--color-accent-primary-1);
		border-color: var(--color-accent-primary-1);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-primary-4);
			background-color: var(--color-accent-primary-4); } }
	&.button_accent-primary-2 {
		background-color: var(--color-accent-primary-2);
		border-color: var(--color-accent-primary-2);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-primary-4);
			background-color: var(--color-accent-primary-4); } }
	&.button_accent-primary-3 {
		background-color: var(--color-accent-primary-3);
		border-color: var(--color-accent-primary-3);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-primary-4);
			background-color: var(--color-accent-primary-4); } }
	&.button_accent-primary-4 {
		background-color: var(--color-accent-primary-4);
		border-color: var(--color-accent-primary-4);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-primary-3);
			background-color: var(--color-accent-primary-3); } }
	&.button_accent-secondary-1 {
		background-color: var(--color-accent-secondary-1);
		border-color: var(--color-accent-secondary-1);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-secondary-4);
			background-color: var(--color-accent-secondary-4); } }
	&.button_accent-secondary-2 {
		background-color: var(--color-accent-secondary-2);
		border-color: var(--color-accent-secondary-2);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-secondary-4);
			background-color: var(--color-accent-secondary-4); } }
	&.button_accent-secondary-3 {
		background-color: var(--color-accent-secondary-3);
		border-color: var(--color-accent-secondary-3);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-secondary-4);
			background-color: var(--color-accent-secondary-4); } }
	&.button_accent-secondary-4 {
		background-color: var(--color-accent-secondary-4);
		border-color: var(--color-accent-secondary-4);
		color: $white;
		&:focus {
			color: $white; }
		&:hover {
			border-color: var(--color-accent-secondary-3);
			background-color: var(--color-accent-secondary-3); } }
	&.button_dark {
		background-color: $greyish-brown;
		border-color: $greyish-brown;
		color: $white;
		&:focus {
			color: $greyish-brown; }
		&:hover {
			background-color: $dark;
			color: $white; } }
	&.button_light {
		background-color: $light;
		border-color: $light;
		color: $dusk;
		&:focus {
			color: $light; }
		&:hover {
			background-color: $very-light-pink;
			border-color: $very-light-pink;
			color: $dark; } }
	&.button_white {
		background-color: $white;
		border-color: $white;
		color: $dusk;
		&:focus {
			color: $white; }
		&:hover {
			background-color: $very-light-pink;
			border-color: $very-light-pink;
			color: $dark; } } }

/* ======================================================================== */
/* 6. bootstrapLayout */
/* ======================================================================== */
.container-fluid {
	padding-left: 120px;
	padding-right: 120px; }
.container-fluid_paddings {
	padding-top: 120px;
	padding-bottom: 120px; }

.container {
	padding-left: 20px;
	padding-right: 20px; }
.row {
	margin-left: -20px;
	margin-right: -20px; }
.col, [class*='col-'], [class*='col-'] {
	padding-left: 20px;
	padding-right: 20px; }
.no-gutters {
	margin-left: 0 !important;
	margin-right: 0 !important; }

@media screen and (max-width: $xxl) {
	.container-fluid {
		padding-left: 80px;
		padding-right: 80px; }
	.container-fluid_paddings {
		padding-top: 80px;
		padding-bottom: 80px; } }
@media screen and (max-width: $xl) {
	.container-fluid {
		padding-left: 50px;
		padding-right: 50px; }
	.container-fluid_paddings {
		padding-top: 50px;
		padding-bottom: 50px; } }
@media screen and (max-width: $md) {
	.container-fluid, .container {
		padding-left: 20px;
		padding-right: 20px; }
	.container-fluid_paddings {
		padding-top: 20px;
		padding-bottom: 20px; } }
@media screen and (max-width: $xs) {
	.container_xs-no-padding {
		padding-left: 0;
		padding-right: 0; } }

/* ======================================================================== */
/* 7. buttonCircles */
/* ======================================================================== */
.button-circles {
	display: inline-flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 36px;
	height: 36px; }
.button-circles__circle {
	position: relative;
	width: 14px;
	height: 14px;
	outline: none;
	display: inline-block;
	border: 2px solid $borders-dark;
	vertical-align: middle;
	border-radius: 100%;
	&:nth-child(1) {
		align-self: flex-start; }
	&:nth-child(2) {
		align-self: flex-start; }
	&:nth-child(3) {
		align-self: flex-end; }
	&:nth-child(4) {
		align-self: flex-end; }
	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: auto;
		width: 14px;
		height: 14px;
		border-radius: 100%;
		stroke-width: 42px;
		z-index: 50;
		.circle {
			stroke: $sandstone; } } }

@media screen and (max-width: $sm) {
	.button-circles {
		width: 30px;
		height: 30px; }
	.button-circles__circle {
		width: 12px;
		height: 12px;
		svg {
			width: 12px;
			height: 12px; } } }

/* ======================================================================== */
/* 8. comments */
/* ======================================================================== */
.comments-title, .comment-reply-title {
	font-family: $font-primary;
	margin-top: 0;
	margin-bottom: 0; }
.comment-list {
	@include reset-ul;
	padding-left: 0 !important;
	> li {
		padding-top: 30px;
		padding-bottom: 30px;
		ol.children {
			@include reset-ul;
			padding-left: 4%;
			li {
				padding-top: 30px;
				padding-bottom: 30px; }
			li:last-child {
				padding-bottom: 0; } } }
	> li:not(:last-child) {
		border-bottom: 1px solid $borders-dark; }
	> ol {
		@include reset-ul; } }
.comment-author {
	// width: 100%
	max-width: 100px;
	margin-right: 2em;
	flex: 0 0 auto;
	.avatar {
		position: relative;
		width: 100%;
		max-width: 100px;
		max-height: 100px;
		border-radius: 100%;
		display: block; } }

.comment-meta {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.5em; }

.comment-metadata {
	@include small-caps;
	margin-left: 10px;
	a:not(.comment-edit-link) {
		color: $brown-grey;
		&:hover {
			color: var(--color-accent-secondary); } }
	a {
		border-bottom: none !important; } }
.comment-body {
	display: flex;
	.fn {
		font-family: $font-secondary;
		font-size: 20px;
		font-weight: bold;
		line-height: 1.7;
		a {
			color: $dusk;
			&:hover {
				color: $sandstone; } } } }
.comment-content {
	width: 100%; }
.reply {
	display: inline-block;
 }	// margin-top: 10px
.comment-reply-link {
	@include small-caps;
	border-bottom: none !important;
	margin-right: 24px;
	&:before {
		content: '\f112';
		font-family: 'FontAwesome';
		text-transform: none;
		margin-right: 8px; } }
.comment-respond {
	margin-top: 30px; }
.comment-edit-link {
	@include small-caps;
	color: var(--color-accent-primary);
	border-bottom: none !important;
	&:before {
		content: '\f044';
		font-family: 'FontAwesome';
		text-transform: none;
		margin-right: 6px; } }

@media only screen and (max-width: $md) {
	.comment-author {
		margin-right: 1em;
		.avatar {
			max-width: 50px;
			max-height: 50px; } }
	.comment-meta {
		flex-wrap: wrap; }
	.comment-metadata {
		margin-top: 5px;
		margin-left: 0; } }

/* ======================================================================== */
/* 9. colors */
/* ======================================================================== */
.color-white {
	color: $white;
	.header__burger-line {
		background-color: $white; }
	.header__controls .social__item a {
		color: $white; }
	.heading-light {
		color: $very-light-pink; }
	.subheading {
		color: $white; } }
.color-black {
	color: $greyish-brown;
	.subheading {
		color: $brown-grey; }
	.heading-light {
		color: $brown-grey; } }

/* ======================================================================== */
/* 10. colorsAccentPrimary */
/* ======================================================================== */
.color-accent-primary-1 {
	color: var(--color-accent-primary-1); }
.color-accent-primary-2 {
	color: var(--color-accent-primary-2); }
.color-accent-primary-3 {
	color: var(--color-accent-primary-3); }
.color-accent-primary-4 {
	color: var(--color-accent-primary-4); }

/* ======================================================================== */
/* 11. colorsAccentSecondary */
/* ======================================================================== */
.color-accent-secondary-1 {
	color: var(--color-accent-secondary-1); }
.color-accent-secondary-2 {
	color: var(--color-accent-secondary-2); }
.color-accent-secondary-3 {
	color: var(--color-accent-secondary-3); }
.color-accent-secondary-4 {
	color: var(--color-accent-secondary-4); }

/* ======================================================================== */
/* 12. counter */
/* ======================================================================== */
.counter {
	text-align: center;
	color: $brown-grey; }
.counter__number {
	font-family: $font-primary;
	font-weight: 100; }
.counter__label {
	margin-top: 5px;
	font-weight: 400; }

/* ======================================================================== */
/* 13. figureAward */
/* ======================================================================== */
.figure-award {
	position: relative;
	@include fluid-padding-top($min_width, $max_width, 30, 50);
	@include fluid-padding-bottom($min_width, $max_width, 30, 50); }
.figure-award__year {
	font-family: $font-primary;
	font-weight: 100;
	line-height: 1;
	@include fluid-type($min_width, $max_width, 42, 65);
	color: $sandstone; }
.figure-award__items {
	> *:first-child {
		margin-top: 0; }
	> *:last-child {
		margin-bottom: 0; } }
.figure-award__border-line {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	border: 1px solid $borders-light; }

@media screen and (max-width: $xs) {
	.figure-award__year {
		margin-bottom: 30px; } }

/* ======================================================================== */
/* 14. figureContact */
/* ======================================================================== */
.figure-contact {
	text-align: center; }
.figure-contact__icon {
	font-size: 36px !important;
	line-height: 1 !important;
	display: inline-block;
	margin-bottom: 30px;
	color: $dusk; }

@media only screen and (max-width: $sm) {
	.figure-contact__icon {
		font-size: 28px;
		margin-bottom: 20px; } }
@media screen and (max-width: $md) {
	.figure-contact {
		@include fluid-padding-top($min_width, $max_width, $distance-min-small / 3, $distance-max-small / 2);
		@include fluid-padding-bottom($min_width, $max_width, $distance-min-small / 3, $distance-max-small / 2); } }

/* ======================================================================== */
/* 15. figureInfo */
/* ======================================================================== */
.figure-info__option {
	@include small-caps;
	margin-bottom: 8px; }

/* ======================================================================== */
/* 16. figureFeature */
/* ======================================================================== */
.figure-feature {
	position: relative;
	text-align: center;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	@include fluid-padding-top($min_width, $max_width, 40, 90);
	@include fluid-padding-bottom($min_width, $max_width, 40, 90); }
.figure-feature__corner {
	position: absolute;
	width: 30px;
	height: 30px;
	border-color: $sandstone; }
.figure-feature__corner_top {
	top: 0;
	left: 0;
	border-top-width: 1px;
	border-left-width: 1px;
	border-top-style: solid;
	border-left-style: solid; }
.figure-feature__corner_bottom {
	border-bottom-width: 1px;
	border-right-width: 1px;
	border-bottom-style: solid;
	border-right-style: solid;
	right: 0;
	bottom: 0; }
.figure-feature__icon {
	font-size: 48px;
	color: $sandstone;
	width: 48px;
	height: 48px; }
.figure-feature__header {
	@include fluid-margin-top($min_width, $max_width, 16, 24);
	width: 100%; }
.figure-feature__heading {
	margin-top: 0;
	margin-bottom: 0; }

/* ======================================================================== */
/* 17. figureMember */
/* ======================================================================== */
.figure-member {
	text-align: center; }
.figure-member_has-social {
	&:hover {
		.figure-member__curtain {
			transform: skewY(-5deg) translateY(0%); }
		.figure-member__wrapper-img img {
			transform: translateY(-20px); }
		.figure-member__social {
			li {
				transform: translateY(0px);
				opacity: 1;
				visibility: visible;
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: 50ms + $i * 30ms; } } } }
		.figure-member__content {
			transform: translate(0, -25%); }
		.figure-member__line {
			transform: scaleY(0); } } }
.figure-member__position {
	@include small-caps;
	color: $pinkish-grey; }
.figure-member__wrapper-img {
	position: relative;
	img {
		@include trans1;
		width: 100%; } }
.figure-member__wrapper-img-inner {
	position: relative;
	overflow: hidden; }
.figure-member__line {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -30px;
	margin: 0 auto;
	height: 60px;
	width: 1px;
	background-color: $sandstone;
	@include trans1;
	transform-origin: top center; }
.figure-member__heading {
	margin-top: 50px;
	margin-bottom: 0; }
.figure-member__position {
	margin-top: 12px; }
.figure-member__curtain {
	position: absolute;
	left: -20px;
	right: -20px;
	bottom: -20px;
	transform: skewY(-5deg) translateY(100%);
	@include trans1;
	padding: 60px 30px 60px; }
.figure-member__social {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 20px 30px;
	li {
		transform: translateY(30px);
		@include trans1;
		opacity: 0;
		visibility: hidden; } }
.figure-member__content {
	@include trans1; }

/* ======================================================================== */
/* 18. figurePortfolio */
/* ======================================================================== */
.figure-portfolio {
	display: block; }
.figure-portfolio__wrapper-img {
	position: relative;
	overflow: hidden; }
.figure-portfolio__content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 50px;
	background: none !important;
 }	// z-index: 50
.figure-portfolio__header {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 50; }
.figure-portfolio__category {
	position: relative;
	@include small-caps;
	font-weight: normal;
	z-index: 50;
	margin-bottom: 1em; }
.figure-portfolio__curtain {
	position: absolute;
	top: -20px;
	left: -20px;
	right: -20px;
	bottom: -100px;
	z-index: 1;
	transform: skewY(0deg) translateY(100%);
 }	// +trans1
.figure-portfolio__heading {
	margin-top: 0;
	margin-bottom: 0;
	line-height: 1.33; }
.figure-portfolio__icon {
	margin-top: 10px;
	z-index: 50; }

@media screen and (max-width: $md) {
	.figure-portfolio__content {
		padding: 30px; }
	.figure-portfolio__icon {
		margin-top: 5px; } }

/* ======================================================================== */
/* 19. figurePortfolioBig */
/* ======================================================================== */
.figure-portfolio-big {
	display: block;
	position: relative;
	&:hover {
		.figure-portfolio-big__wrapper-img-inner {
			transform: scale(0.95); }
		.figure-portfolio-big__wrapper-img img {
			transform: scale(1.2); }
		.figure-portfolio-big__content {
			transform: translate(0, -25%); } } }
.figure-portfolio-big__category {
	margin-top: 0.5em;
	margin-bottom: 0;
	@include small-caps;
	color: $sandstone;
	font-weight: normal; }
.figure-portfolio-big__heading {
	margin-top: 0.75em;
	margin-bottom: 0.5em; }
.figure-portfolio-big__letter {
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(-75%);
	z-index: -1;
	color: $brown-grey;
	font-family: $font-secondary;
	font-weight: bold;
	line-height: 0.66;
	@include fluid-type($min_width, $max_width, 67, 250);
	opacity: .15; }
.figure-portfolio-big__wrapper-img img {
	@include trans1;
	transform-origin: center center;
	transform: scale(1); }
.figure-portfolio-big__wrapper-img-inner {
	@include trans1;
	transform-origin: center center;
	transform: scale(1);
	overflow: hidden; }
.figure-portfolio-big__content {
	@include trans1; }

@media screen and (max-width: $lg) {
	.figure-portfolio-big__letter {
		display: none; } }

/* ======================================================================== */
/* 20. figureImage */
/* ======================================================================== */
.figure-image {
	position: relative;
	text-align: center;
	img {
		width: 100%; } }
.figure-image__wrapper-img {
	position: relative;
	overflow: hidden; }

/* ======================================================================== */
/* 21. figurePost */
/* ======================================================================== */
.figure-post {
	display: block;
	&:hover {
		.figure-post__wrapper-img img {
			transform: scale(1.05); } }
	&:hover {
		.figure-post__wrapper-img-inner {
			transform: scale(0.95); }
		.figure-post__wrapper-img img {
			transform: scale(1.2); }
		.figure-post__content {
			transform: translate(0, -25%); }
		.figure-post__line {
			transform: scaleY(0); } } }
.figure-post__date {
	@include small-caps;
	color: $sandstone; }
.figure-post__wrapper-img {
	position: relative;
	img {
		width: 100%;
		@include trans1; } }
.figure-post__line {
	display: inline-block;
	width: 1px;
	height: 60px;
	background-color: $sandstone;
	position: absolute;
	bottom: -30px;
	z-index: 50;
	@include trans1;
	transform-origin: top center; }
.figure-post__content {
	padding-top: 60px; }
.figure-post__heading {
	margin-top: 0.5em;
	margin-bottom: 0; }
.figure-post__wrapper-img-inner {
	@include trans1;
	overflow: hidden; }
.figure-post__content {
	@include trans1; }

/* ======================================================================== */
/* 22. filter */
/* ======================================================================== */
.filter {
	position: relative;
	padding-top: 45px;
	padding-bottom: 45px;
	text-align: center; }
.filter__inner {
	position: relative; }
.filter__item {
	@include small-caps;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
	@include trans1;
	&:not(.filter__item_active):hover {
		color: $greyish-brown; } }
.filter__item_active {
	color: $sandstone; }
.filter__underline {
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: $sandstone;
	width: 0;
	height: 1px; }

@media screen and (max-width: $xl) {
	.filter__underline {
		display: none; } }

/* ======================================================================== */
/* 23. footer */
/* ======================================================================== */
.footer {
	position: relative; }
.footer__area-primary {
	@include fluid-padding-top($min_width, $max_width, 30, 100);
	@include fluid-padding-bottom($min_width, $max_width, 30, 100); }
.footer__area-secondary {
	padding-top: 30px;
	padding-bottom: 30px;
	border-top: 1px solid $borders-dark; }


@media screen and (max-width: $md) {
	.footer__area-secondary {
		padding-top: 15px;
		padding-bottom: 15px; }
	.footer__column {
		padding-top: 10px;
		padding-bottom: 10px;
		&.text-left, &.text-center, &.text-right {
			text-align: center !important; } } }

/* ======================================================================== */
/* 24. form */
/* ======================================================================== */
.form {
	width: 100%; }
.form__submit {
	margin-top: 1.5em; }
.form__col {
	margin-top: 0.5em;
	margin-bottom: 0.5em; }
.form__col_submit {
	margin-top: 1.5em;
	margin-bottom: 0; }
.form__error, span.wpcf7-not-valid-tip {
	display: block;
	font-size: 12px;
	color: red;
	text-align: left;
	margin-top: 4px; }
.form__heading {
	margin-top: 0;
	margin-bottom: 1em; }

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
	border-color: red;
	padding: 15px;
	margin: 1.5em 0 0;
	display: none !important; }

@media only screen and (max-width: 992px) {
	.form__col_submit {
		text-align: left; } }

/* ======================================================================== */
/* 25. gmap */
/* ======================================================================== */
.gmap {
	width: 100%;
	height: 100%; }
.gmap__container {
	width: 100%;
	height: 100%;
	color: $dark;
	@extend .border-radius; }

@media only screen and (max-width: $md) {
	.gmap__container {
		height: 600px;
		max-height: 120vh; } }

/* ======================================================================== */
/* 26. formContact */
/* ======================================================================== */
.form-contact {
	@include fluid-padding-top($min_width, $max_width, $distance-min-small, $distance-max-small);
	@include fluid-padding-bottom($min_width, $max_width, $distance-min-small, $distance-max-small); }

/* ======================================================================== */
/* 27. inputFloat */
/* ======================================================================== */
.input-float {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 0; }
.input-float__label {
	position: absolute;
	top: 1em;
	left: 0;
	margin: auto;
	display: block;
	font-size: 16px;
	@include trans1;
	transform-origin: left center;
	cursor: text;
	color: $brown-grey; }
.input-float__input {
	display: block;
	width: 100%;
	width: 100%;
	border-bottom: 1px solid $borders-dark;
	border-top: none;
	border-right: none;
	border-left: none;
	outline: none;
	padding: 12px 0 8px;
	@include trans1;
	// line-height: 1.2
	background-color: transparent;
	border-radius: 0;
	color: inherit; }
.input-float__input_textarea {
	resize: none;
	min-height: 200px; }

.input-float__input_focused, .input-float__input_not-empty {
	+ .input-float__label {
		transform: scale(0.8571) translateY(-2rem); } }

.input-float__input_focused {
	border-color: $sandstone;
	+ .input-float__label {
			color: $sandstone; } }

/* ======================================================================== */
/* 28. grid */
/* ======================================================================== */
.grid {
	overflow: hidden; }
.grid__sizer {
	padding: 0 !important;
	margin: 0 !important;
	height: 0 !important; }
.grid__item {
	display: block;
	width: 100%; }
.grid__item-link {
	display: block;
	position: relative;
	img {
		@include trans1;
		transform-origin: center center; }
	&:hover, &:focus {
		opacity: 1; }
	&:hover {
		img {
			transform: scale(1.1); }
		.grid__item-overlay {
			transform: scaleY(1); }
		.grid__item-icon {
			transform: translate(-50%, -50%);
			opacity: 1;
			visibility: visible;
			transition-delay: 50ms;
			&:after {
				transform: scale(1);
				transition-delay: 100ms; } } } }
.grid__item-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 80px;
	height: 80px;
	top: 50%;
	left: 50%;
	z-index: 50;
	transform: translate(-50%, 10px);
	transition: all 0.6s ease;
	opacity: 0;
	visibility: hidden;
	color: $white;
	&:after {
		content: '';
		display: block;
		border-radius: 100%;
		border: 1px solid $brown-grey;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scale(0);
		@include trans1; } }
.grid__item-icon-symbol {
	font-size: 28px !important; }
.grid__item-overlay {
	opacity: 1;
	background-color: $dark;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	@include trans1;
	transform: scaleY(0);
	transform-origin: bottom center; }

.grid__item-icon {
	width: 60px;
	height: 60px; }
.grid__item-icon-symbol {
	font-size: 24px !important; }

@media only screen and (min-width: $md + 1px) {
	.grid__item_desktop-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.grid__item_desktop-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.grid__item_desktop-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }
	.grid__item_desktop-3 {
		flex: 0 0 25%;
		max-width: 25%; } }

@media only screen and (min-width: $sm + 1px) and (max-width: $md) {
	.grid__item_tablet-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.grid__item_tablet-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.grid__item_tablet-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }
	.grid__item_tablet-3 {
		flex: 0 0 25%;
		max-width: 25%; } }

@media only screen and (max-width: $sm) {
	.grid__item_mobile-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.grid__item_mobile-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.grid__item_mobile-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }
	.grid__item_mobile-3 {
		flex: 0 0 25%;
		max-width: 25%; } }

/* ======================================================================== */
/* 29. gridFluid */
/* ======================================================================== */
@for $i from 1 through 20 {
	.grid_fluid-#{$i} {
		margin: -#{$i}vw;
		@media only screen and (max-width: $sm) {
			margin: -10px -20px; } }
	.grid__item_fluid-#{$i} {
		padding: #{$i}vw;
		@media only screen and (max-width: $sm) {
			padding: 20px; } }
	.grid__item_fluid-#{$i}-fancy {
		@media only screen and (min-width: $sm + 1px) {
			&:nth-of-type(3) {
				margin-top: #{$i * 2}vw; } } } }

/* ======================================================================== */
/* 30. header */
/* ======================================================================== */
.header {
	padding: 75px 0 0; }
.header_absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 500; }
.header_fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 500; }
.header__burger-line {
	width: 100%;
	height: 2px;
	background-color: $brown-grey;
	margin-top: 3px;
	margin-bottom: 3px;
	transform: rotate(0deg) translateY(0px);
	will-change: transform;
	@include trans2; }
.header__wrapper-overlay-menu {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 500;
	background: none !important;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
	visibility: hidden; }
.header__burger {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	width: 30px;
	height: 20px;
	cursor: pointer;
	vertical-align: middle; }
.header__burger_opened {
	.header__burger-line {
		background-color: $brown-grey !important;
		&:nth-child(1) {
			transform: rotate(-45deg) translateY(6px); }
		&:nth-child(2) {
			transform: rotate(45deg) translateY(-6px); } } }
.header__wrapper-menu {
	position: relative;
	width: 100%;
	max-width: calc(50vw - 300px); }
.header__curtain {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: -1; }
.header__wrapper-overlay-widgets {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 64px;
	.social {
		margin-top: 1em;
		margin-bottom: 1em; } }
.header__overlay-menu-back {
	position: absolute;
	top: 0;
	cursor: pointer;
	font-size: 32px !important;
	color: $brown-grey !important;
	opacity: 0;
	visibility: hidden; }
.header__controls {
	position: relative;
	z-index: 600; }
.header__circle-letters {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 300px;
	height: 300px;
	border: 2px solid $borders-dark;
	border-radius: 100%;
	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 300px;
		height: 300px;
		border-radius: 100%;
		stroke-width: 1px;
		z-index: 50;
		.circle {
			stroke: $sandstone; } } }
.header__circle-letters-inner {
	width: 100%;
	height: 100%;
	position: relative; }


@media screen and (max-width: $xxl) {
	.header {
		padding-top: 50px; }
	.header__wrapper-overlay-widgets {
		padding-bottom: 40px; } }

@media screen and (max-width: $xl) {
	.header {
		padding-top: 40px; }
	.header__wrapper-overlay-widgets {
		padding-bottom: 30px; } }

@media screen and (max-width: $md) {
	.header {
		padding-top: 20px; }
	.header__circle-letters {
		display: none; }
	.header__wrapper-menu {
		max-width: 100%;
		margin-top: auto;
		margin-bottom: auto; }
	.header__wrapper-overlay-menu {
		text-align: center;
		flex-direction: column;
		padding-top: 80px;
		padding-bottom: 80px; }
	.header__wrapper-overlay-widgets {
		text-align: center;
		position: relative;
		left: auto;
		right: auto;
		bottom: auto;
		padding-top: 30px;
		padding-bottom: 30px;
		margin-bottom: auto;
		.text-left, .text-right {
			text-align: center !important; } } }

@media screen and (max-height: 800px) and (min-width: $md) {
	.header {
		padding-top: 40px; }
	.header__wrapper-overlay-widgets {
		padding-bottom: 30px; } }

/* ======================================================================== */
/* 31. headerColors */
/* ======================================================================== */
.header_color-white {
	.header__burger-line {
		background-color: $white; }
	.header__controls .social__item a {
		color: $white; }
	&:not(.header_sticky) {
		.menu > li > a {
			color: $white;
			&:hover {
				color: $sandstone; } } } }
/////////////////////////////////////////////
.header_color-black {
	.header__burger-line {
		background-color: $greyish-brown; }
	.header__controls .social__item a {
		color: $brown-grey; } }

/* ======================================================================== */
/* 32. headerOverlayThemes */
/* ======================================================================== */
.header__wrapper-overlay-menu {
	&.bg-white {
		.header__curtain {
			background-color: $white; }
		.menu-overlay li a {
			color: $dusk;
			&:hover {
				color: $dark-blue-2; } }
		.vector-letter {
			fill: $dusk; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-off-white {
		.header__curtain {
			background-color: $off-white; }
		.menu-overlay li a {
			color: $dusk;
			&:hover {
				color: $dark-blue-2; } }
		.vector-letter {
			fill: $dusk; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-light {
		.header__curtain {
			background-color: $light; }
		.menu-overlay li a {
			color: $dusk;
			&:hover {
				color: $dark-blue-2; } }
		.vector-letter {
			fill: $dusk; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-black {
		.header__curtain {
			background-color: $black; }
		.menu-overlay li a {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-dark {
		.header__curtain {
			background-color: $dark; }
		.menu-overlay li a {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-dark-2 {
		.header__curtain {
			background-color: $dark2; }
		.menu-overlay li a {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-dark-3 {
		.header__curtain {
			background-color: $dark3; }
		.menu-overlay li a {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-1 {
		.header__curtain {
			background-color: $sandstone; }
		.menu-overlay li a {
			color: $light;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; }
		.header__overlay-menu-back {
			color: $dusk; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-2 {
		.header__curtain {
			background-color: $pale; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-3 {
		.header__curtain {
			background-color: $pinkish-grey; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-4 {
		.header__curtain {
			background-color: $brownish-grey; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-1 {
		.header__curtain {
			background-color: $grey-blue; }
		.menu-overlay li a {
			color: $white;
			&:hover {
				color: $pale; } }
		.vector-letter {
			fill: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-2 {
		.header__curtain {
			background-color: $dusk; }
		.menu-overlay li a {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; } }

	/////////////////////////////////////////////
	&.bg-accent-secondary-3 {
		.header__curtain {
			background-color: $dark-blue; }
		.menu-overlay li a {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-4 {
		.header__curtain {
			background-color: $dark-blue-2; }
		.menu-overlay li a {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } }
		.vector-letter {
			fill: $white; } } }

/* ======================================================================== */
/* 33. headerSticky */
/* ======================================================================== */
.js-sticky-header {
	transition: all 0.6s ease;
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
	will-change: padding, box-shadow; }
.header_sticky {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 15px 0;
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.1); }

/* ======================================================================== */
/* 34. headerThemes */
/* ======================================================================== */
.header {
	&.bg-white {
		.header__burger-line {
			background-color: $dark; }
		.social__item a {
			color: $brown-grey; } }
	/////////////////////////////////////////////
	&.bg-off-white {
		.header__burger-line {
			background-color: $dark; }
		.social__item a {
			color: $brown-grey; } }
	/////////////////////////////////////////////
	&.bg-light {
		.header__burger-line {
			background-color: $dark; }
		.social__item a {
			color: $brown-grey; } }
	/////////////////////////////////////////////
	&.bg-black {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $sandstone; } }
			.sub-menu {
				background-color: $dark2;
				> li a {
					color: $white;
					&:hover {
						background-color: $dark3; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } }
	/////////////////////////////////////////////
	&.bg-dark {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $sandstone; } }
			.sub-menu {
				background-color: $dark2;
				> li a {
					color: $white;
					&:hover {
						background-color: $dark3; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } }
	/////////////////////////////////////////////
	&.bg-dark-2 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $sandstone; } }
			.sub-menu {
				background-color: $dark2;
				> li a {
					color: $white;
					&:hover {
						background-color: $dark3; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } }
	/////////////////////////////////////////////
	&.bg-dark-3 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $sandstone; } }
			.sub-menu {
				background-color: $dark2;
				> li a {
					color: $white;
					&:hover {
						background-color: $dark3; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-1 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $brownish-grey; }
				&:before {
					background-color: $brownish-grey; } }
			.sub-menu {
				background-color: $pinkish-grey;
				> li a {
					color: $white;
					&:hover {
						border-color: $dark;
						background-color: $brownish-grey; } } } }
		.logo {
			color: $white;
			&:hover {
				color: $brownish-grey; } }
		.social__item a {
			color: $white;
			&:hover {
				color: $brownish-grey; } } }
	/////////////////////////////////////////////
	&.bg-accent-primary-3 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $brownish-grey; }
				&:before {
					background-color: $brownish-grey; } }
			.sub-menu {
				background-color: $pinkish-grey;
				> li a {
					color: $white;
					&:hover {
						border-color: $dark;
						background-color: $brownish-grey; } } } }
		.logo {
			color: $white;
			&:hover {
				color: $brownish-grey; } }
		.social__item a {
			color: $white;
			&:hover {
				color: $brownish-grey; } } }
	/////////////////////////////////////////////
	&.bg-accent-primary-4 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $very-light-pink; }
				&:before {
					background-color: $very-light-pink; } }
			.sub-menu {
				background-color: $pinkish-grey;
				> li a {
					color: $white;
					&:hover {
						border-color: $dark;
						background-color: $pale;
						color: $dark; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $brownish-grey; } } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-1 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $very-light-pink; }
				&:before {
					background-color: $very-light-pink; } }
			.sub-menu {
				background-color: $dusk;
				> li a {
					color: $white;
					&:hover {
						border-color: $sandstone;
						background-color: $grey-blue; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-2 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $very-light-pink; }
				&:before {
					background-color: $very-light-pink; } }
			.sub-menu {
				background-color: $dusk;
				> li a {
					color: $white;
					&:hover {
						border-color: $sandstone;
						background-color: $grey-blue; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-3 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $very-light-pink; }
				&:before {
					background-color: $very-light-pink; } }
			.sub-menu {
				background-color: $dusk;
				> li a {
					color: $white;
					&:hover {
						border-color: $sandstone;
						background-color: $grey-blue; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-4 {
		.menu {
			> li > a {
				color: $white;
				&:hover {
					color: $very-light-pink; }
				&:before {
					background-color: $very-light-pink; } }
			.sub-menu {
				background-color: $dusk;
				> li a {
					color: $white;
					&:hover {
						border-color: $sandstone;
						background-color: $grey-blue; } } } }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } }
		.header__burger-line {
			background-color: $white; } } }

/* ======================================================================== */
/* 35. inputSearch */
/* ======================================================================== */
.input-search__input {
	padding-right: 30px; }
.input-search__submit {
	display: inline-block;
	font-size: 18px;
	width: 24px;
	height: 24px;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	background: transparent;
	border: none;
	color: $brown-grey;
	outline: none;
	@include trans1;
	cursor: pointer;
	&:hover {
		color: $dark; }
	&:focus {
		outline: none; } }

/* ======================================================================== */
/* 36. logo */
/* ======================================================================== */
.logo {
	position: relative;
	color: $sandstone;
	font-weight: bold;
	display: inline-flex; }
.logo__wrapper-img {
	align-items: center;
	flex: 1 0 auto;
	height: 100%;
	img {
		height: 50px; } }

/* ======================================================================== */
/* 37. menu */
/* ======================================================================== */
.menu {
	@include reset-ul;
	word-wrap: normal;
	text-align: right;
	display: inline-block;
	font-size: 13px;
	> li {
		display: inline-block;
		&:not(:last-child) {
			margin-right: 17px; }
		a {
			display: block;
			color: $brown-grey;
			padding: 15px;
			&:hover {
				color: $sandstone; } } }
	> li.menu-item-has-children {
		> a {
			&:after {
				content: '\f107';
				font-family: 'FontAwesome';
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px; } } }

	> li:not(.menu-item-has-children) {
		a {
			position: relative;
			&:hover {
				&:before {
					left: 15px;
					width: calc(100% - 30px); } }
			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 1px;
				background-color: $sandstone;
				bottom: 12px;
				right: 15px;
				@include trans1; } } }
	.menu-item-has-children {
		position: relative;
		> a {
			&:hover {
				~ ul {
					opacity: 1;
					visibility: visible;
					transform: translate(0, 0); } } } } }

.menu {
	.sub-menu {
		position: absolute;
		top: 100%;
		left: 15px;
		@include reset-ul;
		padding: 10px 0;
		opacity: 0;
		visibility: hidden;
		@include trans1;
		transform: translate(0, 10px);
		box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
		background-color: $white;
		z-index: 50;
		@extend .border-radius;
		> li {
			display: block;
			white-space: nowrap;
			a {
				display: flex;
				align-items: center;
				padding: 10px 30px;
				border-left: 2px solid transparent;
				&:hover {
					border-color: $sandstone;
					background-color: $light; } } }
		> li.menu-item-has-children {
			> a {
				&:after {
					content: '\f105';
					font-family: 'FontAwesome';
					display: inline-block;
					vertical-align: middle;
					margin-left: auto;
					padding-left: 15px; } } }
		ul {
			top: 0;
			right: calc(100% + 1px);
			transform: translate(10px, 0); }
		&:hover {
			opacity: 1;
			visibility: visible;
			transform: translate(0, 0); } } }

/* ======================================================================== */
/* 38. link */
/* ======================================================================== */
.link-arrow {
	display: inline-flex;
	align-items: center;
	@include small-caps;
	color: $sandstone;
	font-weight: bold; }
.link-arrow__label {
	display: inline-block;
	vertical-align: middle; }
.link-arrow__icon {
	display: inline-block;
	font-size: 20px !important;
	margin-bottom: 1px;
	margin-left: 10px;
	vertical-align: middle; }

/* ======================================================================== */
/* 39. lazy */
/* ======================================================================== */
.lazy {
	background-color: rgba(136, 136, 136, .1); }
.lazy_loaded {
	background-color: unset; }
.lazy__img:not(img) {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat; }
.lazy-bg {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: rgba(136, 136, 136, .1); }
.lazy-bg_loaded {
	background-color: unset; }

/* ======================================================================== */
/* 40. menuOverlay */
/* ======================================================================== */
.menu-overlay {
	@include reset-ul;
	> li {
		display: block;
		> a {
			display: block;
			font-family: $font-secondary;
			@include fluid-type($min_width, $max_width, 28, 42);
			font-weight: bold; } } }
.menu-overlay__item-wrapper {
	font-family: $font-secondary; }
.menu-overlay .sub-menu {
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-start;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	@include reset-ul;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	> li {
		display: block;
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		> a {
			display: block;
			font-family: $font-secondary;
			font-size: 20px;
			@include fluid-type($min_width, $max_width, 20, 26);
			font-weight: bold;
			line-height: 1.2;
			padding: 8px 0; } } }

@media screen and (max-width: $md) {
	.menu-overlay {
		> li {
			> a {
				margin-top: 0.25em;
				margin-bottom: 0.25em; } } } }

/* ======================================================================== */
/* 41. overlay */
/* ======================================================================== */
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: -1; }
.overlay_dark {
	background-color: $dark;
	opacity: .8;
	mix-blend-mode: multiply; }
.overlay_black {
	background-color: $black;
	opacity: .8; }
.overlay_white {
	background-color: $white;
	opacity: .95; }
.overlay_white-solid {
	background-color: $white;
	opacity: 1; }


/* ======================================================================== */
/* 42. pagination */
/* ======================================================================== */
.pagination {
	margin-top: 60px;
	border-top: 2px solid $dark;
	padding: 30px 0 0;
	.nav-links {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	.nav-links__container {
		margin-left: auto;
		margin-right: auto; }
	.page-numbers {
		display: inline-block;
		line-height: 1;
		font-weight: 600;
		margin: 0 15px;
		background-image: none;
		vertical-align: middle;
		&.prev {
			font-size: 20px;
			font-weight: normal;
			margin-left: 0; }
		&.next {
			font-size: 20px;
			font-weight: normal;
			margin-right: 0; }
		&:not(a) {
			color: $brown-grey; } } }
.page-links {
	margin-top: 50px;
	border-top: 2px solid $dusk;
	padding: 30px 0 0;
	.page-number {
		display: inline-block;
		line-height: 1;
		font-weight: 600;
		margin: 0 15px;
		border: none; }
	a {
		background-image: none; }
	.post-page-numbers {
		&:not(a) {
			color: $brown-grey; } } }

.comments-pagination {
	text-align: center;
	.page-numbers {
		display: inline-block;
		line-height: 1;
		font-weight: 600;
		margin: 0 20px; } }

/* ======================================================================== */
/* 43. post */
/* ======================================================================== */
.post__content, .post__comments {
	> *:first-child {
		margin-top: 0; }
	ul {
		@include reset-ul;
		margin-bottom: 1.5em;
		li {
			display: block;
			margin-top: 1em;
			margin-bottom: 1em; }
		ul, ol {
			padding-left: 1em; } }
	ul {
		padding-left: 0.25em;
		> li {
			&:before {
				content: '';
				display: inline-block;
				width: 10px;
				height: 1px;
				vertical-align: middle;
				margin-right: 0.5em;
				margin-bottom: 3px;
				background-color: $dark; } } }
	ol {
		margin-bottom: 24px;
		padding-left: 1.5em;
		li {
			display: list-item;
			margin-top: 12px;
			margin-bottom: 12px; }
		ul, ol {
			padding-left: 16px; } } }
.post__media {
	margin-bottom: 2em; }
.post__tags {
	border-top: 2px solid $dusk;
	margin-top: 50px;
	padding-top: 20px; }
.post__comments {
	@include fluid-margin-top($min_width, $max_width, $distance-min-small, $distance-max-small); }
.post__read-more {
	padding: 16px 30px; }
.sticky {
	background-color: $very-light-pink;
	padding: 50px; }

/* ======================================================================== */
/* 44. postPreview */
/* ======================================================================== */
.post-preview__wrapper-content {
	> *:first-child {
		margin-top: 0; } }
.post-preview__media {
	display: block;
	margin-bottom: 1.5em;
	overflow: hidden;
	&:hover, &:focus {
		opacity: 1; }
	&:hover {
		> * {
			transform: scale(1.05); } }
	> * {
		@include trans1; } }
.post-preview__header {
	h4 {
		margin-top: 0;
		margin-bottom: 0.5em; }
	a {
		color: $dusk;
		&:hover {
			color: $sandstone; } } }
.post-preview__categories {
	@include reset-ul;
	li {
		display: inline-block; } }
.post-preview__meta {
	margin-bottom: 1em;
	font-size: 13px; }
.post-preview__date {
	@include small-caps; }
.post-preview__wrapper-meta {
	a {
		color: $brown-grey; }
	.post-preview__date {
		a {
			color: $sandstone; } } }
.post-preview__wrapper-readmore {
	text-align: right;
	margin-top: 2em; }

@media screen and (max-width: $md) {
	.post-preview__meta {
		margin: 0.25em 0; }
	.post-preview__wrapper-meta {
		margin: 0 0 1em; }
	.post-preview__date {
		width: 100%; } }

/* ======================================================================== */
/* 45. artParallax */
/* ======================================================================== */
[data-art-parallax='image'], [data-art-parallax='background'], {
	position: relative;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover; } }
.art-parallax__bg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center; }

/* ======================================================================== */
/* 46. preloader */
/* ======================================================================== */
.preloader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000; }
.preloader__curtain {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50; }
.preloader__content {
	position: relative;
	width: 160px;
	height: 160px;
	z-index: 50;
	overflow: hidden; }
.preloader__wrapper-logo {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-bottom: 2px solid $borders-dark; }
.preloader__curtain_inner {
	top: 99%;
	animation-name: preloader-load;
	animation-iteration-count: 1;
	animation-duration: 25s;
	transform-origin: left center; }

@keyframes preloader-load {
	from {
		transform: scaleX(0); }
	to {
		transform: scaleX(1); } }

/* ======================================================================== */
/* 47. scroll */
/* ======================================================================== */
.smooth-scroll {
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh; }
[data-os-animation] {
	opacity: 0;
	visibility: hidden; }
[data-os-animation="animated"] {
	opacity: 1;
	visibility: visible; }

/* ======================================================================== */
/* 48. scrollDown */
/* ======================================================================== */
.scroll-down {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	width: 100px;
	&:hover {
		.scroll-down__line {
			transform: scaleY(0);
			transition-delay: 0s; }
		.scroll-down__circle-1 {
			transform: translateY(110px);
			transition-delay: 0.2s; } } }
.scroll-down__circle-1 {
	display: block;
	width: 12px;
	height: 12px;
	background-color: $pinkish-grey;
	border-radius: 100%;
	@include trans2; }
.scroll-down__line {
	width: 1px;
	height: 60px;
	background-color: $pinkish-grey;
	margin-top: 16px;
	margin-bottom: 16px;
	transform-origin: bottom center;
	@include trans2;
	transition-delay: 0.2s; }
.scroll-down__circle-2 {
	width: 24px;
	height: 24px;
	border: 1px solid $brown-grey;
	border-radius: 100%;
	@include trans2; }


@media screen and (max-width: $sm) {
	.scroll-down__circle-1 {
		width: 8px;
		height: 8px; }
	.scroll-down__line {
		height: 40px;
		margin-top: 8px;
		margin-bottom: 8px; }
	.scroll-down__circle-2 {
		width: 18px;
		height: 18px; }
	.scroll-down {
		&:hover {
			.scroll-down__circle-1 {
				transform: translateY(69px); } } } }

/* ======================================================================== */
/* 49. section */
/* ======================================================================== */
.section {
	position: relative;
	z-index: 50; }
.section-fullheight {
	display: flex; }
.section-fullheight__inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 100vh; }
.section__headline {
	display: block;
	width: 100%;
	max-width: 120px;
	height: 1px;
	background-color: $sandstone;
	@include fluid-margin-top($min_width, $max_width, 40, 80); }
.section_z-100 {
	z-index: 100; }
.section__content {
	position: relative;
	z-index: 60; }

@media screen and (max-width: $md) {
	.section__headline {
		max-width: 80px; } }

@media screen and (max-width: $xs) {
	.section__headline {
		max-width: 50px; } }

/* ======================================================================== */
/* 50. sectionHeight */
/* ======================================================================== */
@for $i from 1 through 9 {
	.section_h-#{$i * 100} {
		height: #{$i * 100}px;
		max-height: 100vh; } }
.section_h-100vh {
	height: 100vh; }
.section_w-container-right {
	max-width: 100%;
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px );
	margin-left: auto;
	text-align: left; }
.section_w-container-left {
	max-width: 100%;
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px );
	margin-right: auto;
	text-align: right; }

@media screen and (max-width: $xxl) {
	.section_w-container-right, .section_w-container-left {
		width: 100%;
		text-align: center;
		.section-image__caption-vertical-left, .section-image__caption-vertical-right {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			transform: none;
			padding: 0 20px;
			margin-top: 1em;
			text-align: center;
			width: 100%; } } }

@media screen and (max-width: $sm) {
	@for $i from 1 through 9 {
		.section_h-#{$i * 100} {
			max-height: 70vh; } } }

/* ======================================================================== */
/* 51. sectionMarginsPaddings */
/* ======================================================================== */
.section-offset_bottom {
	z-index: 60; }
.section_pt-small {
	&.section-offset_bottom {
		padding-top: 0;
		.section-offset__content {
			@include fluid-translate-y($min_width, $max_width, $distance-min-small, $distance-max-small); }
		&.section_mb-small {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-small, $distance-max-small); }
		&.section_mb {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
		&.section_mb-large {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-large, $distance-max-large); } } }

.section_pt {
	&.section-offset_bottom {
		padding-top: 0;
		.section-offset__content {
			@include fluid-translate-y($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
		&.section_mb-small {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-small, $distance-max-small); }
		&.section_mb {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
		&.section_mb-large {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-large, $distance-max-large); } } }

.section_pt-large {
	&.section-offset_bottom {
		padding-top: 0;
		.section-offset__content {
			@include fluid-translate-y($min_width, $max_width, $distance-min-normal, $distance-max-large); }
		&.section_mb-small {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-small, $distance-max-small); }
		&.section_mb {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
		&.section_mb-large {
			@include fluid-margin-bottom($min_width, $max_width, $distance-min-large, $distance-max-large); } } }

.section_pb-small {
	&.section-offset_top {
		padding-bottom: 0;
		.section-offset__content {
			@include fluid-translate-y($min_width, $max_width, $distance-min-small, $distance-max-small, -1); }
		&.section_mt-small {
			@include fluid-margin-top($min_width, $max_width, $distance-min-small, $distance-max-small); }
		&.section_mt {
			@include fluid-margin-top($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
		&.section_mt-large {
			@include fluid-margin-top($min_width, $max_width, $distance-min-large, $distance-max-large); } } }

.section_pb {
	&.section-offset_top {
		padding-bottom: 0;
		.section-offset__content {
			@include fluid-translate-y($min_width, $max_width, $distance-min-normal, $distance-max-normal, -1); }
		&.section_mt-small {
			@include fluid-margin-top($min_width, $max_width, $distance-min-small, $distance-max-small); }
		&.section_mt {
			@include fluid-margin-top($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
		&.section_mt-large {
			@include fluid-margin-top($min_width, $max_width, $distance-min-large, $distance-max-large); } } }

.section_pb-large {
	&.section-offset_top {
		padding-bottom: 0;
		.section-offset__content {
			@include fluid-translate-y($min_width, $max_width, $distance-min-large, $distance-max-large, -1); }
		&.section_mt-small {
			@include fluid-margin-top($min_width, $max_width, $distance-min-small, $distance-max-small); }
		&.section_mt {
			@include fluid-margin-top($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
		&.section_mt-large {
			@include fluid-margin-top($min_width, $max_width, $distance-min-large, $distance-max-large); } } }

.section_pt-xsmall {
	@include fluid-padding-top($min_width, $max_width, $distance-min-xsmall, $distance-max-xsmall); }
.section_pt-small {
	@include fluid-padding-top($min_width, $max_width, $distance-min-small, $distance-max-small); }
.section_pt {
	@include fluid-padding-top($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
.section_pt-large {
	@include fluid-padding-top($min_width, $max_width, $distance-min-large, $distance-max-large); }
.section_pt-xlarge {
	@include fluid-padding-top($min_width, $max_width, $distance-min-xlarge, $distance-max-xlarge); }

.section_pb-xsmall {
	@include fluid-padding-bottom($min_width, $max_width, $distance-min-xsmall, $distance-max-xsmall); }
.section_pb-small {
	@include fluid-padding-bottom($min_width, $max_width, $distance-min-small, $distance-max-small); }
.section_pb {
	@include fluid-padding-bottom($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
.section_pb-large {
	@include fluid-padding-bottom($min_width, $max_width, $distance-min-large, $distance-max-large); }
.section_pb-xlarge {
	@include fluid-padding-bottom($min_width, $max_width, $distance-min-xlarge, $distance-max-xlarge); }

.section_mt-xsmall {
	@include fluid-margin-top($min_width, $max_width, $distance-min-xsmall, $distance-max-xsmall); }
.section_mt-small {
	@include fluid-margin-top($min_width, $max_width, $distance-min-small, $distance-max-small); }
.section_mt {
	@include fluid-margin-top($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
.section_mt-large {
	@include fluid-margin-top($min_width, $max_width, $distance-min-large, $distance-max-large); }
.section_mt-xlarge {
	@include fluid-margin-top($min_width, $max_width, $distance-min-xlarge, $distance-max-xlarge); }

.section_mb-xsmall {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-xsmall, $distance-max-xsmall); }
.section_mb-small {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-small, $distance-max-small); }
.section_mb {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-normal, $distance-max-normal); }
.section_mb-large {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-large, $distance-max-large); }
.section_mb-xlarge {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-xlarge, $distance-max-xlarge); }

.section_minus-mt-xsmall {
	@include fluid-margin-top($min_width, $max_width, $distance-min-xsmall, $distance-max-xsmall, -1); }
.section_minus-mt-small {
	@include fluid-margin-top($min_width, $max_width, $distance-min-small, $distance-max-small, -1); }
.section_minus-mt {
	@include fluid-margin-top($min_width, $max_width, $distance-min-normal, $distance-max-normal, -1); }
.section_minus-mt-large {
	@include fluid-margin-top($min_width, $max_width, $distance-min-large, $distance-max-large, -1); }
.section_minus-mt-xlarge {
	@include fluid-margin-top($min_width, $max_width, $distance-min-xlarge, $distance-max-xlarge, -1); }

.section_minus-mb-xsmall {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-xsmall, $distance-max-xsmall, -1); }
.section_minus-mb-small {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-small, $distance-max-small, -1); }
.section_minus-mb {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-normal, $distance-max-normal, -1); }
.section_minus-mb-large {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-large, $distance-max-large, -1); }
.section_minus-mb-xlarge {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-xlarge, $distance-max-xlarge, -1); }

/* ======================================================================== */
/* 52. sectionAbout */
/* ======================================================================== */
.section-about__header {
	position: relative;
	margin-left: -100px;
	@include fluid-margin-bottom($min_width, $max_width, 30, 50); }
.section-about__content {
	@include fluid-margin-top($min_width, $max_width, $distance-min-normal, $distance-max-normal);
	max-width: 550px;
	z-index: 50; }
.section-about__heading {
	margin-top: 0;
	margin-bottom: 0; }
.section-about__headline {
	margin-top: 0;
	@include fluid-margin-bottom($min_width, $max_width, 30, 60); }
.section-about__decor {
	position: absolute;
	top: -200px;
	right: -100px;
	z-index: -1; }
.section-about__wrapper-counter {
	@include fluid-padding-top($min_width, $max_width, 25, 50);
	@include fluid-padding-bottom($min_width, $max_width, 25, 50); }
.section-about__wrapper-content {
	padding-left: 80px;
	padding-right: 80px;
	> *:last-child {
		margin-bottom: 0; } }

@media screen and (max-width: $md) {
	.section-about__content {
		margin-top: 0;
		max-width: 100%; }
	.section-about__header {
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;
		padding-right: 20px; }
	.section-about__wrapper-content {
		padding-left: 20px;
		padding-right: 20px; }
	.section-about__wrapper-img {
		@include fluid-margin-top($min_width, $max_width, 40, 80); } }

/* ======================================================================== */
/* 53. sectionAboutThemes */
/* ======================================================================== */
.section-about {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.section-about__heading {
			color: $white; }
		.counter__number {
			color: $sandstone; }
		.counter__label {
			color: $white; } } }

/* ======================================================================== */
/* 54. sectionAwards */
/* ======================================================================== */
.section-awards__wrapper-item {
	&:first-child {
		.figure-award {
			padding-top: 0; } }
	&:last-child {
		.figure-award {
			padding-bottom: 0; }
		.figure-award__border-line {
			display: none; } } }

/* ======================================================================== */
/* 55. sectionAwardsThemes */
/* ======================================================================== */
.section-awards {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.figure-award__border-line {
			border-color: $borders-dark-low; } } }

/* ======================================================================== */
/* 56. section404 */
/* ======================================================================== */
.section-404__wrapper-button {
	margin-top: 60px; }

/* ======================================================================== */
/* 57. sectionBlog */
/* ======================================================================== */
.section-blog {
	.search-form {
		margin-top: 2em; } }
.section-blog__wrapper-post {
	padding-bottom: 50px;
	margin-bottom: 50px;
	border-bottom: 1px solid $borders-dark;
	&:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: none; } }

@media only screen and (max-width: $sm) {
	.section-blog__wrapper-post {
		padding-bottom: 30px;
		margin-bottom: 30px; } }

/* ======================================================================== */
/* 58. sectionCTA */
/* ======================================================================== */
.section-cta__line {
	width: 1px;
	height: 120px;
	background-color: $sandstone;
	position: absolute;
	top: -60px;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 100; }
.section-cta__heading {
	margin-top: 0; }
.section-cta__wrapper-button {
	@include fluid-margin-top($min_width, $max_width, 30, 50); }

@media screen and (max-width: $md) {
	.section-cta__line {
		height: 60px;
		top: -30px; } }

/* ======================================================================== */
/* 59. sectionCTAThemes */
/* ======================================================================== */
.section-cta {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.section-cta__heading {
			color: $white; } } }

/* ======================================================================== */
/* 60. sectionContactsThemes */
/* ======================================================================== */
.section-contacts {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.figure-contact__icon {
			color: $sandstone; } } }

/* ======================================================================== */
/* 61. sectionContent */
/* ======================================================================== */
.section-content__content {
	margin-bottom: -1em;
	*:first-child:not(.section-content__headline):not(.section-content__subheading) {
		margin-top: 0 !important; } }
.section-content__header {
	*:first-child:not(.section-content__headline):not(.section-content__subheading) {
		margin-top: 0 !important; } }
.section-content__headline {
	margin-top: 14px !important; }
.section-content__header_mb {
	@include fluid-margin-bottom($min_width, $max_width, 0, 40); }
.section-content__headline_right {
	margin-left: auto; }
.section-content__headline_left {
	margin-right: auto; }
.section-content__headline_center {
	margin-left: auto;
	margin-right: auto; }
.section-content__subheading {
	margin-top: 5px; }

@media screen and (max-width: $md) {
	.section-content__content {
		margin-top: 1em;
		margin-bottom: 0;
		*:last-child {
			margin-bottom: 0; } }
	.section-content__header {
		*:last-child {
			margin-bottom: 0; } }
	.section-content__headline {
		margin-top: 0em !important;
		margin-bottom: 0em !important; }
	.section-content__headline_right {
		margin-left: 0;
		margin-right: auto; }
	.section-content__subheading {
		margin-top: 1em !important; } }

/* ======================================================================== */
/* 62. sectionFeatures */
/* ======================================================================== */
.section-features__wrapper-item {
	@include fluid-padding-top($min_width, $max_width, 25, 50);
	@include fluid-padding-bottom($min_width, $max_width, 25, 50); }

/* ======================================================================== */
/* 63. sectionFeaturesThemes */
/* ======================================================================== */
.section-features {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.figure-feature__heading {
			color: $white; } } }

/* ======================================================================== */
/* 64. sectionFullscreenSlider */
/* ======================================================================== */
.section-fullscreen-slider.color-white {
	.slider__dot {
		&:before {
			background-color: $brown-grey; }
		&:after {
			border-color: $white; } }
	.slider__dot_active {
		&:before {
			background-color: $white;
			border-color: $white; } } }

.section-fullscreen-slider__inner {
	padding-top: 0;
	padding-bottom: 0;
	min-height: calc(var(--fix-bar-vh, 1vh) * 100); }

/* ======================================================================== */
/* 65. sectionImage */
/* ======================================================================== */
.section-image {
	display: flex;
	flex-direction: column; }
.section-image__content {
	margin-top: 40px; }
.section-image__wrapper {
	position: relative;
	width: 100%;
	height: 100%; }
.section-image__caption {
	display: inline-block;
	font-weight: bold;
	margin-top: 1em; }
.section-image__caption-vertical-left {
	position: absolute;
	bottom: -1em;
	left: -2em;
	transform: rotate(-90deg);
	transform-origin: left center;
	text-align: left; }
.section-image__caption-vertical-right {
	position: absolute;
	top: -2em;
	right: -2em;
	transform: rotate(-90deg);
	transform-origin: right center;
	text-align: left; }
.section-image__wrapper_absolute {
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
.section-image__overlay {
	z-index: 0; }

@media screen and (max-width: $xl) {
	.section-image__caption-vertical-left {
		left: -1.5em; }
	.section-image__caption-vertical-right {
		right: -1.5em; } }
@media screen and (max-width: $md) {
	.section-image__caption-vertical-left, .section-image__caption-vertical-right {
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		transform: none;
		padding: 0 20px;
		margin-top: 1em;
		text-align: center;
		width: 100%; } }

/* ======================================================================== */
/* 66. sectionIntro */
/* ======================================================================== */
.section-intro__heading {
	@include fluid-type($min_width, $max_width, 58, 107);
	margin-bottom: 0.4em !important; }

@media screen and (max-width: $md) {
	.section-intro__heading {
		margin-bottom: 0.25em !important; } }

/* ======================================================================== */
/* 67. sectionLatestPostsThemes */
/* ======================================================================== */
.section-latest-posts {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.figure-post__heading {
			color: $white; } } }

/* ======================================================================== */
/* 68. sectionNavProjects */
/* ======================================================================== */
.section-nav-projects__inner {
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	@include fluid-padding-top($min_width, $max_width, $distance-min-small, $distance-max-small);
	@include fluid-padding-bottom($min_width, $max_width, $distance-min-small, $distance-max-small);
	overflow: hidden; }
.section-nav-projects__inner_all {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center; }
.section-nav-projects__inner_prev {
	text-align: left;
	justify-content: flex-start; }
.section-nav-projects__inner_next {
	text-align: right;
	justify-content: flex-end; }
.section-nav-projects__arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: inline-block;
	font-size: 32px !important; }
.section-nav-projects__arrow_prev {
	left: 120px; }
.section-nav-projects__arrow_next {
	right: 120px; }
.section-nav-projects__heading {
	font-family: $font-secondary;
	@include fluid-type($min_width, $max-width, 34, 52);
	font-weight: bold;
	line-height: 1.28;
	margin-bottom: 0.25em; }
.section-nav-projects__wrapper-heading_prev {
	padding-right: 20px;
	padding-left: 120px; }
.section-nav-projects__wrapper-heading_next {
	padding-left: 20px;
	padding-right: 120px; }

@media screen and (max-width: $xxl) {
	.section-nav-projects__arrow_prev {
		left: 70px; }
	.section-nav-projects__arrow_next {
		right: 70px; }
	.section-nav-projects__wrapper-heading_prev {
		padding-left: 80px; }
	.section-nav-projects__wrapper-heading_next {
		padding-right: 80px; } }

@media screen and (max-width: $xl) {
	.section-nav-projects__arrow_prev {
		left: 40px; }
	.section-nav-projects__arrow_next {
		right: 40px; }
	.section-nav-projects__wrapper-heading_prev {
		padding-left: 50px; }
	.section-nav-projects__wrapper-heading_next {
		padding-right: 50px; } }

@media screen and (max-width: $md) {
	.section-nav-projects__arrow_prev {
		left: 10px; }
	.section-nav-projects__arrow_next {
		right: 10px; }
	.section-nav-projects__wrapper-heading_prev {
		padding-left: 20px; }
	.section-nav-projects__wrapper-heading_next {
		padding-right: 20px; } }

@media screen and (max-width: $sm) {
	.section-nav-projects__inner_prev {
		text-align: center;
		justify-content: center; }
	.section-nav-projects__inner_next {
		text-align: center;
		justify-content: center; }
	.section-nav-projects__inner:not(:last-child) {
		border-bottom: 1px solid $borders-light; }
	.section-nav-projects__arrow {
		position: relative;
		top: auto;
		right: auto;
		left: auto;
		transform: none;
		margin-top: 0.25em;
		font-size: 24px !important; }
	.section-nav-projects__inner {
		flex-direction: column; } }

/* ======================================================================== */
/* 69. sectionNavProjectsThemes */
/* ======================================================================== */
.section-nav-projects {
	&.bg-white {
		.section-nav-projects__inner {
			color: $dusk;
			&:hover {
				background-color: $light; } }
		.button-circles__circle svg .circle {
			stroke: $dusk; } }
	/////////////////////////////////////////////
	&.bg-off-white {
		.section-nav-projects__inner {
			color: $pinkish-grey;
			&:hover {
				background-color: $white; } } }
	/////////////////////////////////////////////
	&.bg-light {
		.section-nav-projects__inner {
			color: $pinkish-grey;
			&:hover {
				background-color: $off-white; } } }
	/////////////////////////////////////////////
	&.bg-black {
		.section-nav-projects__inner {
			&:hover {
				background-color: $dark; } } }
	/////////////////////////////////////////////
	&.bg-dark {
		.section-nav-projects__inner {
			&:hover {
				background-color: $dark2; } } }
	/////////////////////////////////////////////
	&.bg-dark-2 {
		.section-nav-projects__inner {
			&:hover {
				background-color: $dark3; } } }
	/////////////////////////////////////////////
	&.bg-dark-3 {
		.section-nav-projects__inner {
			&:hover {
				background-color: $dark2; } } }
	/////////////////////////////////////////////
	&.bg-accent-primary-1 {
		.section-nav-projects__inner {
			color: $white;
			&:hover {
				background-color: $dark3; } }
		.button-circles__circle svg .circle {
			stroke: $white; }
		.button-circles__circle {
			border-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-2 {
		.section-nav-projects__inner {
			color: $pinkish-grey;
			&:hover {
				background-color: $off-white; } } }
	/////////////////////////////////////////////
	&.bg-accent-primary-3 {
		.section-nav-projects__inner {
			color: $sandstone;
			&:hover {
				background-color: $light; } } }
	/////////////////////////////////////////////
	&.bg-accent-primary-4 {
		.section-nav-projects__inner {
			color: $white;
			&:hover {
				background-color: $dark3; } }
		.button-circles__circle svg .circle {
			stroke: $white; }
		.button-circles__circle {
			border-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-1 {
		.section-nav-projects__inner {
			color: $white;
			&:hover {
				background-color: $dusk; } }
		.button-circles__circle svg .circle {
			stroke: $white; }
		.button-circles__circle {
			border-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-2 {
		.section-nav-projects__inner {
			color: $white;
			&:hover {
				background-color: $grey-blue; } }
		.button-circles__circle svg .circle {
			stroke: $white; }
		.button-circles__circle {
			border-color: $white; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-3 {
		.section-nav-projects__inner {
			color: $light;
			&:hover {
				background-color: $dusk; } }
		.button-circles__circle svg .circle {
			stroke: $light; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-4 {
		.section-nav-projects__inner {
			color: $sandstone;
			&:hover {
				background-color: $dark-blue; } }
		.button-circles__circle svg .circle {
			stroke: $sandstone; } } }

/* ======================================================================== */
/* 70. sectionPortfolio */
/* ======================================================================== */
.section-portfolio__wrapper-tabs {
	@include fluid-margin-bottom($min_width, $max_width, $distance-min-small, $distance-max-small); }

/* ======================================================================== */
/* 71. sectionMasthead */
/* ======================================================================== */
.section-masthead {
	overflow: hidden; }
.section-masthead__header {
	h1 {
		margin-bottom: 0.75em; } }
.section-masthead__background_fullscreen {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%; }
.section-masthead__overlay {
	z-index: 0; }
.section-masthead__inner {
	position: relative;
	z-index: 50; }
.section-masthead__wrapper-scroll-down {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	text-align: center;
	z-index: 50; }
.section-masthead__inner_background {
	width: 760px;
	max-width: 50vw;
	padding-left: 50px;
	padding-right: 50px;
	background: none; }
.section-masthead__inner_background-left {
	margin-right: auto;
	margin-left: 0;
	padding-left: 120px;
	padding-right: 30px;
	.section-masthead__wrapper-scroll-down {
		left: 80px;
		right: auto; } }
.section-masthead__inner_background-right {
	margin-left: auto;
	margin-right: 0;
	padding-left: 120px;
	padding-right: 30px;
	.section-masthead__wrapper-scroll-down {
		left: 80px;
		right: auto; } }
.section-masthead__background-right {
	left: 760px; }
.section-masthead__background-left {
	right: 760px; }
.section-masthead__meta {
	display: inline-flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0 -1em 1em;
	max-width: 100%;
	.post-preview__meta {
		margin: 1em;
		max-width: 100%;
		@include fluid-type($min_width, $max_width, 13, 16);
		a {
			color: $brown-grey; } }
	.post-preview__date {
		a {
			color: $sandstone; } } }
.section-masthead__curtain {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1; }
.section-masthead_big-heading {
	top: 0;
	left: 0;
	right: 0;
	z-index: 100; }
.section-masthead__heading-big {
	margin-top: 0;
	margin-bottom: 0;
	color: $brown-grey;
	opacity: .3; }
.section-masthead__wrapper-button {
	margin-top: 2em; }

@media screen and (max-width: $xxl) {
	.section-masthead__inner_background-left {
		padding-left: 80px;
		.section-masthead__wrapper-scroll-down {
			left: 40px; } }
	.section-masthead__inner_background-right {
		padding-left: 80px;
		.section-masthead__wrapper-scroll-down {
			left: 40px; } } }

@media screen and (max-width: $xl) {
	.section-masthead__inner_background-left {
		padding-left: 60px;
		.section-masthead__wrapper-scroll-down {
			left: 20px; } }
	.section-masthead__inner_background-right {
		padding-left: 60px;
		.section-masthead__wrapper-scroll-down {
			left: 20px; } } }

@media screen and (max-width: $md) {
	.section-masthead__meta {
		.post-preview__meta {
			// display: block
			margin: 0.5em; }
		.post-preview__wrapper-meta {
			margin: -0.5em -0.5em 0.5em; }
		.post-preview__date {
			display: block;
			width: 100%; } }
	.section-masthead__curtain {
		opacity: .8;
		mix-blend-mode: multiply; }
	.section-masthead__inner_background {
		max-width: 100vw;
		width: 100%; }
	.section-masthead__inner_background-left {
		padding-left: 20px;
		.section-masthead__wrapper-scroll-down {
			left: 20px;
			right: 20px;
			margin: 0 auto; } }
	.section-masthead__inner_background-right {
		padding-left: 20px;
		.section-masthead__wrapper-scroll-down {
			left: 20px;
			right: 20px;
			margin: 0 auto; } } }

@media screen and (max-width: $sm) {
	.section-masthead__inner_background {
		padding-left: 20px;
		padding-right: 20px; }
	.section-masthead__wrapper-scroll-down {
		bottom: 20px; } }

/* ======================================================================== */
/* 72. sectionProperties */
/* ======================================================================== */
@media screen and (max-width: $md) {
	.section-properties__item {
		@include fluid-padding-top($min_width, $md, $distance-min-small / 3, $distance-max-small / 2);
		@include fluid-padding-bottom($min_width, $md, $distance-min-small / 3, $distance-max-small / 2); } }

@media screen and (max-width: $xs) {
	.section-properties__item {
		&:first-of-type {
			padding-top: 0; }
		&:last-of-type {
			padding-bottom: 0; } } }

/* ======================================================================== */
/* 73. sectionServices */
/* ======================================================================== */
.section-services {
	background: none; }
.section-services__inner {
	position: relative; }
.section-services__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - ( (100vw - 1140px) / 2) - 20px);
	height: 100%; }
.section-services__counter {
	@include small-caps; }
.section-services__wrapper-item {
	position: relative;
	@include fluid-padding-top($min_width, $max_width, 40, 120);
	@include fluid-padding-bottom($min_width, $max_width, 40, 120);
	z-index: 50;
	&:not(:last-child) {
		.section-services__border-line {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $borders-dark;
			width: 100%;
			height: 1px;
			display: none; } } }

@media screen and (max-width: $md) {
	.section-services__wrapper-item {
		&:not(:last-child) {
			.section-services__border-line {
				display: block;
				position: absolute;
				bottom: 0;
				left: 20px;
				right: 20px;
				background-color: $borders-dark;
				width: auto;
				height: 1px; } } }
	.section-services__heading {
		margin-top: 0.75em;
		margin-bottom: 0.75em; } }

@media screen and (max-width: 1140px) {
	.section-services__bg {
		width: 100%; } }

/* ======================================================================== */
/* 74. sectionServicesThemes */
/* ======================================================================== */
.section-services {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.section-services__heading {
			color: $white; }
		.section-services__link {
			color: $pinkish-grey;
			&:hover {
				color: $sandstone; } } } }

/* ======================================================================== */
/* 75. sectionSliderThemes */
/* ======================================================================== */
.section-slider {
	/////////////////////////////////////////////
	&.bg-dark-3 {
		.slider__counter_current {
			color: $sandstone; }
		.slider__dot {
			.circle {
				stroke: $sandstone; } } }
	/////////////////////////////////////////////
	&.bg-accent-primary-1 {
		.slider__dot {
			border-color: $borders-light;
			.circle {
				stroke: $white; } }
		.slider__arrow {
			color: $white; }
		.slider__arrows-divider, .slider__counter-divider {
			background-color: $borders-light; }
		.slider__counter_current {
			color: $white; }
		.slider__counter_total {
			color: $dark; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-2 {
		.slider__counter_current {
			color: $sandstone; }
		.slider__dot {
			.circle {
				stroke: $sandstone; } } }
	/////////////////////////////////////////////
	&.bg-accent-primary-3 {
		.slider__dot {
			.circle {
				stroke: $white; } }
		.slider__arrow {
			color: $white; }
		.slider__counter_current {
			color: $white; }
		.slider__counter_total {
			color: $dark; } }
	/////////////////////////////////////////////
	&.bg-accent-primary-4 {
		.slider__dot {
			border-color: $borders-light;
			.circle {
				stroke: $white; } }
		.slider__arrow {
			color: $white; }
		.slider__arrows-divider, .slider__counter-divider {
			background-color: $borders-light; }
		.slider__counter_current {
			color: $white; }
		.slider__counter_total {
			color: $dark; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-1 {
		.slider__dot {
			border-color: $borders-light;
			.circle {
				stroke: $white; } }
		.slider__arrow {
			color: $white; }
		.slider__arrows-divider, .slider__counter-divider {
			background-color: $borders-light; }
		.slider__counter_current {
			color: $white; }
		.slider__counter_total {
			color: $dark; } }
	/////////////////////////////////////////////
	&.bg-accent-secondary-3 {
		.slider__counter_current {
			color: $sandstone; }
		.slider__dot {
			.circle {
				stroke: $sandstone; } } }

	/////////////////////////////////////////////
	&.bg-accent-secondary-4 {
		.slider__counter_current {
			color: $sandstone; }
		.slider__dot {
			.circle {
				stroke: $sandstone; } } } }

/* ======================================================================== */
/* 76. sectionVideo */
/* ======================================================================== */
.section-video {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%; }
.section-video__link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	z-index: 100;
	&:hover {
		border-color: $sandstone;
		color: $sandstone; }
	&:focus {
		opacity: 1; } }
.section-video__icon {
	position: relative;
	display: inline-flex !important;
	align-items: center;
	justify-content: center;
	width: 160px;
	height: 160px;
	border-radius: 100%;
	border: 2px solid $borders-dark;
	@include small-caps;
	font-weight: bold;
	color: $white;
	@include trans1;
	&:hover {
		color: $sandstone; }
	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: auto;
		width: 160px;
		height: 160px;
		border-radius: 100%;
		stroke-width: 1px;
		z-index: 50;
		.circle {
			stroke: $sandstone; } } }
.section-video__overlay {
	z-index: 0; }

@media screen and (max-width: $sm) {
	.section-video__icon {
		width: 120px;
		height: 120px;
		svg {
			width: 120px;
			height: 120px; } } }

/* ======================================================================== */
/* 77. select */
/* ======================================================================== */
select {
	display: block;
	border: 1px solid $borders-dark;
	padding: 10px 35px 10px 15px;
	background-color: transparent;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url('../img/general/select.png');
	background-position: right 15px bottom 50%;
	background-repeat: no-repeat;
	background-size: 6px 4px; }

/* ======================================================================== */
/* 78. sectionTeam */
/* ======================================================================== */
.section-team {
	@include fluid-margin-top($min_width, $max_width, 25, 30, -1);
	@include fluid-margin-bottom($min_width, $max_width, 25, 30, -1); }

.section-team__wrapper-item {
	@include fluid-padding-top($min_width, $max_width, 25, 50);
	@include fluid-padding-bottom($min_width, $max_width, 25, 50); }

/* ======================================================================== */
/* 79. sectionTeamThemes */
/* ======================================================================== */
.section-team {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.figure-member__heading {
			color: $white; }
		.social__item a {
			color: $white;
			&:hover {
				color: $sandstone; } } } }

/* ======================================================================== */
/* 80. sectionTestimonialsThemes */
/* ======================================================================== */
.section-testimonials {
	&.bg-dark, &.bg-dark-2, &.bg-dark-3 {
		.slider-testimonials__text {
			color: $white; } } }

/* ======================================================================== */
/* 81. sidebar */
/* ======================================================================== */
.sidebar_no-margin-last-widget {
	.widget {
		&:last-child {
			margin-bottom: 0; } } }
.sidebar {
	.widget {
		margin-bottom: 50px; }
	.search-form {
		margin-top: 0; } }

@media only screen and (max-width: $md) {
	.sidebar {
		margin-top: 80px; } }

@media only screen and (max-width: $sm) {
	.sidebar {
		margin-top: 60px; } }

/* ======================================================================== */
/* 82. slider */
/* ======================================================================== */
.slider:not(.container) {
	max-width: 100%; }
.slider_offset-top {
	margin-top: -200px;
	padding-bottom: 200px; }
.slider__bg {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat; }
.slider__images-slide-inner {
	transition: transform 2.4s ease;
	transform: scale(1.05);
	width: 100%;
	height: 100%; }
.slider__images-slide {
	overflow: hidden;
	&.swiper-slide-active {
		.slider__images-slide-inner {
			transform: scale(1); } }
	.swiper-slide {
		&:not(.swiper-slide-active):not(.swiper-slide-prev):not(.swiper-slide-next) {
			.slider__images-slide-inner {
				display: none !important; } } } }
.slider__counter {
	@include small-caps;
	height: 24px; }
.slider__counter_current {
	color: $pinkish-grey; }
.slider__counter_total {
	color: $brown-grey; }
.slider__link {
	display: block; }
.slider__progress {
	display: inline-flex;
	align-items: center; }
.slider__progressbar {
	position: relative;
	width: 180px;
	height: 1px;
	background: $brown-grey; }
.slider__progressbar-fill {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: $dark;
	transform-origin: left center;
	@include trans1; }

.slider__dot {
	position: relative;
	width: 14px;
	height: 14px;
	margin: 0 8px;
	cursor: pointer;
	outline: none;
	display: inline-block;
	border: 2px solid $borders-dark;
	border-radius: 100%;
	vertical-align: middle;
	z-index: 50;
	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: auto;
		width: 14px;
		height: 14px;
		border-radius: 100%;
		stroke-width: 42px;
		z-index: 50;
		.circle {
			stroke: $sandstone; } } }
.slider__dots {
	display: inline-flex;
	height: 24px; }
.slider__arrows {
	display: inline-flex;
	align-items: center; }
.slider__arrows-divider, .slider__counter-divider {
	width: 1px;
	height: 18px;
	background-color: $borders-dark;
	transform: rotate(45deg);
	margin-left: 40px;
	margin-right: 40px; }

.slider__arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	@include trans1;
	outline: none;
	width: 24px;
	height: 24px;
	color: $brown-grey;
	i {
		font-size: 24px; }
	&.swiper-button-disabled {
		opacity: 0.3;
		color: $brown-grey; }
	&:hover {
		color: $pinkish-grey; } }
.slider__footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 80px;
	padding-left: 0;
	padding-right: 0;
	z-index: 50; }
.slide__arrow_prev_centered {
	position: absolute;
	top: 50%;
	left: 0;
	padding-left: 120px;
	transform: translateY(-50%);
	z-index: 50; }
.slide__arrow_next_centered {
	position: absolute;
	top: 50%;
	right: 0;
	padding-right: 120px;
	transform: translateY(-50%);
	z-index: 50; }
.slider__footer_half {
	width: 50%; }
.slider__footer_left {
	margin-right: auto; }
.slider__footer_right {
	margin-left: auto; }
.swiper-lazy {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.6s ease; }
.swiper-lazy-loaded {
	opacity: 1;
	visibility: visible; }
.slider__footer-relative {
	padding-top: 30px; }
.slider__overlay {
	z-index: 1; }
.slider__overlay_solid {
	opacity: 1;
	mix-blend-mode: normal; }
.slider__progress {
	vertical-align: top; }

@media screen and (max-width: $xxl) {
	.slider__footer {
		padding-bottom: 60px; }
	.slide__arrow_prev_centered {
		padding-left: 80px; }
	.slide__arrow_next_centered {
		padding-right: 80px; } }

@media screen and (max-width: $xl) {
	.slider__footer {
		padding-bottom: 40px; }
	.slide__arrow_prev_centered {
		padding-left: 50px; }
	.slide__arrow_next_centered {
		padding-right: 50px; } }

@media screen and (max-height: 800px) and (min-width: $md) {
	.slider__footer {
		padding-bottom: 40px; } }

@media screen and (max-width: $md) {
	.slider__footer {
		padding-bottom: 20px; }
	.slider__footer_halfscreen {
		padding-top: 20px;
		padding-bottom: 0;
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto; }
	.slide__arrow_prev_centered {
		padding-left: 30px; }
	.slide__arrow_next_centered {
		padding-right: 30px; }
	.slider-halfscreen__container {
		padding-left: 0;
		padding-right: 0; } }

@media screen and (max-width: $sm) {
	.slider__footer-relative {
		padding-top: 20px; }
	.slider__footer-col {
		padding-top: 10px;
		&.text-left, &.text-center, &.text-right {
			text-align: center !important; } }
	.slider__arrows-divider, .slider__counter-divider {
		margin-left: 20px;
		margin-right: 20px; }
	.slider__counter {
		height: 18px; }
	.slider__arrow {
		width: 18px;
		height: 18px;
		i {
			font-size: 18px; } }
	.slider__progress, .slider__arrows, .slider__dots {
		vertical-align: top; }
	.slider__dots {
		height: 18px; } }

/* ======================================================================== */
/* 83. sliderHalfscreen */
/* ======================================================================== */
.slider-halfscreen {
	max-height: 100vh;
	height: 100%;
	overflow: hidden;
	padding-top: 150px;
	padding-bottom: 160px; }
.slider-halfscreen_fullheight {
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
	padding-left: 0; }
.slider-halfscreen__header {
	p {
		max-width: 600px; } }
.slider-halfscreen__wrapper-button {
	@include fluid-margin-top($min_width, $max_width, 30, 50); }
.slider-halfscreen__arrows_centered {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	z-index: 50; }
.slider-halfscreen__content_padding {
	padding: 120px; }


@media screen and (max-width: $xxl) {
	.slider-halfscreen:not(.slider-halfscreen_fullheight) {
		padding-top: 120px;
		padding-bottom: 130px; }
	.slider-halfscreen__content_padding {
		padding: 80px; } }

@media screen and (max-width: $xl) {
	.slider-halfscreen:not(.slider-halfscreen_fullheight) {
		padding-top: 100px;
		padding-bottom: 110px; }
	.slider-halfscreen__content_padding {
		padding: 60px; } }

@media screen and (max-height: 800px) and (min-width: $md) {
	.slider-halfscreen:not(.slider-halfscreen_fullheight) {
		padding-top: 100px;
		padding-bottom: 110px; } }

@media screen and (max-width: $md) {
	.slider-halfscreen, .slider-halfscreen:not(.slider-halfscreen_fullheight) {
		@include fluid-padding-top($min_width, $max_width, $distance-min-large, $distance-max-large);
		padding-bottom: 20px;
		padding-left: 0;
		padding-right: 0;
		max-height: unset;
		height: auto !important; }
	.slider-halfscreen__images {
		height: 33vh !important; }
	.slider-halfscreen__content {
		margin-bottom: 40px;
		@include fluid-margin-bottom($min_width, $md, 40, 50);
		padding: 0 20px; }
	.slider__footer_half {
		width: 100%; } }

/* ======================================================================== */
/* 84. sliderFullscreen */
/* ======================================================================== */
.slider-fullscreen {
	position: relative;
	// width: 100vw
	// height: 100vh
	height: calc(var(--fix-bar-vh, 1vh) * 100); }
.slider-fullscreen__header {
	h1 {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0; } }
.slider-fullscreen__images {
	position: relative;
	width: 100%;
	height: 100%;
	// position: absolute
	// top: 0
	// left: 0
	// width: 100%
	// height: 100%
 }	// z-index: -1
.slider-fullscreen__images-slide {
	will-change: transform; }
.slider-fullscreen__content {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	// text-align: center
	padding-bottom: 80px;
	z-index: 60; }
.slider-fullscreen__content_centered {
	top: 50%;
	transform: translateY(-50%);
	bottom: auto;
	padding-bottom: 0; }
.slider-fullscreen__footer {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	z-index: 50; }
.slider-fullscreen__footer-inner {
	position: relative;
	height: 100px; }
.slider-fullscreen__footer-divider {
	position: absolute;
	top: 50%;
	left: 0;
	background-color: rgba(255, 255, 255, .1);
	width: 100%;
	height: 1px;
	transform: translateY(-50%); }
.slider-fullscreen__arrows {
	flex-direction: column;
	justify-content: space-between;
	height: 100%; }
.slider-fullscreen__progress {
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 50px; }
.slider-fullscreen__counter-divider {
	position: absolute;
	top: -1px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto !important;
	transform: none !important;
	width: 50px !important;
	height: 1px !important;
	transform: none !important; }
.slider-fullscreen__wrapper-button {
	@include fluid-margin-top($min_width, $max_width, 20, 40);
	// overflow: hidden
	// a
	// 	display: inine-block
 }	// 	color: $white

@media screen and (max-width: $xxl) {
	.slider-fullscreen__content {
		padding-bottom: 60px; } }

@media screen and (max-width: $xl) {
	.slider-fullscreen__content {
		padding-bottom: 25px; } }

@media screen and (max-width: $md) {
	.slider-fullscreen {
 } }		// height: auto

/* ======================================================================== */
/* 85. sliderImages */
/* ======================================================================== */
.slider-images {
	display: flex;
	flex-wrap: wrap;
	width: 100%; }
.slider-images_footer-bottom {
	.slider-images__footer {
		padding-top: 30px;
		order: 1; } }
.slider-images_footer-top {
	.slider-images__footer {
		padding-bottom: 30px;
		order: -1; } }
.slider-images__caption {
	font-weight: bold;
	width: 100%; }
.slider-images__slider-captions {
	height: 28px; }

@media screen and (max-width: $md) {
	.slider-images_footer-bottom {
		.slider-images__footer {
			padding-top: 10px; } }
	.slider-images_footer-top {
		.slider-images__footer {
			padding-bottom: 10px; } } }

/* ======================================================================== */
/* 86. sliderLetters */
/* ======================================================================== */
.slider-letters {
	width: 100%;
	height: 100%; }
.vector-letter:not(:first-child) {
	visibility: hidden; }
svg.vector-letters {
	height: 130px; }

/* ======================================================================== */
/* 87. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials__text {
	font-weight: 300;
	font-style: italic;
	margin-top: 0; }
.slider-testimonials__author {
	display: flex;
	align-items: center;
	@include small-caps;
	color: $pinkish-grey;
	margin-top: 3em; }
.slider-testimonials__author-line {
	width: 60px;
	height: 1px;
	background-color: $sandstone;
	margin-right: 2em; }
.slider-testimonials__footer {
	margin-top: 30px; }

/* ======================================================================== */
/* 88. social */
/* ======================================================================== */
.social {
	@include reset-ul;
	margin-left: -16px;
	margin-right: -16px; }
.social__item {
	display: inline-block;
	margin: 0 8px;
	a {
		color: $brown-grey;
		font-size: 13px;
		padding: 8px;
		&:hover {
			color: $sandstone; } } }

/* ======================================================================== */
/* 89. sliderProjects */
/* ======================================================================== */
.slider-projects__footer {
	padding-top: 30px; }

/* ======================================================================== */
/* 90. splitText */
/* ======================================================================== */
.elementor-editor-active {
	[data-os-animation] {
		.split-text {
			opacity: 1;
			visibility: visible; } } }
.clone {
	.split-text__line {
		position: static !important; }
	.split-text__word {
		position: static !important; } }

/* ======================================================================== */
/* 91. transitionCurtain */
/* ======================================================================== */
.transition-curtain {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 400;
	display: none;
	transition: background-color 0.6s ease-in-out;
	will-change: background-color; }

/* ======================================================================== */
/* 92. tags */
/* ======================================================================== */
.tagcloud {
	ul {
		@include reset-ul;
		li {
			display: inline-block !important;
			margin-bottom: 0 !important;
			a {
				display: inline-block;
				font-size: 13px;
				color: $dusk !important;
				background-color: $off-white;
				padding: 7px 15px;
				margin-bottom: 6px;
				margin-right: 4px;
				border-radius: 100px;
				&:hover {
					background-color: $dusk;
					color: $white !important; } } } } }

/* ======================================================================== */
/* 93. utilities */
/* ======================================================================== */
.text-left {
	text-align: left !important;
	.section__headline {
		margin-right: auto; } }
.text-center {
	text-align: center !important;
	.section__headline {
		margin-left: auto;
		margin-right: auto; } }
.text-right {
	text-align: right !important;
	.section__headline {
		margin-left: auto; } }
.row-80 {
	margin-left: -40px;
	margin-right: -40px; }
.overflow {
	position: relative;
	overflow: hidden; }
.overflow__curtain {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50; }
.overflow__content {
	width: 100%;
	height: 100%; }
.position-relative {
	position: relative; }
.position-fixed {
	position: fixed; }
.of-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	font-family: 'object-fit: cover;'; }
.of-contain {
	width: 100%;
	height: 100%;
	object-fit: contain;
	font-family: 'object-fit: contain;'; }
.split-line {
	overflow: hidden; }
.hidden {
	opacity: 0;
	visibility: hidden; }
.no-gutters {
	padding-left: 0;
	padding-right: 0; }
.grayscale {
	filter: grayscale(70%); }
.h-100 {
	height: 100%; }
.clearfix:after {
	content: '';
	display: table;
	clear: both; }
.backgroundblendmode {
	.blend-difference {
		mix-blend-mode: difference;
		.header__burger-line {
			background-color: $white; }
		.logo {
			color: $white; }
		.menu > li > a {
			color: $white; } } }
.border-radius {
	border-radius: $brdr; }
.bg-ornament {
	background-image: url('../img/general/bg-ornament.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover; }
.bg-dots {
	width: 256px;
	height: 400px;
	background-image: url('../img/general/bg-dots.png');
	background-repeat: no-repeat;
	background-position: center center; }
.mw-960 {
	max-width: 960px; }

/* ======================================================================== */
/* 94. typography */
/* ======================================================================== */
h1, h2, h3, h4, h5, h6 {
	font-family: $font-secondary;
	color: $dusk; }
h1, h2, h3 {
	margin-top: 0.5em;
	margin-bottom: 0.5em; }
h4, h5, h6 {
	margin-top: 0.75em;
	margin-bottom: 0.75em; }
h1 {
	@include fluid-type($min_width, $max_width, $h1-min-font-size, $h1-max-font-size);
	font-weight: bold;
	line-height: $h1-line-height;
	margin-top: 0; }
h2 {
	@include fluid-type($min_width, $max_width, $h2-min-font-size, $h2-max-font-size);
	font-weight: bold;
	line-height: $h2-line-height; }
h3 {
	@include fluid-type($min_width, $max_width, $h3-min-font-size, $h3-max-font-size);
	font-weight: bold;
	line-height: $h3-line-height; }
h4 {
	@include fluid-type($min_width, $max_width, $h4-min-font-size, $h4-max-font-size);
	font-weight: bold;
	line-height: $h4-line-height; }
h5 {
	@include fluid-type($min_width, $max_width, $h5-min-font-size, $h5-max-font-size);
	font-weight: bold;
	line-height: $h5-line-height; }
h6 {
	@include fluid-type($min_width, $max_width, $h6-min-font-size, $h6-max-font-size);
	font-weight: bold;
	line-height: $h6-line-height; }
.h1 {
	@include fluid-type($min_width, $max_width, $h1-min-font-size, $h1-max-font-size);
	font-weight: bold;
	line-height: $h1-line-height;
	margin-top: 0; }
.h2 {
	@include fluid-type($min_width, $max_width, $h2-min-font-size, $h2-max-font-size);
	font-weight: bold;
	line-height: $h2-line-height; }
.h3 {
	@include fluid-type($min_width, $max_width, $h3-min-font-size, $h3-max-font-size);
	font-weight: bold;
	line-height: $h3-line-height; }
.h4 {
	@include fluid-type($min_width, $max_width, $h4-min-font-size, $h4-max-font-size);
	font-weight: bold;
	line-height: $h4-line-height; }
.h5 {
	@include fluid-type($min_width, $max_width, $h5-min-font-size, $h5-max-font-size);
	font-weight: bold;
	line-height: $h5-line-height; }
.h6 {
	@include fluid-type($min_width, $max_width, $h6-min-font-size, $h6-max-font-size);
	font-weight: bold;
	line-height: $h6-line-height; }
p {
	margin-top: 1em;
	margin-bottom: 1em;
	@include fluid-type($min_width, $max_width, $paragraph-min-font-size, $paragraph-max-font-size);
	line-height: $paragraph-line-height; }
strong, b {
	font-weight: bold; }
small {
	display: block;
	font-size: 13px;
	line-height: 1.5;
	color: $brown-grey; }
.subheading {
	@include small-caps;
	@include fluid-type($min_width, $max_width, $subheading-min-font-size, $subheading-max-font-size);
	line-height: $subheading-line-height;
	margin-bottom: 1em; }
.heading-light {
	font-weight: normal; }
blockquote {
	display: block;
	background-color: $off-white;
	color: $dusk;
	font-family: $font-secondary;
	@include fluid-type($min_width, $max_width, $blockquote-min-font-size, $blockquote-max-font-size);
	line-height: $blockquote-line-height;
	font-style: italic;
	padding: 3em;
	margin-top: 2em;
	margin-bottom: 2em;
	p {
		margin-top: 0;
		@include fluid-type($min_width, $max_width, $blockquote-min-font-size, $blockquote-max-font-size); } }
cite {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: $font-primary;
	font-style: normal;
	@include fluid-type($min_width, $max_width, $paragraph-min-font-size, $paragraph-max-font-size);
	margin-top: 1em;
	color: $sandstone;
	&:before {
		content: '';
		display: block;
		width: 50px;
		height: 1px;
		background-color: $sandstone;
		margin-right: 1em; } }
.text-xl {
	@include fluid-type($min_width, $max_width, $text-xl-min-font-size, $text-xl-max-font-size);
	line-height: $text-xl-line-height; }
.text-xxl {
	@include fluid-type($min_width, $max_width, $text-xxl-min-font-size, $text-xxl-max-font-size);
	line-height: $text-xxl-line-height; }

@media only screen and (max-width: $md) {
	h1, .h1 {
		margin-bottom: 1em; }
	h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
		margin-top: 1em;
		margin-bottom: 1em; }
	blockquote {
		padding: 2em; } }

/* ======================================================================== */
/* 95. typographyAccentSecondaryAdjustments */
/* ======================================================================== */
.bg-accent-secondary-1 {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pale; }
	.section__headline {
		background-color: $pale; } }
// /////////////////////////////////////////////
.bg-accent-secondary-2 {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pinkish-grey; } }
// /////////////////////////////////////////////
.bg-accent-secondary-3 {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pinkish-grey; } }
// /////////////////////////////////////////////
.bg-accent-secondary-4 {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pinkish-grey; } }

/* ======================================================================== */
/* 96. typographyDarkAdjustments */
/* ======================================================================== */
.bg-black {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pinkish-grey; } }
////////////////////////////////////////////////
.bg-dark {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pinkish-grey; } }
////////////////////////////////////////////////
.bg-dark-2 {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pinkish-grey; } }
////////////////////////////////////////////////
.bg-dark-3 {
	h1, h2, h3, h4, h5, h6, .section-nav-projects__heading {
		color: $pinkish-grey; } }

/* ======================================================================== */
/* 97. widget */
/* ======================================================================== */
.widget {
	p {
		&:last-of-type {
			margin-bottom: 0; } }
	select {
		width: 100%;
		max-width: 100%; }
	ul {
		@include reset-ul;
		text-align: left;
		li {
			display: block;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0; } }
		ul {
			width: 100%;
			padding-left: 1em;
			margin-top: 1em; }
		.sub-menu {
			padding-left: 1em;
			margin-top: 1em; } }
	ul {
		li {
			a {
				color: $brown-grey;
				&:hover {
					color: $sandstone; } } } } }

.widgettitle {
	display: block;
	@include small-caps;
	color: $dusk;
	margin-bottom: 1.5em;
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		background-color: $dusk;
		margin-bottom: 0.5em; } }

/* ======================================================================== */
/* 98. widgetArchive */
/* ======================================================================== */
.widget_archive {
	ul {
		li {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			span {
				color: $brown-grey;
				font-size: 13px;
				line-height: 1; } } } }

/* ======================================================================== */
/* 99. widgetCalendar */
/* ======================================================================== */
#wp-calendar {
	width: 100%;
	text-align: center;
	caption {
		caption-side: top;
		width: 100%;
		text-align: center;
		padding-top: 0;
		padding-bottom: 10px; }
	th {
		font-weight: 600;
		padding: 5px;
		text-align: center; }
	td {
		padding: 5px;
		color: $brown-grey;
		&#next {
			text-align: right; }
		&#prev {
			text-align: left; } }
	tbody {
		a {
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 30px;
				height: 30px;
				background-color: $off-white;
				border-radius: 100%;
				transform: translate(-50%, -50%);
				z-index: -1;
				@include trans1; }
			&:hover {
				color: $white;
				&:before {
					background: $dusk; } } } }

	tr {
		border-bottom: none; }
	a {
		display: inline-block;
		position: relative;
		color: $dusk;
		z-index: 50;
		&:hover {
			color: $sandstone; } } }

/* ======================================================================== */
/* 100. widgetCassioLogo */
/* ======================================================================== */

/* ======================================================================== */
/* 101. widgetCassioMenuInline */
/* ======================================================================== */
.widget_cassio_menu_inline {
	ul {
		li {
			display: inline-block;
			margin-bottom: 0;
			a {
				padding: 4px;
				&:before {
					display: none; } } } } }

@media screen and (max-width: $md) {
	.widget_cassio_menu_inline {
		.menu {
			text-align: center; } } }

/* ======================================================================== */
/* 102. widgetCassioSocial */
/* ======================================================================== */
.widget_cassio_social {
	ul {
		text-align: inherit;
		li {
			display: inline-block;
			margin-bottom: 0; } } }

/* ======================================================================== */
/* 103. widgetCategories */
/* ======================================================================== */
.widget_categories {
	ul {
		li {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			span {
				color: $brown-grey;
				font-size: 13px;
				line-height: 1; } } } }

/* ======================================================================== */
/* 104. widgetLogo */
/* ======================================================================== */
.widgetcassio_logo, .widget_cassio_logo {
	max-width: 360px;
	.logo {
		margin-bottom: 2em; } }

@media screen and (max-width: $md) {
	.widgetcassio_logo, .widget_cassio_logo {
		max-width: 100%;
		.logo {
			margin-bottom: 1em; } } }

/* ======================================================================== */
/* 105. widgetNavMenu */
/* ======================================================================== */
.widget_nav_menu {
	ul.menu {
		> li {
			margin-bottom: 1em !important;
			white-space: normal;
			word-break: break-word;
			a {
				display: inline-block;
				padding: 0;
				font-size: 16px;
				&:after, &:before {
					display: none; } } }
		> li.menu-item-has-children {
			margin-bottom: 1.5em !important;
			a {
				&:after {
					display: none; } } } }
	ul.sub-menu {
		background-color: transparent;
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		opacity: 1;
		visibility: visible;
		box-shadow: none;
		border-bottom: none;
		transform: none;
		margin-top: 1em;
		> li {
			border-left: none;
			> a {
				padding: 3px 0;
				border-left: none;
				&:hover {
					background-color: unset; } } } } }

/* ======================================================================== */
/* 106. widgetPolylang */
/* ======================================================================== */
.widget_polylang {
	display: inline-block;
	font-size: 14px;
	select {
		width: auto;
		display: inline-block;
		padding: 5px 25px 5px 15px; }
	ul {
		@include reset-ul;
		li {
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 0; } } }

/* ======================================================================== */
/* 107. widgetRSS */
/* ======================================================================== */
.widget_rss {
	.rsswidget {
		font-family: $font-secondary;
		font-weight: bold;
		color: $dusk; }
	.rss-date {
		display: block;
		width: 100%;
		font-size: 13px;
		margin-top: 0.5em;
		margin-bottom: 1em;
		color: $brown-grey; }
	.rssSummary {
		margin-top: 1em;
		margin-bottom: 1em; }
	ul {
		> li {
			border-bottom: 1px solid $borders-dark;
			padding-bottom: 1em;
			&:last-child {
				border-bottom: none;
				padding-bottom: 0; } } } }

/* ======================================================================== */
/* 108. widgetRecentComments */
/* ======================================================================== */
.widget_recent_comments {
	ul {
		li {
			padding: 20px 0;
			margin-bottom: 0 !important;
			border-bottom: 1px solid $borders-dark;
			&:first-child {
				padding-top: 0; }
			&:last-child {
				padding-bottom: 0;
				border-bottom: none; }
			a {
				color: $dusk;
				font-family: $font-secondary;
				font-weight: bold;
				&:hover {
					color: $sandstone; } } } }
	.comment-author-link {
		a {
			color: $brown-grey;
			font-family: $font-primary;
			font-weight: normal; } } }

/* ======================================================================== */
/* 109. widgetRecentEntries */
/* ======================================================================== */
.widget_recent_entries {
	.post-date {
		display: block;
		width: 100%;
		font-size: 13px;
		margin-top: 0.25em;
		color: $brown-grey; }
	ul {
		li {
			padding: 20px 0;
			margin-bottom: 0 !important;
			border-bottom: 1px solid $borders-dark;
			a {
				font-family: $font-secondary;
				font-weight: bold;
				color: $dusk !important;
				&:hover {
					color: $sandstone !important; } }
			&:first-child {
				padding-top: 0; }
			&:last-child {
				padding-bottom: 0;
				border-bottom: none; } } } }

/* ======================================================================== */
/* 110. widgetWPML */
/* ======================================================================== */
.widget_icl_lang_sel_widget {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
	font-size: 14px;
	.wpml-ls-legacy-dropdown {
		width: auto;
		border: 1px solid $borders-dark;
		border-radius: 2px;
		padding: 2px 5px;
		a {
			border: none;
			background: transparent;
			&:hover, &:focus {
				opacity: 1; } } }
	.wpml-ls-legacy-dropdown a:hover, .wpml-ls-legacy-dropdown a:focus, .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a {
		background: transparent; }
	.wpml-ls-sub-menu {
		border-top: none; }
	.wpml-ls-legacy-list-horizontal {
		padding: 2px 5px;
		.wpml-ls-item {
			display: inline-block;
			margin-bottom: 0; } } }
.lang-switch-no-padding-right {
	.widget_icl_lang_sel_widget {
		.wpml-ls-legacy-list-horizontal {
			padding-right: 0; } } }
