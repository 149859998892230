/* ======================================================================== */
/* 31. headerColors */
/* ======================================================================== */
.header_color-white
	.header__burger-line
		background-color: $white
	.header__controls .social__item a
		color: $white
	&:not(.header_sticky)
		.menu > li > a
			color: $white
			&:hover
				color: $sandstone
/////////////////////////////////////////////
.header_color-black
	.header__burger-line
		background-color: $greyish-brown
	.header__controls .social__item a
		color: $brown-grey
