/* ======================================================================== */
/* 109. widgetRecentEntries */
/* ======================================================================== */
.widget_recent_entries
	.post-date
		display: block
		width: 100%
		font-size: 13px
		margin-top: 0.25em
		color: $brown-grey
	ul
		li
			padding: 20px 0
			margin-bottom: 0 !important
			border-bottom: 1px solid $borders-dark
			a
				font-family: $font-secondary
				font-weight: bold
				color: $dusk !important
				&:hover
					color: $sandstone !important
			&:first-child
				padding-top: 0
			&:last-child
				padding-bottom: 0
				border-bottom: none
