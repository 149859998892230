/* ======================================================================== */
/* 7. buttonCircles */
/* ======================================================================== */
.button-circles
	display: inline-flex
	justify-content: space-between
	flex-wrap: wrap
	width: 36px
	height: 36px
.button-circles__circle
	position: relative
	width: 14px
	height: 14px
	outline: none
	display: inline-block
	border: 2px solid $borders-dark
	vertical-align: middle
	border-radius: 100%
	&:nth-child(1)
		align-self: flex-start
	&:nth-child(2)
		align-self: flex-start
	&:nth-child(3)
		align-self: flex-end
	&:nth-child(4)
		align-self: flex-end
	svg
		display: block
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		margin: auto
		width: 14px
		height: 14px
		border-radius: 100%
		stroke-width: 42px
		z-index: 50
		.circle
			stroke: $sandstone

@media screen and (max-width: $sm)
	.button-circles
		width: 30px
		height: 30px
	.button-circles__circle
		width: 12px
		height: 12px
		svg
			width: 12px
			height: 12px
