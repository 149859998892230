/* ======================================================================== */
/* 104. widgetLogo */
/* ======================================================================== */
.widgetcassio_logo, .widget_cassio_logo
	max-width: 360px
	.logo
		margin-bottom: 2em

@media screen and (max-width: $md)
	.widgetcassio_logo, .widget_cassio_logo
		max-width: 100%
		.logo
			margin-bottom: 1em
