/* ======================================================================== */
/* 11. colorsAccentSecondary */
/* ======================================================================== */
.color-accent-secondary-1
	color: var(--color-accent-secondary-1)
.color-accent-secondary-2
	color: var(--color-accent-secondary-2)
.color-accent-secondary-3
	color: var(--color-accent-secondary-3)
.color-accent-secondary-4
	color: var(--color-accent-secondary-4)
