/* ======================================================================== */
/* 107. widgetRSS */
/* ======================================================================== */
.widget_rss
	.rsswidget
		font-family: $font-secondary
		font-weight: bold
		color: $dusk
	.rss-date
		display: block
		width: 100%
		font-size: 13px
		margin-top: 0.5em
		margin-bottom: 1em
		color: $brown-grey
	.rssSummary
		margin-top: 1em
		margin-bottom: 1em
	ul
		> li
			border-bottom: 1px solid $borders-dark
			padding-bottom: 1em
			&:last-child
				border-bottom: none
				padding-bottom: 0
