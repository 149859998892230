/* ======================================================================== */
/* 2. backgrounds */
/* ======================================================================== */
.bg-white
	background-color: $white
.bg-off-white
	background-color: $off-white
.bg-light
	background-color: $light
.bg-black
	background-color: $black
.bg-dark
	background-color: $dark
.bg-dark-2
	background-color: $dark2
.bg-dark-3
	background-color: $dark3
.bg-accent-primary-1
	background-color: $sandstone
.bg-accent-primary-2
	background-color: $pale
.bg-accent-primary-3
	background-color: $pinkish-grey
.bg-accent-primary-4
	background-color: $brownish-grey
.bg-accent-secondary-1
	background-color: $grey-blue
.bg-accent-secondary-2
	background-color: $dusk
.bg-accent-secondary-3
	background-color: $dark-blue
.bg-accent-secondary-4
	background-color: $dark-blue-2
