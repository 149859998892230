/* ======================================================================== */
/* 103. widgetCategories */
/* ======================================================================== */
.widget_categories
	ul
		li
			display: flex !important
			justify-content: space-between
			align-items: center
			flex-wrap: wrap
			span
				color: $brown-grey
				font-size: 13px
				line-height: 1
