/* ======================================================================== */
/* 78. sectionTeam */
/* ======================================================================== */
.section-team
	@include fluid-margin-top($min_width, $max_width, 25, 30, -1)
	@include fluid-margin-bottom($min_width, $max_width, 25, 30, -1)

.section-team__wrapper-item
	@include fluid-padding-top($min_width, $max_width, 25, 50)
	@include fluid-padding-bottom($min_width, $max_width, 25, 50)
