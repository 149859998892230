/* ======================================================================== */
/* 34. headerThemes */
/* ======================================================================== */
.header
	&.bg-white
		.header__burger-line
			background-color: $dark
		.social__item a
			color: $brown-grey
	/////////////////////////////////////////////
	&.bg-off-white
		.header__burger-line
			background-color: $dark
		.social__item a
			color: $brown-grey
	/////////////////////////////////////////////
	&.bg-light
		.header__burger-line
			background-color: $dark
		.social__item a
			color: $brown-grey
	/////////////////////////////////////////////
	&.bg-black
		.menu
			> li > a
				color: $white
				&:hover
					color: $sandstone
			.sub-menu
				background-color: $dark2
				> li a
					color: $white
					&:hover
						background-color: $dark3
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
	/////////////////////////////////////////////
	&.bg-dark
		.menu
			> li > a
				color: $white
				&:hover
					color: $sandstone
			.sub-menu
				background-color: $dark2
				> li a
					color: $white
					&:hover
						background-color: $dark3
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
	/////////////////////////////////////////////
	&.bg-dark-2
		.menu
			> li > a
				color: $white
				&:hover
					color: $sandstone
			.sub-menu
				background-color: $dark2
				> li a
					color: $white
					&:hover
						background-color: $dark3
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
	/////////////////////////////////////////////
	&.bg-dark-3
		.menu
			> li > a
				color: $white
				&:hover
					color: $sandstone
			.sub-menu
				background-color: $dark2
				> li a
					color: $white
					&:hover
						background-color: $dark3
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
	/////////////////////////////////////////////
	&.bg-accent-primary-1
		.menu
			> li > a
				color: $white
				&:hover
					color: $brownish-grey
				&:before
					background-color: $brownish-grey
			.sub-menu
				background-color: $pinkish-grey
				> li a
					color: $white
					&:hover
						border-color: $dark
						background-color: $brownish-grey
		.logo
			color: $white
			&:hover
				color: $brownish-grey
		.social__item a
			color: $white
			&:hover
				color: $brownish-grey
	/////////////////////////////////////////////
	&.bg-accent-primary-3
		.menu
			> li > a
				color: $white
				&:hover
					color: $brownish-grey
				&:before
					background-color: $brownish-grey
			.sub-menu
				background-color: $pinkish-grey
				> li a
					color: $white
					&:hover
						border-color: $dark
						background-color: $brownish-grey
		.logo
			color: $white
			&:hover
				color: $brownish-grey
		.social__item a
			color: $white
			&:hover
				color: $brownish-grey
	/////////////////////////////////////////////
	&.bg-accent-primary-4
		.menu
			> li > a
				color: $white
				&:hover
					color: $very-light-pink
				&:before
					background-color: $very-light-pink
			.sub-menu
				background-color: $pinkish-grey
				> li a
					color: $white
					&:hover
						border-color: $dark
						background-color: $pale
						color: $dark
		.social__item a
			color: $white
			&:hover
				color: $brownish-grey
	/////////////////////////////////////////////
	&.bg-accent-secondary-1
		.menu
			> li > a
				color: $white
				&:hover
					color: $very-light-pink
				&:before
					background-color: $very-light-pink
			.sub-menu
				background-color: $dusk
				> li a
					color: $white
					&:hover
						border-color: $sandstone
						background-color: $grey-blue
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-2
		.menu
			> li > a
				color: $white
				&:hover
					color: $very-light-pink
				&:before
					background-color: $very-light-pink
			.sub-menu
				background-color: $dusk
				> li a
					color: $white
					&:hover
						border-color: $sandstone
						background-color: $grey-blue
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-3
		.menu
			> li > a
				color: $white
				&:hover
					color: $very-light-pink
				&:before
					background-color: $very-light-pink
			.sub-menu
				background-color: $dusk
				> li a
					color: $white
					&:hover
						border-color: $sandstone
						background-color: $grey-blue
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-4
		.menu
			> li > a
				color: $white
				&:hover
					color: $very-light-pink
				&:before
					background-color: $very-light-pink
			.sub-menu
				background-color: $dusk
				> li a
					color: $white
					&:hover
						border-color: $sandstone
						background-color: $grey-blue
		.social__item a
			color: $white
			&:hover
				color: $sandstone
		.header__burger-line
			background-color: $white
