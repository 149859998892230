/* ======================================================================== */
/* 46. preloader */
/* ======================================================================== */
.preloader
	display: flex
	align-items: center
	justify-content: center
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	z-index: 1000
.preloader__curtain
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 50
.preloader__content
	position: relative
	width: 160px
	height: 160px
	z-index: 50
	overflow: hidden
.preloader__wrapper-logo
	width: 100%
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	overflow: hidden
	border-bottom: 2px solid $borders-dark
.preloader__curtain_inner
	top: 99%
	animation-name: preloader-load
	animation-iteration-count: 1
	animation-duration: 25s
	transform-origin: left center

@keyframes preloader-load
	from
		transform: scaleX(0)
	to
		transform: scaleX(1)
		