$em-base: 16px
$font-primary: var(--font-primary)
$font-secondary: var(--font-secondary)
$line-height: 1.75

//Color palette
$sandstone: var(--color-accent-primary-1)
$pale: var(--color-accent-primary-2)
$pinkish-grey: var(--color-accent-primary-3)
$brownish-grey: var(--color-accent-primary-4)

$grey-blue: var(--color-accent-secondary-1)
$dusk: var(--color-accent-secondary-2)
$dark-blue: var(--color-accent-secondary-3)
$dark-blue-2: var(--color-accent-secondary-4)

$white: #ffffff
$light: #f8f8f8
$off-white: #f7f5f0

$very-light-pink: #cccccc
$brown-grey: #888888
$greyish-brown: #444444
$dark2: #222222
$dark3: #333333
$dark: #181818
$black: #111111

$borders-dark: rgba(136, 136, 136, .3)
$borders-dark-low: rgba(136, 136, 136, .1)
$borders-light: rgba(204, 204, 204, .3)

$brdr: 4px

// Responsive Breakpoints
$xxl: 1400px
$xl: 1280px
$lg: 1024px
$md: 991px
$sm: 767px
$xs: 576px

$min_width: 320px
$max_width: 1920px

$distance-max-xsmall: var(--distance-max-xsmall)
$distance-max-small: var(--distance-max-small)
$distance-max-normal: var(--distance-max-normal)
$distance-max-large: var(--distance-max-large)
$distance-max-xlarge: var(--distance-max-xlarge)
$distance-min-xsmall: var(--distance-min-xsmall)
$distance-min-small: var(--distance-min-small)
$distance-min-normal: var(--distance-min-normal)
$distance-min-large: var(--distance-min-large)
$distance-min-xlarge: var(--distance-min-xlarge)

$h1-max-font-size: var(--h1-max-font-size)
$h1-min-font-size: var(--h1-min-font-size)
$h1-line-height: var(--h1-line-height)

$h2-max-font-size: var(--h2-max-font-size)
$h2-min-font-size: var(--h2-min-font-size)
$h2-line-height: var(--h2-line-height)

$h3-max-font-size: var(--h3-max-font-size)
$h3-min-font-size: var(--h3-min-font-size)
$h3-line-height: var(--h3-line-height)

$h4-max-font-size: var(--h4-max-font-size)
$h4-min-font-size: var(--h4-min-font-size)
$h4-line-height: var(--h4-line-height)

$h5-max-font-size: var(--h5-max-font-size)
$h5-min-font-size: var(--h5-min-font-size)
$h5-line-height: var(--h5-line-height)

$h6-max-font-size: var(--h6-max-font-size)
$h6-min-font-size: var(--h6-min-font-size)
$h6-line-height: var(--h6-line-height)

$subheading-max-font-size: var(--subheading-max-font-size)
$subheading-min-font-size: var(--subheading-min-font-size)
$subheading-line-height: var(--subheading-line-height)

$blockquote-max-font-size: var(--blockquote-max-font-size)
$blockquote-min-font-size: var(--blockquote-min-font-size)
$blockquote-line-height: var(--blockquote-line-height)

$paragraph-max-font-size: var(--paragraph-max-font-size)
$paragraph-min-font-size: var(--paragraph-min-font-size)
$paragraph-line-height: var(--paragraph-line-height)

$text-xl-max-font-size: var(--xl-max-font-size)
$text-xl-min-font-size: var(--xl-min-font-size)
$text-xl-line-height: var(--xl-line-height)

$text-xxl-max-font-size: var(--xxl-max-font-size)
$text-xxl-min-font-size: var(--xxl-min-font-size)
$text-xxl-line-height: var(--xxl-line-height)
