/* ======================================================================== */
/* 9. colors */
/* ======================================================================== */
.color-white
	color: $white
	.header__burger-line
		background-color: $white
	.header__controls .social__item a
		color: $white
	.heading-light
		color: $very-light-pink
	.subheading
		color: $white
.color-black
	color: $greyish-brown
	.subheading
		color: $brown-grey
	.heading-light
		color: $brown-grey
