/* ======================================================================== */
/* 45. artParallax */
/* ======================================================================== */
[data-art-parallax='image'], [data-art-parallax='background'],
	position: relative
	overflow: hidden
	img
		width: 100%
		height: 100%
		object-fit: cover
.art-parallax__bg
	display: block
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-size: cover
	background-position: center center
